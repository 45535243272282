import React, { Component } from 'react'
import Tab from './Tab';
import classnames from 'classnames'

export class SubHeader extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * Handle tab click handler, set active tab index to state
     * 
     * @param {number} tabIndex the index of the tab to be set to active
     */
    handleSubTabClick = (subTabIndex) => {
        this.props.onClick(subTabIndex);
    }

    /**
	 * Check if sub header has tabs to render
	 *
	 * @returns {boolean} true if the sub header has tabs to render, false if not
	 */
    hasTabs = () => {
        return React.Children.toArray(this.props.children).some(c => c.type === Tab);
    }

    /**
     * Render tabs, add properties
     */
    renderTabs() {
        let tabs = [];
        React.Children.forEach(this.props.children, (child, index) => {
            if(child.type === Tab) {
                tabs.push(React.cloneElement(child, {
                    key: index,
                    onClick: this.handleSubTabClick,
                    tabIndex: index,
                    isActive: index === this.props.activeSubTabIndex,
                }));
            }
        });
        return tabs;
    }

    /**
     * Render sub header
     */
    renderSubHeader() {
        let subHeaders = [];
        React.Children.forEach(this.props.children, (child, index) => {
            if(child.type !== Tab) {
                subHeaders.push(React.cloneElement(child, {key: index}));
            }
        });
        return subHeaders;
    }

    render() {
        const tabClasses = classnames({
            "btn-group": true,
            "hidden": !this.hasTabs()
        });

        return (
            <div>
                <div className={tabClasses} style={{"marginTop" : "10px"}}>
                    {this.renderTabs()}
                </div>
                <div style={{"marginTop" : "10px"}}>
                    {this.renderSubHeader()}
                </div>
            </div>
        )
    }
}

export default SubHeader
