import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login, loginWithFacebook } from '../../actions/auth_actions'
import LoginForm from './LoginForm'
import { loginWithGoogle } from '../../actions/auth_actions'
import { useLocation } from 'react-router-dom'

export const LoginPage = (props) => {
    let query = new URLSearchParams(useLocation().search);
    return (
        <LoginForm
            onLogin={props.login}
            onLoginWithGoogle={props.loginWithGoogle}
            onLoginWithFacebook={props.loginWithFacebook}
            auth_data={props.auth_data}
            email={query.get('username')}
            password={query.get('password')}
            i18n={props.i18n}/>
    )
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication,
        i18n: state.language
    }
}

export default connect(mapStateToProps, { login, loginWithGoogle, loginWithFacebook })(LoginPage)

