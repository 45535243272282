import React, { Component } from 'react'
import { ChatMessages } from './ChatMessages';
import ChatInput from './ChatInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import noImage from '../../../public/media/profile/no_image.png'
import { getUserImage } from '../../service/users_service';

export class Chat extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }

    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.selectedUserId !== this.props.selectedUserId) {
            this.imgRef.current.src = "";
        }
        if (prevProps.selectedUserId !== this.props.selectedUserId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.selectedUserId) {
            this.fetchUserImage();
        }
    }
    
    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.selectedUserId).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => { });
        } finally {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
        }
    }

    /**
     * Send message to other user in chat
     *
     * @param {object} msg - the message to send
     */
    onSendMessage = (msg) => {
        const message = {
            message: msg,
            from_user_id: this.props.loggedUser.id,
            to_user_id: this.props.selectedUserId,
            dateTime: moment().valueOf()
        };
        this.props.sendMessage(message);
    }

    render() {
        if (!this.props.selectedUserId) {
            return '';
        }
        const unreadMessagesFromUser = this.props.unreadMessages.find(m => m.sender === this.props.selectedUserId);
        return (
            <div className="card chat-card">
                <div className="card-header">
                    <div className="chat-card-title">
                        <div style={{ "alignSelf": "center" }}>
                            <div className="chat-user-info">
                                <div>
                                    <button className="btn btn-default" onClick={this.props.showLastMessages}>
                                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                                    </button>
                                </div>
                                <img className="user-chat-image" ref={this.imgRef} style={{ "marginLeft": "5px" }} />
                                <span>{this.props.selectedUserFullName}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body chat-card-body">
                    <div className="conversation">
                        <ChatMessages messages={this.props.messages} loggedUser={this.props.loggedUser} unreadMessagesFromUser={unreadMessagesFromUser} />
                        <ChatInput sendMessage={this.onSendMessage} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Chat
