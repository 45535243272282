import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

export default function BloodPressureEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><FontAwesomeIcon icon={faHeartbeat} className="logbook-entry-icon"/> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("Systolic")}: {props.entry.systolic} {$$('millimetre_of_mercury')}</div>
                <div>{$$("Diastolic")}: {props.entry.diastolic} {$$('millimetre_of_mercury')}</div>
                <div>{$$("Pulse")}: {props.entry.pulse}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
                {/*<div>Measuring site: {props.entry.measuring_site}</div>*/}
            </div>
        </div>
    )
}
