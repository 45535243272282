import React from 'react'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as MenstrautionIcon } from '../../../public/media/icons/menstruation.svg'
import { getResolvedText, MENSTRUATION } from '../../constants/select_options'

export default function MenstruationEntityV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon" style={{ "top": "10px" }}><MenstrautionIcon fill="#646c9a"/></span>
                <div className="logbook-entry-dashboard-text">
                    <div>{getResolvedText(MENSTRUATION.FLOW, props.entry.menstruationFlow)}</div>
                    <div>{getResolvedText(MENSTRUATION.COLOR, props.entry.menstruationColor)}, {getResolvedText(MENSTRUATION.CONSISTENCY, props.entry.menstruationConsistency)}</div>
                </div>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
