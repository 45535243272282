export const Routes = {
    LOGIN: "/login",
    REGISTER: "/register",
    MAIN_PAGE: "/main",
    SETTINGS: "/settings",
    SHARING: "/sharing",
    PROFILE: "/profile",
    MY_DOCUMENTS: "/documents",
    COMMUNICATION: "/communication",
    USER_MEDICAL_RECORD: "/medical-record",
    CHANGE_PASSWORD: "/change-password"
};
