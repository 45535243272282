import React, { Component } from 'react'
import { $$ } from '../../helpers/localization';
import noImage from '../../../public/media/profile/no_image.png'
import { getUserImage } from '../../service/users_service';
import { MISSED, REJECTED, IN_PROGRESS, COMPLETED, INITIATED } from '../../constants/video_message_status'
import moment from 'moment'

export class ChatUserItem extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }
    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userId !== this.props.userId) {
            this.imgRef.current.src = "";
        }
        if (prevProps.userId !== this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.userId).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => { });
        } finally {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
        }
    }

    /**
     * Get the vide message text to display in the last message item
     * 
     * @returns {string} text to display for the video call
     */
    getVideoMessageText = () => {
        if (this.props.message.type === "VIDEO") {
            switch (this.props.message.video_status) {
                case MISSED: return <b>{$$('missed_call_label')}</b>;
                case REJECTED: return <b>{$$('rejected_call_label')}</b>;
                case IN_PROGRESS: return <b>{$$('in_progress_call_label')}</b>;
                case COMPLETED: return <span><b>{$$('completed_call_label')}</b>: {moment.utc(this.props.message.video_duration).format('HH:mm:ss')}</span>;
            }
        }
        return '';
    }

    render() {
        const youWrote = <b>{$$('you_wrote')}:</b>;
        return (
            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start" onClick={(e) => { e.preventDefault(); this.props.onItemClick(this.props.message); }}>
                <div className="user-chat-info">
                    <img className="user-chat-image" ref={this.imgRef} />
                    <div>
                        <div><b>{this.props.isSent ? this.props.message.to : this.props.message.from}</b></div>
                        {this.props.message.type === 'TEXT' &&
                            <div className="mb-1">{this.props.isSent ? youWrote : ''} {this.props.message.message}</div>
                        }
                        {(this.props.message.type === "VIDEO" && this.props.message.video_status !== INITIATED) &&
                            <div className="mb-1">{this.getVideoMessageText()}</div>
                        }
                    </div>
                    {(this.props.unreadMessages && this.props.unreadMessages.count > 0) &&
                        <span className="message-count-label chat-unread-messages">{this.props.unreadMessages.count}</span>
                    }
                </div>
            </a>
        )
    }
}

export default ChatUserItem
