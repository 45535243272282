export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const LOGIN_REQUEST_SENT = "LOGIN_REQUEST_SENT";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const TOKEN_REFRESH_REQUEST_SENT = "TOKEN_REFRESH_REQUEST_SENT";
export const TOKEN_REFRESH_REQUEST_SUCCESS = "TOKEN_REFRESH_REQUEST_SUCCESS";
export const TOKEN_REFRESH_REQUEST_ERROR = "TOKEN_REFRESH_REQUEST_ERROR";
export const LOGOUT = "LOGOUT";

export const REQUEST_SENT = "REQUEST_SENT";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_CLEAR = "REGISTER_CLEAR";

export const ALL_USERS_REQUEST_SENT = "ALL_USERS_REQUEST_SENT";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_ERROR = "ALL_USERS_ERROR";
export const CLEAR_USERS = "CLEAR_USERS";
export const ALL_USERS_FILTER_CHANGE = "ALL_USERS_FILTER_CHANGE";

export const SELECT_USER = "SELECT_USER";
export const FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT = "FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT";
export const FETCH_SELECTED_USER_ENTRIES_ERROR = "FETCH_SELECTED_USER_ENTRIES_ERROR";
export const FETCH_SELECTED_USER_ENTRIES_SUCCESS = "FETCH_SELECTED_USER_ENTRIES_SUCCESS";
export const NO_MORE_ENTRIES = "NO_MORE_ENTRIES";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";

export const FETCH_CHARTS_DATA_REQUEST_SENT = "FETCH_CHARTS_DATA_REQUEST_SENT";
export const FETCH_CHARTS_DATA_SUCCESS = "FETCH_CHARTS_DATA_SUCCESS";
export const FETCH_CHARTS_DATA_ERROR = "FETCH_CHARTS_DATA_ERROR";
export const CHANGE_CHARTS_FILTERS = "CHANGE_CHARTS_FILTERS";
export const FETCH_DASHBOARD_CHARTS_DATA_SUCCESS = "FETCH_DASHBOARD_CHARTS_DATA_SUCCESS";
export const FETCH_DASHBOARD_CHARTS_DATA_ERROR = "FETCH_DASHBOARD_CHARTS_DATA_ERROR";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR";

export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const GET_USER_SETTINGS_ERROR = "GET_USER_SETTINGS_ERROR";
export const CHANGE_USER_SETTINGS_SUCCESS = "CHANGE_USER_SETTINGS_SUCCESS";
export const CHANGE_USER_SETTINGS_ERROR = "CHANGE_USER_SETTINGS_ERROR";

export const GET_NOMENCLATURE_REQUEST_SENT = "GET_NOMENCLATURE_REQUEST_SENT";
export const GET_NOMENCLATURE_SUCCESS = "GET_NOMENCLATURE_SUCCESS";
export const GET_NOMENCLATURE_ERROR = "GET_NOMENCLATURE_ERROR";

export const FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT = "FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT";
export const FETCH_SELECTED_USER_SYMPTOMS_ERROR = "FETCH_SELECTED_USER_SYMPTOMS_ERROR";
export const FETCH_SELECTED_USER_SYMPTOMS_SUCCESS = "FETCH_SELECTED_USER_SYMPTOMS_SUCCESS";
export const NO_MORE_SYMPTOMS = "NO_MORE_SYMPTOMS";

export const DUMMY = "DUMMY";

export const ALL_PERMISSIONS_REQUEST_SENT = "ALL_PERMISSIONS_REQUEST_SENT";
export const ALL_PERMISSIONS_REQUEST_SUCCESS = "ALL_PERMISSIONS_REQUEST_SUCCESS";
export const ALL_PERMISSIONS_REQUEST_ERROR = "ALL_PERMISSIONS_REQUEST_ERROR";
export const PERMISSION_DENY_REQUEST_SUCCESS = "PERMISSION_DENY_REQUEST_SUCCESS";
export const PERMISSION_APPROVE_REQUEST_SUCCESS = "PERMISSION_APPROVE_REQUEST_SUCCESS";
export const PERMISSION_DENY_REQUEST_ERROR = "PERMISSION_DENY_REQUEST_ERROR";
export const PERMISSION_APPROVE_REQUEST_ERROR = "PERMISSION_APPROVE_REQUEST_ERROR";
export const REQUEST_PERMISSION_SUCCESS = "REQUEST_PERMISSION_SUCCESS";
export const REQUEST_PERMISSION_ERROR = "REQUEST_PERMISSION_ERROR";

export const CLEAR_PERMISSIONS = "CLEAR_PERMISSIONS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";
export const CLEAR_USER_PASSWORD = "CLEAR_USER_PASSWORD";

export const FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_DOCUMENTS_SUCCESS = "FETCH_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_SELECTED_USER_DOCUMENTS_ERROR = "FETCH_SELECTED_USER_DOCUMENTS_ERROR";
export const NO_MORE_SELECTED_USER_DOCUMENTS = "NO_MORE_SELECTED_USER_DOCUMENTS";
export const CHANGE_DOCUMENTS_FILTERS = "CHANGE_DOCUMENTS_FILTERS";
export const RESET_SELECTED_USER_DOCUMENTS_SUCCESS = "RESET_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_LOGGED_USER_DOCUMENTS_SUCCESS = "FETCH_LOGGED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_ERROR = "FETCH_LOGGED_USER_DOCUMENTS_ERROR";
export const NO_MORE_LOGGED_USER_DOCUMENTS = "NO_MORE_LOGGED_USER_DOCUMENTS";
export const RESET_LOGGED_USER_DOCUMENTS_SUCCESS = "RESET_LOGGED_USER_DOCUMENTS_SUCCESS";

export const GET_CHILDREN = "GET_CHILDREN";
export const GET_CHILDREN_ERROR = "GET_CHILDREN_ERROR";

export const FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS = "FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS";
export const FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR = "FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR";

export const FETCH_CHAT_MESSAGES_REQUEST_SENT = "FETCH_CHAT_MESSAGES_REQUEST_SENT";
export const FETCH_CHAT_MESSAGES_REQUEST_SUCCESS = "FETCH_CHAT_MESSAGES_REQUEST_SUCCESS";
export const FETCH_CHAT_MESSAGES_REQUEST_ERROR = "FETCH_CHAT_MESSAGES_REQUEST_ERROR";
export const SEND_MESSAGE_TO_USER = "SEND_MESSAGE_TO_USER"
export const NO_MORE_MESSAGES = "NO_MORE_MESSAGES";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR";
export const SET_MESSAGE_AS_SEEN = "SET_MESSAGE_AS_SEEN";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const CHANGE_CHAT_VIEW_POSITION = "CHANGE_CHAT_VIEW_POSITION";
export const SET_ONGOING_CALL_FLAG = "SET_ONGOING_CALL_FLAG";

export const FETCH_SELECTED_USER_MEDICATIONS_SUCCESS = "FETCH_SELECTED_USER_MEDICATIONS_SUCCESS";
export const FETCH_SELECTED_USER_MEDICATIONS_ERROR = "FETCH_SELECTED_USER_MEDICATIONS_ERROR";

export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS";
export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR";

export const FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT = "FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS = "FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_SELECTED_USER_LAB_RESULTS_ERROR = "FETCH_SELECTED_USER_LAB_RESULTS_ERROR";
export const NO_MORE_SELECTED_USER_LAB_RESULTS = "NO_MORE_SELECTED_USER_LAB_RESULTS";
export const CHANGE_LAB_RESULTS_FILTERS = "CHANGE_LAB_RESULTS_FILTERS";
export const RESET_SELECTED_USER_LAB_RESULTS_SUCCESS = "RESET_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_LABORATORIES_REQUEST_SENT = "FETCH_LABORATORIES_REQUEST_SENT";
export const FETCH_LABORATORIES_SUCCESS = "FETCH_LABORATORIES_SUCCESS";
export const FETCH_LABORATORIES_ERROR = "FETCH_LABORATORIES_ERROR";