import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import {
    Modal, Button
} from "react-bootstrap";

export default class CenteredModal extends Component {

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onConfirm}>
                        {this.props.confirmBtnLabel ? this.props.confirmBtnLabel : $$('confirm_btn_label')}
                    </Button>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        {this.props.cancelBtnLabel ? this.props.cancelBtnLabel : $$('close_btn_label')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
