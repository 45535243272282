import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserListItem } from './UserListItem';
import {
    getAllUsers,
    selectUser,
    fetchChartsData,
    fetchDashboardChartsData,
    fetchSelectedUserLatestMeasurements,
    fetchSelectedUserLogbookEntries
} from '../../actions/users_actions'
import { fetchSelectedUserSymptomsLog } from '../../actions/symptoms_actions'
import { fetchSelectedUserDocuments } from '../../actions/documents_actions'
import { fetchSelectedUserLabResults } from '../../actions/lab-results_actions'

class UserList extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * Fetch users function used for the infinite scroll
     * 
     * @param {number} page page of items to fetch
     */
    fetchUsers = (page) => {
        this.props.getAllUsers({ page: page, size: 500 });
    }

    render() {
        const usersList = this.props.users ? this.props.users.map((user, idx) => {
            return <UserListItem user={user} key={idx}
                onSelectUser={this.props.selectUser}
                fetchSelectedUserLatestMeasurements={this.props.fetchSelectedUserLatestMeasurements}
                selectedUserId={this.props.selectedUser.id}
                fetchChartsData={this.props.fetchChartsData}
                fetchDashboardChartsData={this.props.fetchDashboardChartsData}
                fetchSelectedUserSymptomsLog={this.props.fetchSelectedUserSymptomsLog}
                fetchSelectedUserDocuments={this.props.fetchSelectedUserDocuments}
                fetchSelectedUserLogbookEntries={this.props.fetchSelectedUserLogbookEntries}
                chartFilters={this.props.chartFilters}
                fetchSelectedUserLabResults={this.props.fetchSelectedUserLabResults}
                i18n={this.props.i18n.selected} />
        }) : '';
        return (
            <ul className="kt-menu__nav" style={{ "overflowY": "auto" }}>
                {usersList}
            </ul>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        chartFilters: state.charts.filters
    }
}

export default connect(mapStateToProps, {
    getAllUsers,
    selectUser,
    fetchChartsData,
    fetchDashboardChartsData,
    fetchSelectedUserSymptomsLog,
    fetchSelectedUserLatestMeasurements,
    fetchSelectedUserDocuments,
    fetchSelectedUserLogbookEntries,
    fetchSelectedUserLabResults
})(UserList);
