export const formatUtils = {
  format,
  formatAsJSON
}

/**
 * Convert the string to the appropriate format
 *
 * @param {string} str the string to be formatted
 * @returns {string} the string in the appropriate format
 */
function format(str) {
    if (typeof str !== 'string') {
        return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Replace the slashes found in the string with empty string
 *
 * @param {string} str the string to be processed
 * @returns {string} the JSON object of the manipulated string
 */
function formatAsJSON(str) {
    if (typeof str !== 'string') {
      return '';
    }
    
    str = str.replace(/\\\([^n]\)1/g, '');
    return JSON.parse(str);
}