import moment from 'moment'
import store from '../store'
import { $$ } from '../helpers/localization'
import {WEIGHT, HYDRATION, TEMPERATURE, HEIGHT} from '../constants/entry_types';
import { selectedUser } from '../reducers/users_reducers';

export const CONVERTER = {
    cholesterolPerUnit,
    convertByUnit,
    formatDate,
    getSelectedDateTimeFormat,
    getEntryDateTimeFormat,
    getDayPeriodFromTime,
    getFormatedCholesterolYAxes,
    getFormatedWeightYAxes,
    getFormatedHydrationYAxes,
    getFormatedTemperatureYAxes,
    getFormatedRespiratoryRateYAxes,
    getFormatedSaturationYAxes,
    getFormattedCholesterolUnit,
    getUnitByType,
    getBloodGroupByType,
    getSeverityForDisplay,
    getAllergyNameForDisplay,
    ketonesPerUnit,
    getFormattedKetonesUnit,
    bloodSugarPerUnit,
    getFormattedBloodSugarUnit,
    hba1cPerUnit,
    getFormattedHBA1CUnit
};

/**
 * Convert cholesterol value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function cholesterolPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return roundToTwo(value * 18);
        }
    }
}

/**
 * Convert ketones value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function ketonesPerUnit(value) {
    return value;
}

/**
 * Convert blood sugar value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function bloodSugarPerUnit(value) {
    return value;
}

/**
 * Convert hba1c value to unit selected in settings
 * 
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function hba1cPerUnit(value) {
    return value;
}

/**
 * Convert the given value based on the entry type and the selected unit
 * 
 * @param {string} entryType the type of the entry to convert
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function convertByUnit(entryType, value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            return value;
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return roundToTwo(value * 2.2046);
                }
                case HYDRATION: {
                    return roundToTwo(value * 0.03381402);
                }
                case TEMPERATURE: {
                    return roundToTwo((value * 1.8) + 32);
                }
                case HEIGHT: {
                    return roundToTwo(value / 2.54);
                }
                default: {
                    return value;
                }
            }
        }
    }
}

/**
 * Get the formated cholesterol unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedCholesterolUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated ketones unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedKetonesUnit() {
    return $$('millimol_per_litre');
}

/**
 * Get the formated blood sugar unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedBloodSugarUnit() {
    return $$('millimol_per_litre');
}

/**
 * Get the formated hba1c unit
 * 
 * @returns {string} formated unit
 */
export function getFormattedHBA1CUnit() {
    return '%';
}

/**
 * Get the formated unit for the entry
 * 
 * @param {string} entryType the entry type
 * @returns {string} formated unit
 */
export function getUnitByType(entryType) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('kg');
                }
                case HYDRATION: {
                    return $$('ml');
                }
                case TEMPERATURE: {
                    return $$('celcius');
                }
                case HEIGHT: {
                    return $$('cm');
                }
                default: {
                    return selectedUnit;
                }
            }
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('pound');
                }
                case HYDRATION: {
                    return $$('floz');
                }
                case TEMPERATURE: {
                    return $$('fahrenheight');
                }
                case HEIGHT: {
                    return $$('inches');
                }
                default: {
                    return selectedUnit;
                }
            }
        }
    }
}

/**
 * Format date with the format selected in settings
 * 
 * @param {date} date to format
 * @param {boolean} hideTime flag used to hide time
 * @returns {date} formated date
 */
export function formatDate(date, hideTime) {
    if (store.getState().settings.data) {
        return moment(date).format(getSelectedDateTimeFormat(hideTime));
    }
}

/**
 * Get the format of date time from the settings
 * 
 * @param {boolean} hideTime flag used to hide time
 * @returns {string} the format to use
 */
export function getSelectedDateTimeFormat(hideTime) {
    const dateFormat = store.getState().settings.data.dateFormat;
    if (dateFormat) {
        if (hideTime) {
            return dateFormat.toUpperCase();
        } else {
            const timeFormat = store.getState().settings.data.time24hour ? "kk:mm" : "hh:mm a";
            return dateFormat.toUpperCase() + ", " + timeFormat;
        }
    }
}

/**
 * Format the date time for the user entries
 *
 * @param {Date} dateTime date time of the entries to format
 * @param showDayOfTheWeek boolean if set - display also the day of the week before the time.
 * @returns {string} the formated date time
 */
export function getEntryDateTimeFormat(dateTime, showDayOfTheWeek) {
    if (dateTime) {
        const timeFormat = store.getState().settings.data.time24hour ? "kk:mm" : "hh:mm a";
        const format = showDayOfTheWeek ? `dddd, ${timeFormat}` : timeFormat;
        return moment(dateTime).locale(store.getState().language.selected.lang).format(format);
    }
}

/**
 * Get the day period based on the time
 * 
 * @param {Date} dateTime date time of the entries to format
 * @returns {string} day period, morning, afternoon, evening
 */
export function getDayPeriodFromTime(dateTime) {
    const hour = moment(dateTime).format('HH');
    if (!hour) {
        return moment(dateTime).locale(store.getState().language.selected.lang).format('dddd');
    }
    const splitAfternoon = 12;
    const splitEvening = 17;
    if (hour >= splitAfternoon && hour <= splitEvening) {
        return $$('afternoon');
    } else if (hour >= splitEvening) {
        return $$('evening');
    }
    return $$('morning');
}

/**
 * Rounds the given number to with precision of two decimals
 * 
 * @param {number} num the number to round 
 * @returns {number} the rounded number
 */
function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

/**
 * Gets the y axes for the cholesterol charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedCholesterolYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (selectedUnit && selectedUnit === 'MMOL_L') {
            return { min: 0, max: 18, step: 1 };
        } else if (selectedUnit === 'MG_DL') {
            return { min: 30, max: 240, step: 30 };
        }
    }
}

/**
 * Gets the y axes for the weight charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedWeightYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 0, max: 180, step: 30 };
        } else if (selectedUnit === 'US') {
            return { min: 60, max: 360, step: 60 };
        }
    }
}

/**
 * Gets the y axes for the hydration charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedHydrationYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 0, max: 2100, step: 300 };
        } else if (selectedUnit === 'US') {
            return { min: 0, max: 70, step: 10 };
        }
    }
}

/**
 * Gets the y axes for the temperature charts, depending on the settings chosen
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedTemperatureYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return { min: 35, max: 42, step: 0.5 };
        } else if (selectedUnit === 'US') {
            return { min: 95, max: 110, step: 5 };
        }
    }
}

/**
 * Gets the y axes for the respiratory chart
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedRespiratoryRateYAxes() {
    return { min: 0, max: 100, step: 5 };
}

/**
 * Gets the y axes for the saturation chart
 * 
 * @returns {object} the boundaries for the axes
 */
export function getFormatedSaturationYAxes() {
    return { min: 70, max: 110, step: 5 };
}

/**
 * Return blood group e.g. (A+, A-, AB+...) as string from Medical Profile blood_type value
 * @param bloodType as it comes from the medical profile
 */
export function getBloodGroupByType(bloodType) {
    switch (bloodType) {
        // NONE (display None)
        //     A_RhD_P (display A+)
        //     A_RhD_N (display A-)
        //     B_RhD_P (display B+)
        //     B_RhD_N (display B-)
        //     O_RhD_P (display 0+)
        //     O_RhD_N (display 0-)
        //     AB_RhD_P (display AB+)
        //     AB_RhD_N (display AB-)
        case "A_RhD_P": return "A+";
        case "A_RhD_N": return "A-";
        case "B_RhD_P": return "B+";
        case "B_RhD_N": return "B-";
        case "O_RhD_P": return "0+";
        case "O_RhD_N": return "0-";
        case "AB_RhD_P": return "AB+";
        case "AB_RhD_N": return "AB-";
        default : return $$("no_available_data");
    }
}

/**
 * Return severity for display from the allergy severity int field
 * @param severity as it comes from the allergy
 */
export function getSeverityForDisplay(severity) {
    switch (severity) {
        // 0 (display Mild)
        // 1 (display Mild to moderate)
        // 2 (display Moderate)
        // 3 (display Moderate to severe)
        // 4 (display Severe)
        // 5 (display Life threatening)
        case 0: return $$("mild");
        case 1: return $$("mild_to_moderate");
        case 2: return $$("moderate");
        case 3: return $$("moderate_to_severe");
        case 4: return $$("severe");
        case 5: return $$("life_threatening");
        default : "";
    }
}

/**
 * Return localized allergy name for display from the allergy type field
 * @param type as it comes from the allergy
 */
export function getAllergyNameForDisplay(type) {
    switch (type) {
        // DRUG_ALLERGY
        // DUST_ALLERGY
        // FOOD_ALLERGY
        // INSECT_ALLERGY
        // LATEX_ALLERGY
        // MOLD_ALLERGY
        // PET_ALLERGY
        // POLLEN_ALLERGY
        // OTHER_ALLERGY
        case "DRUG_ALLERGY": return $$("drug_allergy");
        case "DUST_ALLERGY": return $$("dust_allergy");
        case "FOOD_ALLERGY": return $$("food_allergy");
        case "INSECT_ALLERGY": return $$("insect_allergy");
        case "LATEX_ALLERGY": return $$("latex_allergy");
        case "MOLD_ALLERGY": return $$("mold_allergy");
        case "PET_ALLERGY": return $$("pet_allergy");
        case "POLLEN_ALLERGY": return $$("pollen_allergy");
        case "OTHER_ALLERGY": return $$("other_allergy");

        default : $$("None");
    }
}

/**
 * Return display value for given unit
 * @param unit medication unit
 */
export function getUnitValuefromKey(unit) {
    switch (unit.toUpperCase()) {
        case "AMPULE": return $$("medication.unit.label.ampule.display_short").length ? 
            $$("medication.unit.label.ampule.display_short") : $$("medication.unit.label.ampule.display_long");
        case "APPLICATION": return $$("medication.unit.label.application.display_short").length ? 
            $$("medication.unit.label.application.display_short") : $$("medication.unit.label.application.display_long");
        case "CAPSULE": return $$("medication.unit.label.capsule.display_short").length ? 
            $$("medication.unit.label.capsule.display_short") : $$("medication.unit.label.capsule.display_long");
        case "DROP": return $$("medication.unit.label.drop.display_short").length ? 
            $$("medication.unit.label.drop.display_short") : $$("medication.unit.label.drop.display_long");
        case "GRAM": return $$("medication.unit.label.gram.display_short").length ? 
            $$("medication.unit.label.gram.display_short") : $$("medication.unit.label.gram.display_long");
        case "INJECTION": return $$("medication.unit.label.injection.display_short").length ? 
            $$("medication.unit.label.injection.display_short") : $$("medication.unit.label.injection.display_long");
        case "MG": return $$("medication.unit.label.mg.display_short").length ? 
            $$("medication.unit.label.mg.display_short") : $$("medication.unit.label.mg.display_long");
        case "ML": return $$("medication.unit.label.ml.display_short").length ? 
            $$("medication.unit.label.ml.display_short") : $$("medication.unit.label.ml.display_long");
        case "PACKET": return $$("medication.unit.label.packet.display_short").length ? 
            $$("medication.unit.label.packet.display_short") : $$("medication.unit.label.packet.display_long");
        case "PATCH": return $$("medication.unit.label.patch.display_short").length ? 
            $$("medication.unit.label.patch.display_short") : $$("medication.unit.label.patch.display_long");
        case "PIECE": return $$("medication.unit.label.piece.display_short").length ? 
            $$("medication.unit.label.piece.display_short") : $$("medication.unit.label.piece.display_long");
        case "TABLET": return $$("medication.unit.label.tablet.display_short").length ? 
            $$("medication.unit.label.tablet.display_short") : $$("medication.unit.label.tablet.display_long");
        case "PUFF": return $$("medication.unit.label.puff.display_short").length ? 
            $$("medication.unit.label.puff.display_short") : $$("medication.unit.label.puff.display_long");
        case "SPRAY": return $$("medication.unit.label.spray.display_short").length ? 
            $$("medication.unit.label.spray.display_short") : $$("medication.unit.label.spray.display_long");
        case "SUPPOSITORY": return $$("medication.unit.label.suppository.display_short").length ? 
            $$("medication.unit.label.suppository.display_short") : $$("medication.unit.label.suppository.display_long");
        case "TBSP": return $$("medication.unit.label.tbsp.display_short").length ? 
            $$("medication.unit.label.tbsp.display_short") : $$("medication.unit.label.tbsp.display_long");
        case "TSP": return $$("medication.unit.label.tsp.display_short").length ? 
            $$("medication.unit.label.tsp.display_short") : $$("medication.unit.label.tsp.display_long");
        case "UNIT": return $$("medication.unit.label.unit.display_short").length ? 
            $$("medication.unit.label.unit.display_short") : $$("medication.unit.label.unit.display_long");

        default : $$("None");
    }
}

