import { fetchHelper } from '../helpers/request_helper';
import { GET_ALL_PERMISSIONS_URL, APPROVE_PERMISSION_URL, DENY_PERMISSION_URL, REQUEST_PERMISSION_URL, GET_ALL_AUTHORIZING_PERMISSIONS_URL } from '../constants/api_paths';

export const permissionsService = {
    getPermissions,
    approvePermission,
    denyPermission,
    requestPermission,
    getAuthorizingPermission
};

/**
 * Get list of permission
 * 
 * @returns {object} promise object
 */
export function getPermissions() {
    return fetchHelper.callGet(GET_ALL_PERMISSIONS_URL);
}

/**
 * Get list of permission for the normal user (not a medical provider)
 * 
 * @returns {object} promise object
 */
export function getAuthorizingPermission() {
    return fetchHelper.callGet(GET_ALL_AUTHORIZING_PERMISSIONS_URL);
}

/**
 * Approve the given permission
 * 
 * @param {string} permissionId id of the permission to approve
 * @returns {object} promise object
 */
export function approvePermission(permissionId) {
    return fetchHelper.callPost(APPROVE_PERMISSION_URL.replace('{permissionId}', permissionId));
}

/**
 * Deny the given permission
 * 
 * @param {string} permissionId id of the permission to cancel
 * @returns {object} promise object
 */
export function denyPermission(permissionId) {
    return fetchHelper.callPost(DENY_PERMISSION_URL.replace('{permissionId}', permissionId));
}

/**
 * Request permission for a given user
 * 
 * @param {string} email of the user to request permission to
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function requestPermission(email, params) {
    return fetchHelper.callPost(REQUEST_PERMISSION_URL.replace('{email}', email));
}

