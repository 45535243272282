import { fetchHelper } from '../helpers/request_helper';
import { GET_USER_SETTINGS_URL, UPDATE_USER_SETTINGS_URL } from '../constants/api_paths';

export const settingsService = {
    getUserSettings,
    updateUserSettings
};

/**
 * Get user settings
 * 
 * @returns {object} promise object
 */
export function getUserSettings() {
    return fetchHelper.callGet(GET_USER_SETTINGS_URL);
}

/**
 * Update user settings
 * 
 * @param {object} body the new settings to save
 * @returns {object} promise object
 */
export function updateUserSettings(body) {
    return fetchHelper.callPut(UPDATE_USER_SETTINGS_URL, body);
}


