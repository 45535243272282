import {
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    NO_MORE_SYMPTOMS,
    LOGOUT,
    CLEAR_USERS,
    SELECT_USER
} from "../actions/actions";
import { requestStatus } from './requests_reducers';

const initialState = { data: {}, request: requestStatus(undefined, {}) }
/**
 * Nomenclature reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of nomenclature
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function nomenclature(state = initialState, action) {
    switch (action.type) {
        case GET_NOMENCLATURE_REQUEST_SENT: {
            return { ...state, request: requestStatus(state.request, { type: REQUEST_SENT }) };
        }
        case GET_NOMENCLATURE_SUCCESS: {
            return { ...state, data: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) };
        }
        case GET_NOMENCLATURE_ERROR:
            return { ...state, ...initialState, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) };
        case LOGOUT: {
            return { ...state, ...initialState };
        }
        default: {
            return state
        }
    }
}

/**
 * Symptoms log reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const symptomsInitialState = { entries: [], isLastPage: false, request: requestStatus(undefined, {}) }
export function symptoms(state = symptomsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT: {
            const { error, isLastPage, ...rest } = state;
            return { ...rest, request: requestStatus(rest.request, { type: REQUEST_SENT }) }
        }
        case FETCH_SELECTED_USER_SYMPTOMS_SUCCESS: {
            return { ...state, entries: [...state.entries, ...action.result], request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_SELECTED_USER_SYMPTOMS_ERROR: {
            const { entries, isLastPage, ...rest } = state;
            return { ...rest, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case NO_MORE_SYMPTOMS: {
            return { ...state, isLastPage: true }
        }
        case SELECT_USER:
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...symptomsInitialState };
        }
        default: {
            return state;
        }
    }
}