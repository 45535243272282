import { CHANGE_LANGUAGE } from "./actions";
import { SELECTED_LANGUAGE } from "../constants/available_languages";

/**
 * Change system language, dispatch the appropiate reducer method 
 *
 * @param {object} language - the selected language
 * @returns {function} dispatch function
 */
export function changeLanguage(language) {
    return (dispatch, getState) => {
        localStorage.setItem(SELECTED_LANGUAGE, JSON.stringify(language));
        dispatch({ type: CHANGE_LANGUAGE, selected: language });
    };
} 