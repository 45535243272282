import React, { Component } from 'react'
import Symptoms from './Symptoms';
import moment from 'moment';
import { CONVERTER } from '../../utils/converter';

export class GroupedSymptoms extends Component {
    constructor(props) {
        super(props);
    }

    /**
	 * Get the symptoms to show
     * 
     * @returns {Array} of symptoms
	 */
    getGroupedSymptoms = () => {
        if(this.props.symptoms && this.props.symptoms.length > 0) {
            return this.props.symptoms.map((entry, idx) => {
                return <Symptoms key={idx} entry={entry} nomenclature={this.props.nomenclature.data.nomenclature} i18n={this.props.i18n} />
            });
        } else {
            return <div className="loader" key={0}>{$$('symptoms_no_data_label')}</div>;
        }
    }

    render() {
        return (
            <div className="card grouped-card">
                <div className="card-header">
                    {moment(this.props.date_time).format(CONVERTER.getSelectedDateTimeFormat(true))}, {moment(this.props.date_time).locale(this.props.i18n.lang).format('dddd')}
                </div>
                <div className="card-body" style={{ "padding": "0" }}>
                    {this.getGroupedSymptoms()}
                </div>
            </div>
        )
    }
}

export default GroupedSymptoms
