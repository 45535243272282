import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingsForm from './SettingsForm'
import { updateUserSettings } from '../../actions/settings_actions'
import { fetchChartsData, fetchDashboardChartsData } from '../../actions/users_actions'
import { PERIOD } from '../../constants/enums'

export class SettingsPage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        successfulUpdate: false
    }

    /**
	 * Check if update opeartin is done, if so display successful update message. Also if the settings are changed,
     * fetch the charts data based on the new settings.
	 *
	 * @param {object} prevProps - the previous props
	 */
    componentDidUpdate(prevProps) {
        const prevSettings = prevProps.settings.data;
        const currentSettings = this.props.settings.data
        if (prevSettings) {
            if (prevSettings.timestamp < currentSettings.timestamp) {
                this.setState({ successfulUpdate: true });
            }
            if (this.props.selectedUser.id &&
                (prevSettings.cholesterolUnits !== currentSettings.cholesterolUnits) ||
                (prevSettings.unitsOfMeasurement !== currentSettings.unitsOfMeasurement)) {
                this.props.fetchChartsData(this.props.selectedUser.id);
                this.props.fetchDashboardChartsData(this.props.selectedUser.id);
            }
        }
    }

    render() {
        return (
            <SettingsForm
                showSuccessfulAlert={this.state.successfulUpdate}
                settings={this.props.settings.data}
                updateUserSettings={this.props.updateUserSettings}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    selectedUser: state.selectedUser.data,
    i18n: state.language.selected
})

export default connect(mapStateToProps, { updateUserSettings, fetchChartsData, fetchDashboardChartsData })(SettingsPage)
