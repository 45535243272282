import React, { Component } from 'react'
import { connect } from 'react-redux'
import BloodPressureClassification from './BloodPressureClassification'
import LineChart from './LineChart'
import BarChart from './BarChart'
import { CONVERTER } from '../../utils/converter'
import { Chart } from 'react-chartjs-2'
import { PLUGINS } from '../../utils/chartsPlugins'

class Charts extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Register charts plugins
     */
    componentDidMount() {
        //Used to clear the previous labels before the chart is drawn
        this.clearClassificationLabels = {
            id: "clearClassificationLabels",
            beforeDraw: function (chart, easing) {
                let ctx = chart.chart.ctx;
                let width = chart.chart.width;
                let height = chart.chart.height;
                ctx.restore();
                if (this.props.chartsDataClassificationChart.ranges && chart.config.options.shouldDrawRectangleLabels) {
                    ctx.clearRect(0, 0, width, height);
                }
                ctx.save();
            }.bind(this)
        }

        //Used to register plugin for drawing the labels for the classification rectangles
        this.classificationLabelsPlugin = {
            id: "classificationLabelsPlugin",
            afterDraw: function (chart, easing) {
                if (this.props.chartsDataClassificationChart.ranges && chart.config.options.shouldDrawRectangleLabels) {
                    let ctx = chart.chart.ctx;
                    let width = chart.chart.width;
                    let xScale = chart.scales['x-axis-1'];
                    let yScale = chart.scales['y-axis-1'];
                    const labelStartX = width > 1000 ? 5 : 5;
                    ctx.restore();
                    ctx.textAlign = "left";
                    ctx.textBaseline = "middle";
                    this.props.chartsDataClassificationChart.ranges.forEach(r => {
                        ctx.fillText(r.label, xScale.getPixelForValue(labelStartX), yScale.getPixelForValue(r.y2 + 5));
                    });
                    ctx.save();
                }
            }.bind(this)
        }

        this.resizeChartFont = PLUGINS.resizeChartFont();

        //Used to register plugin for drawing the labels on top of the bar charts.
        this.barChartBoundaryLabelsPlugin = PLUGINS.barChartBoundaryLabelsPlugin();

        Chart.pluginService.register(this.clearClassificationLabels);
        Chart.pluginService.register(this.resizeChartFont);
        Chart.pluginService.register(this.classificationLabelsPlugin);
        Chart.pluginService.register(this.barChartBoundaryLabelsPlugin);
    }

    componentWillUnmount() {
        Chart.pluginService.unregister(this.clearClassificationLabels);
        Chart.pluginService.unregister(this.classificationLabelsPlugin);
        Chart.pluginService.unregister(this.resizeChartFont);
        Chart.pluginService.unregister(this.barChartBoundaryLabelsPlugin);
    }

    render() {
        return (
            <div className="card" style={{ "border": "none" }}>
                <div className="card-body inner-card-body">
                    <BloodPressureClassification chartData={this.props.chartsDataClassificationChart} settings={this.props.settings.data} />
                    <BarChart chartData={this.props.chartsDataAveragesPerDayInterval} settings={this.props.settings.data} />
                    <BarChart chartData={this.props.chartsDataAveragesPerHour} settings={this.props.settings.data} />
                    <BarChart chartData={this.props.chartsDataAveragesPerDayOfWeek} settings={this.props.settings.data} />
                    <LineChart chartData={this.props.chartsDataBPTimeline} settings={this.props.settings.data} />
                    <LineChart chartData={this.props.chartsDataTemperatureTimeline} yAxesRange={CONVERTER.getFormatedTemperatureYAxes()} settings={this.props.settings.data} />
                    <BarChart chartData={this.props.chartsDataHydrationAveragesPerHour} yAxesRange={CONVERTER.getFormatedHydrationYAxes()} settings={this.props.settings.data} hideLowDataLabel={true} />
                    <LineChart chartData={this.props.chartsDataHydrationTimeline} yAxesRange={CONVERTER.getFormatedHydrationYAxes()} settings={this.props.settings.data} />
                    <LineChart chartData={this.props.chartsDataSaturationTimeline} yAxesRange={CONVERTER.getFormatedSaturationYAxes()} settings={this.props.settings.data} />
                    {/* <LineChart chartData={this.props.chartsDataWeightTimeline} yAxesRange={CONVERTER.getFormatedWeightYAxes()} settings={this.props.settings.data} />
                    <LineChart chartData={this.props.chartsDataCholesterolTimeline} yAxesRange={CONVERTER.getFormatedCholesterolYAxes()} settings={this.props.settings.data} /> */}
                    <div className="last-chart-container">
                        <LineChart chartData={this.props.chartsDataRespiratoryRateTimeline} yAxesRange={CONVERTER.getFormatedRespiratoryRateYAxes()} settings={this.props.settings.data} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    chartsDataClassificationChart: state.charts.chartsData.chartsDataClassificationChart,
    chartsDataAveragesPerDayInterval: state.charts.chartsData.chartsDataAveragesPerDayInterval,
    chartsDataAveragesPerHour: state.charts.chartsData.chartsDataAveragesPerHour,
    chartsDataAveragesPerDayOfWeek: state.charts.chartsData.chartsDataAveragesPerDayOfWeek,
    chartsDataBPTimeline: state.charts.chartsData.chartsDataBPTimeline,
    chartsDataWeightTimeline: state.charts.chartsData.chartsDataWeightTimeline,
    chartsDataCholesterolTimeline: state.charts.chartsData.chartsDataCholesterolTimeline,
    chartsDataHydrationAveragesPerHour: state.charts.chartsData.chartsDataHydrationAveragesPerHour,
    chartsDataTemperatureTimeline: state.charts.chartsData.chartsDataTemperatureTimeline,
    chartsDataHydrationTimeline: state.charts.chartsData.chartsDataHydrationTimeline,
    chartsDataSaturationTimeline: state.charts.chartsData.chartsDataSaturationTimeline,
    chartsDataRespiratoryRateTimeline: state.charts.chartsData.chartsDataRespiratoryRateTimeline,
    settings: state.settings
})

export default connect(mapStateToProps, null)(Charts)
