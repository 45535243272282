import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { HYDRATION } from '../../constants/entry_types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTint } from '@fortawesome/free-solid-svg-icons'

export default function HydrationEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><FontAwesomeIcon icon={faTint} className="logbook-entry-icon"/> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("Hydration")}: {CONVERTER.convertByUnit(HYDRATION, props.entry.hydration).toFixed(2)} {CONVERTER.getUnitByType(HYDRATION)}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
