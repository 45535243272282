import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter';
import moment from 'moment';
import GroupedLogBookEntries from './GroupedLogBookEntries';
import _ from 'underscore'

export class Logbook extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Fetch logbook entries function used for the infinite scroll
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params;
        if (this.props.userId && (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished)) {
            if (this.props.logbook.entries && this.props.logbook.entries.length > 0) {
                const entiresLength = this.props.logbook.entries.length;
                params = {
                    before_date_time: this.props.logbook.entries[entiresLength - 1].date_time
                }
            }
            this.props.fetchEntries(this.props.userId, params);
        }
    }

    /**
	 * Get the logbook entries grouped by date time
     * 
     * @returns {Array} of grouped logbook entries
	 */
    getGroupedLogBookEntries = () => {
        const noData = <div className="loader" key={0}>{$$('logbook_no_data_label')}</div>;
        if (this.props.logbook.entries && this.props.logbook.entries.length > 0) {
            const groupedByDate = _.groupBy(this.props.logbook.entries, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((dateTime, idx) => {
                    return <GroupedLogBookEntries
                        date_time={dateTime}
                        key={idx}
                        logBook={groupedByDate[dateTime]}
                        settings={this.props.settings}
                        i18n={this.props.i18n}
                    />
                });
            }
        } else {
            return noData;
        }
    }

    render() {
        const hasMore = !this.props.logbook.isLastPage && (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished) && !this.props.logbook.request.error;
        return (
            <div className="card inner-card">
                <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getGroupedLogBookEntries()}
                        </div>
                        {hasMore &&
                            <div className="text-center" style={{ "marginTop": "10px" }}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Logbook
