import React, { Component } from 'react'
import moment from 'moment'
import _ from 'underscore'
import Symptoms from '../symptoms/Symptoms'
import { $$ } from '../../helpers/localization'

export class LatestSymptoms extends Component {

    constructor(props) {
        super(props);
    }

    /**
	 * Filter only the symptoms for the last 24 hours
     * 
     * @returns {Array} of Symptoms
	 */
    getLast24HoursSymptoms = () => {
        let latestSymptoms = '';
        const noSymptoms = <div style={{ "paddingTop": "15px" }}>{$$('no_latest_symptoms_label')}</div>;
        if (this.props.symptoms && this.props.symptoms.entries && this.props.symptoms.entries.length > 0) {
            latestSymptoms = this.props.symptoms.entries
                .filter(s => moment().diff(moment(s.date_time), 'h') < 24)
                .map((entry, idx) => {
                    return <Symptoms
                        key={idx}
                        entry={entry}
                        nomenclature={this.props.nomenclature.data.nomenclature}
                        i18n={this.props.i18n.selected}
                        isDashboardComponent={true} />
                });
            if (latestSymptoms.length > 0) {
                return latestSymptoms;
            }
        }
        return noSymptoms;
    }

    render() {
        return (
            <div className="card latest-symptoms-card">
                <div className="card-header">
                    <i className='kt-menu__link-icon flaticon2-medical-records-1' /> {$$('latest_symptoms_label')}
                </div>
                <div className="card-body row">
                    <div className="latest-measurements-cards">
                        {this.getLast24HoursSymptoms()}
                    </div>
                </div>
            </div>
        )
    }
}

export default LatestSymptoms
