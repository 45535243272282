import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as KetonesIcon } from '../../../public/media/icons/ketones.svg'

export default function KetonesEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><span className="logbook-entry-custom-icon"><KetonesIcon fill="#646c9a"/></span> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("Ketones")}: {CONVERTER.ketonesPerUnit(props.entry.ketones)} {CONVERTER.getFormattedKetonesUnit()}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
