import React from 'react'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as HBA1CIcon } from '../../../public/media/icons/hba1c.svg'

export default function HBA1CEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon">
                    <HBA1CIcon fill="#646c9a"/>
                </span>
                <span className="logbook-entry-dashboard-text">
                    {CONVERTER.hba1cPerUnit(props.entry.hba1c).toFixed(1)} {CONVERTER.getFormattedHBA1CUnit()}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
