import React, { Component } from 'react'
import classnames from 'classnames'
import { PERIOD } from '../../constants/enums';
import moment from 'moment';

export class UserListItem extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * On user item clicked handler, dipatch select user actions
     * 
     * @param {object} e the event object
     */
    onUserItemClicked = (e) => {
        e.preventDefault();
        this.props.onSelectUser(this.props.user);
    }

    render() {
        var linkClass = classnames({
            'kt-menu__link': true,
            'list-group-item-custom': true,
        });

        var itemClass = classnames({
            'kt-menu__item': true,
            'kt-menu__item--active': this.props.user.id === this.props.selectedUserId,
        });

        return (
            <li className={itemClass}>
                <a href="#" onClick={this.onUserItemClicked} className={linkClass}>
                    <span className="kt-menu__link-text user-list-text">
                        {this.props.user.fullname}
                    </span>
                </a>
            </li>
        )
    }
}

export default UserListItem
