import React, { Component } from 'react'
import { connect } from 'react-redux'
import TabsContainer from './TabsContainer';

class UserMedicalRecord extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="card">
                            <TabsContainer selectedUser={this.props.selectedUser} i18n={this.props.i18n} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication.data,
        users_data: state.users,
        selectedUser: state.selectedUser.data,
        i18n: state.language
    }
}

export default connect(mapStateToProps)(UserMedicalRecord);
