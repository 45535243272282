export const languages = [{
    key: "us",
    lang: "en",
    alt: "United States",
    title: "English (US)"
}, {
    key: "al",
    lang: "sq",
    alt: "Albania",
    title: "Albanian"
}, {
    key: "bg",
    lang: "bg",
    alt: "Bulgarian",
    title: "Български"
}];

export const SELECTED_LANGUAGE = 'SELECTED_LANGUAGE';