import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import moment from 'moment'
import CenteredModal from '../shared/CenteredModal';
import { documentsService } from '../../service/docments_service';
import { downloadUtils } from '../../utils/downloadUtils';
import _ from 'underscore'
import { CONVERTER } from '../../utils/converter';
import GroupedDocuments from './GroupedDocuments';

export class Documents extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        showModal: false,
        documentEntryId: null,
        documentName: ''
    }

    /**
	 * On confirm modal, download the file.
	 */
    onConfirmModal = () => {
        const fileName = this.state.documentName;
        documentsService.downloadFile(this.state.documentEntryId).then((file) => {
            downloadUtils.download(file, fileName);
        });
        this.setState({ showModal: false, documentEntryId: null });
    }

    /**
	 * Set state for the selected document entry when showing the modal
	 */
    showModal = (documentEntryId, documentName) => {
        this.setState({ showModal: true, documentEntryId: documentEntryId, documentName: documentName });
    }

    /**
     * Fetch selected user documents
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.documents.request.finished === undefined || this.props.documents.request.finished)) {
            if (this.props.documents.entries && this.props.documents.entries.length > 0) {
                const entiresLength = this.props.documents.entries.length;
                params.before_date_time = this.props.documents.entries[entiresLength - 1].date_time;
            }
            this.props.fetchEntries(this.props.selectedUser.id, params);
        }
    }

    /**
	 * Get the documents to show. Filter the documents based on the given input.
     * 
     * @returns {array} array of GroupedDocuments
	 */
    getDocuments = () => {
        const filters = this.props.filters;
        if (this.props.documents && this.props.documents.entries && this.props.documents.entries.length > 0) {
            const filteredDocuments = this.props.documents.entries.filter(doc => {
                return (!filters.type || filters.type === doc.type) &&
                    (!filters.category || (doc.category && filters.category.toLowerCase() === doc.category.toLowerCase())) &&
                    (!filters.after_date_time || doc.date_time > filters.after_date_time);
            });
            const groupedByDate = _.groupBy(filteredDocuments, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((dateTime, idx) => {
                    return <GroupedDocuments
                        date_time={dateTime}
                        key={idx}
                        isLast={keys.length - 1 === idx}
                        documents={groupedByDate[dateTime]}
                        showModal={this.showModal}
                        i18n={this.props.i18n} />
                });
            } else {
                return <div className="loader" key={0}>{$$('documents_no_data_for_filters_label')}</div>;
            }
        } else {
            return <div className="loader" key={0}>{$$('documents_no_data_label')}</div>;
        }
    }

    render() {
        const hasMore = !this.props.documents.isLastPage && (this.props.documents.request.finished === undefined || this.props.documents.request.finished) && !this.props.documents.request.error;
        const loader = <div className="loader" key={0}>{$$('loading_data_label')}</div>;
        return (
            <div className="card documents-inner-card">
                <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getDocuments()}
                        </div>
                        {hasMore &&
                            <div className="text-center" style={{ "marginTop": "10px" }}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>
                        }
                    </div>
                </div>
                <CenteredModal title={$$('document_download_dialog_title')}
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false, documentEntryId: null })}
                    onConfirm={this.onConfirmModal}
                    confirmBtnLabel={$$('download_btn')}
                    cancelBtnLabel={$$('cancel_btn')}
                >
                    {$$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname)}
                </CenteredModal>
            </div>
        )
    }
}

export default Documents
