import React from 'react'
import { authHelper } from '../../helpers/auth_helper';
import { Routes } from '../../constants/routes';
import { Route, Redirect } from 'react-router-dom'
import MainContainer from '../layout/MainContainer';
import { DefaultContainer } from '../layout/DefaultContainer';
import Layout from "../../_metronic/layout/Layout";
import { useLocation } from 'react-router-dom'
import { logout } from '../../actions/auth_actions'
import { connect } from 'react-redux'

export function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    const isLoggedIn = authHelper.isLoggedIn();
    let query = new URLSearchParams(useLocation().search);

    /**
     * Redirect user to Log in page if he tries to access a private route
     * without authentication.
     */
    if (isPrivate && !isLoggedIn) {
        return <Redirect to={Routes.LOGIN} />;
    }

    /**
     * If the target url is the login page, and the request comes from a link with username and password, logout current user
     */
    if(!isPrivate && isLoggedIn && rest.path === Routes.LOGIN && query.get("username") && query.get("password")) {
        rest.logout(query.get("username"), query.get("password"))
        return '';
    }

    /**
     * Redirect user to Main page if he tries to access a non private route
     * after being authenticated.
     */
    if (!isPrivate && isLoggedIn) {
        return <Redirect to={Routes.MAIN_PAGE} />;
    }

    const Wrapper = isLoggedIn ? Layout : DefaultContainer;

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return (
        <Route
            {...rest}
            render={props => (
                <Wrapper>
                    <Component {...props} />
                </Wrapper>
            )}
        />
    );
}

export default connect(null, { logout })(RouteWrapper)
