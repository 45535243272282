import {
    FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_SELECTED_USER_DOCUMENTS_SUCCESS,
    FETCH_SELECTED_USER_DOCUMENTS_ERROR,
    NO_MORE_SELECTED_USER_DOCUMENTS,
    CHANGE_DOCUMENTS_FILTERS,
    RESET_SELECTED_USER_DOCUMENTS_SUCCESS,
    DUMMY,
    FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_SUCCESS,
    FETCH_LOGGED_USER_DOCUMENTS_ERROR,
    NO_MORE_LOGGED_USER_DOCUMENTS,
    RESET_LOGGED_USER_DOCUMENTS_SUCCESS
} from './actions';
import moment from 'moment';
import { documentsService } from '../service/docments_service';

/**
 * Fetch the uploaded documents of the selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the documents list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserDocuments(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().documents.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({ type: FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT });
        return documentsService.fetchSelectedUserDocuments(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({ type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res });
                } else {
                    dispatch({ type: FETCH_SELECTED_USER_DOCUMENTS_SUCCESS, result: res });
                }
            } else {
                dispatch({ type: NO_MORE_SELECTED_USER_DOCUMENTS });
                if (resetList) {
                    dispatch({ type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res });
                }
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_DOCUMENTS_ERROR, result: err });
        })
    }
}

/**
 * Fetch the uploaded documents of the currently logged user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the logged user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchLoggedUserDocuments(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().documents.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({ type: FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT });
        const userId = userId || getState().userInfo && getState().userInfo.data ? getState().userInfo.data.id : '';
        return documentsService.fetchSelectedUserDocuments(userId, params).then((res) => {
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({ type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res });
                } else {
                    dispatch({ type: FETCH_LOGGED_USER_DOCUMENTS_SUCCESS, result: res });
                }
            } else {
                dispatch({ type: NO_MORE_LOGGED_USER_DOCUMENTS });
                if (resetList) {
                    dispatch({ type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res });
                }
            }
        }).catch((err) => {
            dispatch({ type: FETCH_LOGGED_USER_DOCUMENTS_ERROR, result: err });
        })
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeDocumentsFilters(filters) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_DOCUMENTS_FILTERS,
            documentType: filters.type,
            category: filters.category,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}
