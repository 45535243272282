import React, { Component } from 'react'
import { connect } from 'react-redux'
import { register, clearRegistrationData } from '../../actions/register_actions'
import { RegisterForm } from './RegisterForm'
import CenteredModal from '../shared/CenteredModal'
import { Routes } from '../../constants/routes';
import { $$ } from '../../helpers/localization'

class RegisterPage extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        showModal: false
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.id) {
            props.clearRegistrationData();
            return {
                showModal: true
            }
        }
        return state;
    }

    /**
	 * Confirm the registration success modal, navigate to login
	 */
    onConfirmModal = () => {
        this.props.history.push(Routes.LOGIN)
    }

    render() {
        return (
            <div>
                <RegisterForm register={this.props.register}
                    clearRegistrationData={this.props.clearRegistrationData}
                    registration={this.props.registration} />
                <CenteredModal title={$$('register_label')} show={this.state.showModal} onHide={() => this.setState({ showModal: false })} onConfirm={this.onConfirmModal}>
                    {$$('register_form_successful_message')}
                </CenteredModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    registration: state.register
})

const mapDispatchToProps = {
    register,
    clearRegistrationData,
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
