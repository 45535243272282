import React, { Component } from 'react'
import { $$, _$$ } from '../../helpers/localization';
import { CONVERTER, getUnitValuefromKey } from '../../utils/converter';
import moment from 'moment'
import { TAKE_X_TIMES, TAKE_EVERY_X_HOURS, DURATION_FOR_X_DAYS, DAILY, EVERY_X_DAYS, SPECIFIC_DAYS_OF_WEEK, CYCLE, WHEN_NEEDED, DURATION_UNTIL_DATE } from '../../constants/medications_constants';
import { ONGOING } from '../../constants/call_status';
import classNames from 'classnames'

export class MedicationPlan extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get list of medication plan items.
     * 
     * @returns list of medication plan items
     */
    getMedicationPlanList = () => {
        const activeMedicationPlan = this.props.medicationPlan && this.props.medicationPlan.entries ?
            this.props.medicationPlan.entries.filter(m => m.ends === 0 || m.ends > moment().valueOf()) : [];
        if (activeMedicationPlan.length === 0) return <div className="card-body">{$$("no_available_data")}</div>;
        return activeMedicationPlan.map((m, idx) => {
            return <MedicationPlanItem key={idx} medication={m} i18n={this.props.i18n} />
        })
    }

    render() {
        return (<div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-blood-group' /> {$$('current_medications')}
            </div>
            <ul className="list-group list-group-flush">
                {this.getMedicationPlanList()}
            </ul>
        </div>)
    }
}

const MedicationPlanItem = (props) => {
    const { medication, i18n } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);

    const collapseClick = React.useCallback(
        () => setIsCollapsed(!isCollapsed),
        [isCollapsed, setIsCollapsed],
    );

    /**
     * Get the formatted medication frequency string
     * 
     * @returns {string} medication plan frequency
     */
    const getMedicationFrequencyString = () => {
        switch (medication.howToTake) {
            case TAKE_X_TIMES: return _$$('times_per_day', medication.dose, getUnitValuefromKey(medication.unit), medication.scheduleTimesPerDay);
            case TAKE_EVERY_X_HOURS: return _$$('every_x_hours', medication.dose, getUnitValuefromKey(medication.unit), medication.scheduleHours);
            default: return '';
        }
    }

    /**
     * Get the formatted medication schedule string
     * 
     * @returns {string} medication plan schedule
     */
    const getMedicationScheduleString = () => {
        switch (medication.scheduleType) {
            case WHEN_NEEDED: return $$('when_needed');
            case DAILY: return $$('daily');
            case EVERY_X_DAYS: return _$$('every_x_days', medication.scheduleDaysInactive + 1);
            case SPECIFIC_DAYS_OF_WEEK: {
                let days = [];
                console.log((medication.scheduleWeekdays >>> 0).toString(2));
                (medication.scheduleWeekdays >>> 0).toString(2).split('').forEach((bit, idx) => {
                    if (bit === '1') {
                        days.push(moment().locale(i18n.selected.lang).day(idx + 1).format('ddd'));
                    }
                });
                return days.join(', ');
            }
            case CYCLE: return _$$('take_x_rest_x', medication.scheduleDaysActive, medication.scheduleDaysInactive);
            default: return '';
        }
    }

    /**
     * Get the formatted medication duration string
     * 
     * @returns {string} medication plan duration
     */
    const getMedicationDurationString = () => {
        switch (medication.durationType) {
            case ONGOING: return $$('ongoing');
            case DURATION_FOR_X_DAYS: return _$$('for_x_days_until', medication.durationInDays, CONVERTER.formatDate(medication.ends));
            case DURATION_UNTIL_DATE: return _$$('until_date', CONVERTER.formatDate(medication.ends, true))
            default: return '';
        }
    }

    const collapseClass = classNames('medication-plan-info',
        {
            "hide-medicaiton-info": !isCollapsed,
            "show-medication-info": isCollapsed
        });

    const getMedTitle =  () => {
        if (medication.scheduleType === WHEN_NEEDED) {
            return `${medication.name}, ${medication.dose} ${getUnitValuefromKey(medication.unit)}, ${$$("when_needed").toLowerCase()}`
        }
        return `${medication.name}, ${getMedicationFrequencyString()}`
    }

    return (
        <li onClick={collapseClick} className="list-group-item">
            <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                <div className="kt-font-bold">
                    {getMedTitle()}
                </div>
                <div>
                    {!isCollapsed &&
                        <i className="kt-nav__link-icon flaticon2-down"></i>
                    }
                    {isCollapsed &&
                        <i className="kt-nav__link-icon flaticon2-up"></i>
                    }
                </div>
            </div>
            <div className={collapseClass}>
                <div>{getMedicationScheduleString()}</div>
                <div>{getMedicationDurationString()}</div>
                {medication.notes && <div>{medication.notes}</div>}
                <div className="small text-sm-right">{_$$('effective_from', CONVERTER.formatDate(medication.starts))}</div>
            </div>
        </li>
    )
}

export default MedicationPlan
