
export default {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: [
      {
        title: "Main",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "main",
        translate: "main_page_label",
        bullet: "dot"
      },
      {
        title: "Sharing",
        root: true,
        icon: "flaticon-share",
        page: "sharing",
        translate: "sharing_label",
        bullet: "dot"
      },
      {
        title: "Settings",
        root: true,
        icon: "flaticon-settings",
        page: "settings",
        translate: "settings_label",
        bullet: "dot"
      },
      {
        title: "My documents",
        root: true,
        icon: "flaticon2-document",
        page: "documents",
        translate: "my_documents_label",
        bullet: "dot"
      },
      {
        title: "Children",
        root: true,
        icon: "flaticon-users",
        page: null,
        hidden: true,
        translate: "my_children_label"
      },
      {
        title: "",
        root: true,
        page: "medical-record",
        hidden: true,
        translate: "medical_record_label"
      },
    ]
  }
};
