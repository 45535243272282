import React from 'react'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as BloodSugarIcon } from '../../../public/media/icons/blood_sugar.svg'

export default function BloodSugarEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon">
                    <BloodSugarIcon fill="#646c9a"/>
                </span>
                <span className="logbook-entry-dashboard-text">
                    {CONVERTER.bloodSugarPerUnit(props.entry.blood_sugar)} {CONVERTER.getFormattedBloodSugarUnit()}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
