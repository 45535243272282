import React, { Component } from 'react'
import NavBar from './NavBar'

export class MainContainer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ "height": "inherit" }}>
                <NavBar />
                <div className="main">
                    <div className="container">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default MainContainer
