import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import LineChart from '../charts/LineChart';
import { CONVERTER } from '../../utils/converter';
import { Chart } from 'react-chartjs-2'
import { PLUGINS } from '../../utils/chartsPlugins'

export class LatestDataCharts extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Register charts plugins
     */
    componentDidMount() {
        //Used to clear the previous labels before the chart is drawn
        this.resizeChartFont = PLUGINS.resizeChartFont();

        //Used to register plugin for drawing the labels on top of the bar charts.
        this.barChartBoundaryLabelsPlugin = PLUGINS.barChartBoundaryLabelsPlugin();

        Chart.pluginService.register(this.resizeChartFont);
        Chart.pluginService.register(this.barChartBoundaryLabelsPlugin);
    }

    componentWillUnmount() {
        Chart.pluginService.unregister(this.resizeChartFont);
        Chart.pluginService.unregister(this.barChartBoundaryLabelsPlugin);
    }

    render() {
        return (
            <div className="card" style={{ "height": "100%" }}>
                <div className="card-header">
                    <i className='kt-menu__link-icon flaticon2-line-chart' /> {$$('last_week_data')}
                </div>
                <div className="card-body row" style={{ "paddingTop": "2px" }}>
                    <div style={{ "width": "100%" }}>
                        <LineChart chartData={this.props.dashboardCharts.chartsDataBPTimeline} settings={this.props.settings.data} />
                        <LineChart chartData={this.props.dashboardCharts.chartsDataTemperatureTimeline} yAxesRange={CONVERTER.getFormatedTemperatureYAxes()} settings={this.props.settings.data} />
                    </div>
                </div>
            </div>
        )
    }
}

export default LatestDataCharts
