import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import PermissionItem from './PermissionItem';
import { connect } from 'react-redux';

export class PermissionList extends Component {
    render() {
        if (!this.props.permissions || (this.props.permissions && this.props.permissions.length === 0)) {
            return '';
        }
        const permissions = this.props.permissions.filter(p => {
            return !((p.authorizingUser == this.props.userInfo.id) && (p.authorizedUser == this.props.userInfo.id));
        }).map((p, idx) => {
            return <PermissionItem
                key={idx}
                permission={p}
                onApprove={this.props.onApprove}
                onDeny={this.props.onDeny}
                cancelBtnLabel={this.props.cancelBtnLabel} />
        });
        return (
            <ul className="list-group" style={{ "width": "100%" }}>
                {permissions}
            </ul>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo.data
    }
}

export default connect(mapStateToProps)(PermissionList)
