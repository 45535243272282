import React, { Component } from 'react'
import { formatUtils } from '../../utils/formatUtils'
import { $$ } from '../../helpers/localization'

export class LabResultMicrobiologyTable extends Component {
    constructor(props) {
        super(props);

        this.index = 0;
        this.microbiology = this.props.result.microbiology;
        this.maxNumberOfColumns = this.getMaxNumberOfColumns();
    }

    /**
     * Finds the maximum number of table columns
     *
     * @returns {number} the number of table columns
     */
    getMaxNumberOfColumns = () => {
        //TODO - return the maximum number of columns based on keys of the findings' array
        //handle the case when the array of findings is empty
        return 2;
    }

    /**
     * Prepares an array containing the table columns names
     *
     * @returns {Array} the array of column names
     */
    getColumnNames = () => {
        let columnNames = [];
        columnNames.push($$('lab_microbiology_col_name_antibiogram'), "1:  2:  3:  ");
        return columnNames;
    }

    /**
     * Creates the table header
     *
     * @returns {object} the table header row
     */
    getHeader = () => {
        var headerCells = [];
        if(this.microbiology.length > 0) {
          headerCells.push(<th colSpan ={this.maxNumberOfColumns} key={this.microbiology[0].label}>{formatUtils.format(this.microbiology[0].label).toUpperCase()}</th>);
            return <tr key={++this.index} style={{ "backgroundColor": "#f2f2f2", "textAlign": "center" }}>{headerCells}</tr>;
        }
        return null;
    }

    /**
     * Creates the findings table cell
     *
     * @param {string} findings - the value of the cell
     * @returns {object} the cell element created
     */
    getFindingsRow = (findings) => {
        const processedFindings = findings.split("\n").map((f, index) => {
          return f.indexOf(":") > 0 ?  <p key={index}><b>{f}</b></p> :  <p key={index}>{f}</p>;
        });

        return <tr key={++this.index}>
                  <td key={++this.index} colSpan={this.maxNumberOfColumns} className="table-cells">
                      {processedFindings}
                  </td>
              </tr>;
    }

    /**
     * Creates the columns row
     *
     * @returns {object} the columns row created
     */
    getTableColumnsRow = () => {
        const columnNames = this.getColumnNames();
        let headerCells = columnNames.map((col, index) => {
          return <th key={index} className="table-cells">
                    {formatUtils.format(col)}
                </th>
          });
        return <tr key={++this.index}>{headerCells}</tr>;
    }

    /**
     * Creates the table's rows
     *
     * @param {Array} data - the data
     * @returns {Array} the cells created
     */
    getRows = (data) => {
        let rows = [];
        for(let i = 0; i < data.length; ++i) {
          rows.push(<tr key={++this.index}>{this.getCells(i, data[i])}</tr>);
        }

        return rows;
    }
  
    /**
     * Creates the table's cells
     * 
     * @param {number} i - the index that is going to be used as key
     * @param {object} item - the object that needs to be displayed in the table
     * @returns {Array} the td cells created
     */
    getCells = (i, item) => {
        let cells = [];
        cells.push(<td key={++this.index} className="table-cells">{item.label}</td>);
        cells.push(<td key={i} className="table-cells">{item.value}</td>);

        return cells;
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];

        for(let i = 0; i < this.microbiology.length; ++i) {
            let findings = Object.keys(this.microbiology[i].findings)[0];
            if(findings) {
                allRows.push(this.getFindingsRow(findings));
                allRows.push(this.getTableColumnsRow());
                if(this.microbiology[i].findings[findings].length > 0) {
                    allRows.push(this.getRows(this.microbiology[i].findings[findings]));
                }
            }
            
        }

        return allRows = [].concat.apply([], allRows);
    }

    render() {
        return (
            <div style={{ "overflowX": "auto" }}>
                <table className={"lab-results-table"} style={{ "width": this.props.width }}>
                    <thead>
                        {this.getHeader()}
                    </thead>
                    <tbody>
                        {this.getBody()}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default LabResultMicrobiologyTable