import { fetchHelper } from '../helpers/request_helper';
import { 
    ALL_USERS_URL, 
    ALL_LOGBOOK_ENTIRES_FOR_USER, 
    USER_CHART_DATA_URL, 
    LATEST_MEASUREMENtS_FOR_USER, 
    USER_PICTURE_URL,
    GET_USER_INFO_URL,
    UPDATE_USER_INFO_URL,
    UPDATE_USER_PASSWORD_URL,
    UPLOAD_USER_IMAGE_URL,
    DELETE_USER_IMAGE_URL,
    MEDICAL_PROFILE_FOR_USER
} from '../constants/api_paths';

export const usersService = {
    getAllUsers,
    fetchSelectedUserLogbookEntries,
    fetchSelectedUserChartsData,
    fetchSelectedUserLatestMeasurements,
    fetchSelectedUserMedicalProfile,
    getUserImage,
    getUserInfo,
    updateUserInfo,
    updateUserPassword,
    uploadImage,
    deleteImage
};

/**
 * Get all users method
 * 
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getAllUsers(params) {
    return fetchHelper.callGet(ALL_USERS_URL, params);
}

/**
 * Get logged user info
 * 
 * @returns {object} promise object
 */
export function getUserInfo() {
    return fetchHelper.callGet(GET_USER_INFO_URL);
}

/**
 * Update logged user info
 * 
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function updateUserInfo(data) {
    return fetchHelper.callPut(UPDATE_USER_INFO_URL, data);
}

/**
 * Update logged user password
 * 
 * @param {object} data the user password to save
 * @returns {object} promise object
 */
export function updateUserPassword(data) {
    return fetchHelper.callPut(UPDATE_USER_PASSWORD_URL, data);
}

/**
 * Get user image
 * 
 * @param {string} userId Id of the user to get image for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getUserImage(userId, params) {
    return fetchHelper.getBlob(USER_PICTURE_URL.replace('{userId}', userId), params);
}

/**
 * Upload user image
 * 
 * @param {object} file the image to be uploaded
 * @returns {object} promise object
 */
export function uploadImage(file) {
    return fetchHelper.uploadFile(UPLOAD_USER_IMAGE_URL, file);
}

/**
 * Delete user image
 * 
 * @returns {object} promise object
 */
export function deleteImage() {
    return fetchHelper.callPost(DELETE_USER_IMAGE_URL);
}

/**
 * Fetch the logbook entries method
 * 
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLogbookEntries(userId, params) {
    return fetchHelper.callGet(ALL_LOGBOOK_ENTIRES_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Fetch the selected user chart data
 * 
 * @param {number} userId id of the user to fetch the chart data
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserChartsData(userId, params) {
    return fetchHelper.callGet(USER_CHART_DATA_URL.replace('{userId}', userId), params);
}

/**
 * Fetch the latest logged measurements of the given user
 * 
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLatestMeasurements(userId, params) {
    return fetchHelper.callGet(LATEST_MEASUREMENtS_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Fetch the medical profile of the given user
 *
 * @param {number} userId id of the user to fetch the profile for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserMedicalProfile(userId, params) {
    return fetchHelper.callGet(MEDICAL_PROFILE_FOR_USER.replace('{userId}', userId), params);
}

