import React, { Component } from 'react'
import classnames from 'classnames'
import { $$ } from '../../helpers/localization';
import AvailableUserItem from './AvailableUserItem';

export class AvailableUsers extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        selectedId: null
    }

    /**
     * On item clicked handler. Handle user click, show chat with the selected user.
     *
     * @param {object} item - the item of the list, the selected user
     */
    onItemClick = (userId, userFullName) => {
        this.props.showChat(userId, userFullName);
    }

    render() {
        const users = this.props.availableUsers && this.props.availableUsers.length > 0 ? this.props.availableUsers.map((u, idx) => {
            const name = u.authorizingUserFullName;
            const email = u.authorizingUserEmail;
            const userId = u.authorizingUser;
            return <AvailableUserItem key={idx} userId={userId} name={name} email={email} onItemClick={this.onItemClick} />
        }) : '';
        return (
            <div className="card chat-users-card">
                <div className="card-header">
                    <div className="chat-card-title">
                        <div>{$$('available_users_label')}</div>
                        <div>
                            <button className="btn btn-success" onClick={this.props.showLastMessages}>
                                {$$("back")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {users}
                </div>
            </div>
        )
    }
}

export default AvailableUsers
