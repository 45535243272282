import React, { Component } from 'react'
import { connect } from 'react-redux'
import Tabs from '../main/Tabs'
import Tab from '../main/Tab'
import DocumentsFilters from './DocumentsFilters'
import Documents from './Documents'
import { fetchLoggedUserDocuments, changeDocumentsFilters } from '../../actions/documents_actions'
import { $$ } from '../../helpers/localization'
import SubHeader from '../main/SubHeader'

class MyDocuments extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchLoggedUserDocuments(null, null, true);
    }

    /**
     * Fetch the docments for the current logged user if the end date filter has changed
     */
    componentDidUpdate(prevProps) {
        if (prevProps.documents.filters.before_date_time !== this.props.documents.filters.before_date_time) {
            this.props.fetchLoggedUserDocuments(this.props.userInfo.id, null, true);
        }
    }

    /**
	 * Get the options for the documents category filter
     *
     * @returns {array} options for the documents category filter
	 */
    getDocumentsCategoryOptions = () => {
        const categories = [{ text: $$('select_category'), value: '' }];
        if (this.props.documents && this.props.documents.loggedUser && this.props.documents.loggedUser.entries && this.props.documents.loggedUser.entries.length > 0) {
            for (let doc of this.props.documents.loggedUser.entries) {
                if (categories.length === 11) {
                    break;
                }
                if (doc.category && !categories.some(c => c.value === doc.category)) {
                    categories.push({ text: doc.category, value: doc.category });
                }
            }
        }
        return categories;
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="card">
                            <div className="card-header">
                                <DocumentsFilters
                                    i18n={this.props.i18n}
                                    filters={this.props.documents.filters}
                                    categoryOptions={this.getDocumentsCategoryOptions()}
                                    changeDocumentsFilters={this.props.changeDocumentsFilters}
                                />
                            </div>
                            <div className="card-body main-card" style={{ "padding": "0px" }}>
                                <Documents
                                    filters={this.props.documents.filters}
                                    selectedUser={this.props.userInfo}
                                    documents={this.props.documents.loggedUser}
                                    fetchEntries={this.props.fetchLoggedUserDocuments}
                                    i18n={this.props.i18n.selected} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo.data,
    settings: state.settings,
    documents: state.documents,
    i18n: state.language
})

const mapDispatchToProps = {
    fetchLoggedUserDocuments,
    changeDocumentsFilters
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocuments)
