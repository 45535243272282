import React, { Component } from 'react'
import { CONVERTER } from '../../utils/converter'
import { $$ } from '../../helpers/localization'
import classnames from 'classnames';

export class DocumentEntry extends Component {
    constructor(props) {
        super(props);
    }

    /**
	 * Confirm the registration success modal, navigate to login
     *
     * @param {object} e the event object
	 */
    onFileNameClick = (e) => {
        e.preventDefault();
        this.props.showModal(this.props.entry.id, this.props.entry.filename);
    }

    /**
	 * Convert the type enum to text
     *
     * @param {string} type the type of the document
     * @param {string} type_description the type description
     * @returns {string} the text for the corresponding type
	 */
    convertTypeToText = (type, type_description) => {
        switch (type) {
            case "NONE": return $$("document_type_none");
            case "EPICRISIS": return $$("document_type_epicrisis");
            case "PRESCRIPTION": return $$("document_type_prescription");
            case "LAB_RESULTS": return $$("document_type_lab_results");
            case "AMBULANCE_SHEET": return $$("document_type_ambulance_sheet");
            case "REFERRAL": return $$("document_type_referral");
            case "OTHER": return type_description; //use the type description field which is provided from the user for OTHER /custom/ types
            default: ""; //unknown
        }
    }

    render() {
        const cardClass = classnames('card', {
            'entry-card': this.props.index !== 0
        });
        return (
            <div className="card">
                <div className="card-header entry-card-header">
                    <b>{this.convertTypeToText(this.props.entry.type, this.props.entry.typeDescription)}</b>
                </div>
                <div className="card-body" style={{ "paddingTop": "0px" }}>
                    <h5 className="card-title">{this.props.entry.title}</h5>
                    <div>
                        <i className="kt-menu__link-icon flaticon2-clip-symbol" style={{ "marginRight": "5px" }} />
                        <a href="#" onClick={this.onFileNameClick}>{this.props.entry.filename}</a>
                    </div>
                    {this.props.entry.category ? <div><i className=" flaticon2-tag" style={{ "marginRight": "5px" }} /><span>{this.props.entry.category}</span></div> : ""}
                    {/*<div>{$$('document_type_label')}: {this.props.entry.type}</div>
                    <div>{$$('type_description_label')}: {this.props.entry.typeDescription}</div>*/}
                    {this.props.entry.notes && !this.props.entry.hideNotes ? <div>{$$("notes")}: {this.props.entry.notes}</div> : ""}
                </div>
            </div>
        )
    }
}

export default DocumentEntry