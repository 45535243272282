import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import moment from 'moment'
import _ from 'underscore'
import GroupedLabResults from './GroupedLabResults';

export class LabResults extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Fetch selected user lab results
     * 
     * @param {object} e  - the event
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished)) {
            if (this.props.labResults.entries && this.props.labResults.entries.length > 0) {
                const entriesLength = this.props.labResults.entries.length;
                params.before_date_time = new Date(this.props.labResults.entries[entriesLength - 1].result_date).getTime();
            }
            this.props.fetchEntries(this.props.selectedUser.id, params);
        }
    }

    /**
	   * Get the lab results to show. Filter the lab results based on the given input.
     * 
     * @returns {array} array of GroupedLabResults
	   */
    getLabResults = () => {
        const filters = this.props.filters;
        if (this.props.labResults && this.props.labResults.entries && this.props.labResults.entries.length > 0) {
            const filteredLabResults = this.props.labResults.entries.filter(labResult => {
                return (!filters.laboratory || (labResult.laboratory && filters.laboratory.toLowerCase() === labResult.laboratory.toLowerCase())) &&
                    (!filters.after_date_time || new Date(labResult.result_date).getTime() > filters.after_date_time);
            });
            
            const groupedByDate = _.groupBy(filteredLabResults, (result) => moment(result.result_date).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((resultDate, idx) => {
                    return <GroupedLabResults
                        resultDate={resultDate}
                        key={idx}
                        isLast={keys.length - 1 === idx}
                        labs={this.props.labs}
                        labResults={groupedByDate[resultDate]}
                        i18n={this.props.i18n} 
                        filters={this.props.filters} />
                });
            } else {
                return <div className="loader" key={0}>{$$('lab_results_no_data_for_filters_label')}</div>;
            }
        } else {
            return <div className="loader" key={0}>{$$('lab_results_no_data_label')}</div>;
        }
    }

    render() {
        const hasMore = !this.props.labResults.isLastPage && (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished) && !this.props.labResults.request.error;
        const loader = <div className="loader" key={0}>{$$('loading_data_label')}</div>;
        return (
            <div className="card documents-inner-card">
                <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getLabResults()}
                        </div>
                        {hasMore &&
                            <div className="text-center" style={{ "marginTop": "10px" }}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default LabResults
