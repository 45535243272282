import React from 'react'
import LoginPage from './components/login/LoginPage'
import MainPage from './components/main/MainPage'
import SettingsPage from './components/settings/SettingsPage'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import RegisterPage from './components/register/RegisterPage'
import { Routes } from './constants/routes';
import RouteWrapper from './components/wrapper/RouteWrapper'
import history from './helpers/history'
import { LayoutContextProvider } from "./_metronic";
import { connect } from 'react-redux'
import SharingPage from './components/permissions/SharingPage'
import UserProfile from './components/profile/UserProfile'
import MyDocuments from './components/documents/MyDocuments'
import UserMedicalRecord from './components/main/UserMedicalRecord';
import Password from './components/password/Password';

class App extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Router history={history}>
                <LayoutContextProvider history={history} menuConfig={this.props.menuConfig} lang={this.props.lang}>
                    <Switch>
                        <RouteWrapper isPrivate={false} path={Routes.LOGIN} component={LoginPage} />
                        <RouteWrapper isPrivate={false} path={Routes.REGISTER} component={RegisterPage} />
                        <Route exact path='/' render={() => (
                            <Redirect
                                to={Routes.MAIN_PAGE}
                            />
                        )} />
                        <RouteWrapper isPrivate={true} path={Routes.MAIN_PAGE} component={MainPage} />
                        <RouteWrapper isPrivate={true} path={Routes.SETTINGS} component={SettingsPage} />
                        <RouteWrapper isPrivate={true} path={Routes.SHARING} component={SharingPage} />
                        <RouteWrapper isPrivate={true} path={Routes.PROFILE} component={UserProfile} />
                        <RouteWrapper isPrivate={true} path={Routes.MY_DOCUMENTS} component={MyDocuments} />
                        <RouteWrapper isPrivate={true} path={Routes.USER_MEDICAL_RECORD} component={UserMedicalRecord} />
                        <RouteWrapper isPrivate={true} path={Routes.CHANGE_PASSWORD} component={Password} />
                    </Switch>
                </LayoutContextProvider>
            </Router>
        );
    }
}


function mapStateToProps(state) {
    return {
        menuConfig: state.builder.menuConfig,
        lang: state.language.selected
    }
}

export default connect(mapStateToProps, null)(App);
