import React from 'react'
import { Link } from 'react-router-dom'
import { Routes } from '../../constants/routes'
import { $$ } from '../../helpers/localization'

export default function NavLinks(props) {
    if (props.isAuthenticated) {
        return (
            <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link className="nav-link" to={Routes.SHARING}>{$$('sharing_page_label')}</Link>
                </li>
            </ul>
        )
    }

    return null;
}
