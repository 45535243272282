import React, { Component } from 'react'
import { ReactComponent as Seen } from '../../../public/media/icons/double_tick_seen.svg'
import { ReactComponent as Delivered } from '../../../public/media/icons/double_tick_delivered.svg'
import classNames from 'classnames'
import { $$, _$$ } from '../../helpers/localization'
import { MISSED, REJECTED, IN_PROGRESS, COMPLETED, INITIATED } from '../../constants/video_message_status'
import moment from 'moment'
import { CONVERTER } from '../../utils/converter';

export class Message extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * Get the vide message text to display in the last message item
     * 
     * @returns {string} text to display for the video call
     */
    getVideoMessageText = () => {
        if (this.props.message.type === "VIDEO") {
            switch (this.props.message.video_status) {
                case MISSED: return <b>{$$('missed_call_label')}</b>;
                case REJECTED: return <b>{$$('rejected_call_label')}</b>;
                case IN_PROGRESS: return <b>{$$('in_progress_call_label')}</b>;
                case COMPLETED: return <span><b>{$$('completed_call_label')}</b>: {moment.utc(this.props.message.video_duration).format('HH:mm:ss')}</span>;
            }
        }
        return '';
    }

    render() {
        const messageClass = classNames('message', { 'sent': this.props.isSent === true, 'received': this.props.isSent === false });
        const time = moment(this.props.message.date_time).format(CONVERTER.getSelectedDateTimeFormat())
        return (
            <div>
                {/* <div className="chat-messages-line">
                    {this.props.showUnreadMessagesLine &&
                        <div className="line"><span className="text">{_$$("unread_messages", this.props.unreadMessages)}</span></div>
                    }
                </div> */}
                {this.props.message.type === "TEXT" &&
                    <div className={messageClass}>
                        {this.props.message.message}
                        <span className="metadata">
                            <span className="time">{time}</span>
                            <span className="tick">
                                {this.props.message.seen && this.props.isSent && <Seen />}
                                {!this.props.message.seen && this.props.isSent && <Delivered />}
                            </span>
                        </span>
                    </div>
                }
                {(this.props.message.type === "VIDEO" && this.props.message.video_status !== INITIATED) &&
                    <div className="chat-video-message">
                        <div className="text">
                            <div>{time}</div>
                            <div>{this.getVideoMessageText()}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Message

