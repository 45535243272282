import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { WEIGHT } from '../../constants/entry_types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWeight } from '@fortawesome/free-solid-svg-icons'

export default function WeightEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><FontAwesomeIcon icon={faWeight} className="logbook-entry-icon" /> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("Weight")}: {CONVERTER.convertByUnit(WEIGHT, props.entry.weight).toFixed(1)} {CONVERTER.getUnitByType(WEIGHT)}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
