import { settingsService } from '../service/settings_service'
import { GET_USER_SETTINGS, GET_USER_SETTINGS_ERROR, CHANGE_USER_SETTINGS_SUCCESS, CHANGE_USER_SETTINGS_ERROR } from './actions';

/**
 * Get the user settings action, fetch the settings and dispatch action
 *
 * @returns {function} dispatch function
 */
export function getUserSettings() {
    return (dispatch, getState) => {
        settingsService.getUserSettings().then((res) => {
            if (res) {
                dispatch({ type: GET_USER_SETTINGS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: GET_USER_SETTINGS_ERROR, result: err });
        });
    }
}

/**
 * Update the settings action, call the service and dipatch the correct action
 *
 * @param {object} settings - the settings to save
 * @returns {function} dispatch function
 */
export function updateUserSettings(settings) {
    return (dispatch, getState) => {
        settingsService.updateUserSettings(settings).then((res) => {
            if (res) {
                dispatch({ type: CHANGE_USER_SETTINGS_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: CHANGE_USER_SETTINGS_ERROR, result: err });
        });
    }
}