import React, { Component } from 'react'
import { $$, _$$ } from '../../helpers/localization';
import classNames from 'classnames'

export class GeneralPractitioner extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get Infomration of GP.
     *
     * @returns GP infomrmation
     */
    getGeneralPractitioner = () => {
        return <GeneralPractitionerItem  practitioner={
            typeof this.props.generalPractitioner !== "undefined" ? this.props.generalPractitioner : ( {
                name:"",
                practiceName:"",
                medicalPracticeAddress:"",
                practicePhoneNumber:"",
                regionalHealthCenterCode:"",
                uin:""
            })
        } i18n={this.props.i18n} />
    }

    render() {
        return (<div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-blood-group' /> {$$("general_practitioner")}
            </div>
            <ul className="list-group list-group-flush">
                {this.getGeneralPractitioner()}
            </ul>
        </div>)
    }
}

const GeneralPractitionerItem = (props) => {
    const { practitioner, i18n } = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);

    const collapseClick = React.useCallback(
        () => setIsCollapsed(!isCollapsed),
        [isCollapsed, setIsCollapsed],
    );


    const collapseClass = classNames('medication-plan-info',
        {
            "hide-medicaiton-info": !isCollapsed,
            "show-medication-info": isCollapsed
        });

    if (practitioner.name == "")
    {
        return(
            <div className="list-group-item" style={{ "display": "flex", "justifyContent": "space-between" }}>
                <div>
                    {$$("no_available_data")}
                </div>
            </div>)
    }


    return (
        <li onClick={collapseClick} className="list-group-item">
            <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                <div>
                    {practitioner.name}
                </div>
                <div>
                    {!isCollapsed &&
                    <i className="kt-nav__link-icon flaticon2-down"></i>
                    }
                    {isCollapsed &&
                    <i className="kt-nav__link-icon flaticon2-up"></i>
                    }
                </div>
            </div>
            <div className={collapseClass}>
                <div> {$$('practice_name')} : {practitioner.practiceName}</div>
                <div> {$$('practice_address')} : {practitioner.medicalPracticeAddress}</div>
                <div> {$$('practice_contact')} : {practitioner.practicePhoneNumber}</div>
                <div> {$$('practice_centre_code')} : {practitioner.regionalHealthCenterCode}</div>
                <div> {$$('practice_practitioner_code')} : {practitioner.uin}</div>
            </div>
        </li>
    )
}

export default GeneralPractitioner
