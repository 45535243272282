import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import {HEIGHT, WEIGHT} from "../../constants/entry_types";
import moment from "moment";
import * as PropTypes from "prop-types";

export class MedicalProfile extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <>
            <WeightHeight medicalProfile={this.props.medicalProfile}/>
            <BloodGroup blood_type={this.props.medicalProfile.data.blood_type}/>
            <ChronicConditions medicalProfile={this.props.medicalProfile} condition={condition => {
                let body = condition.name !== "NONE" ? <div className="card-body">
                        <h6 className="card-title">{condition.name}</h6>
                        {condition.description ? <p>{condition.description}</p> : ""}
                        <p className="small">{$$("diagnosed_in")}:
                            {condition.date_diagnosed > 0 ? " " + moment(condition.date_diagnosed).locale(this.props.i18n.selected.lang).format('MMMM YYYY') : " --"}</p>
                    </div> :
                    <div className="card-body light-green">
                        <h6 className="card-title">{$$("no_chronic_conditions")}</h6>
                    </div>;

                return <div key={condition.id} className="row">
                    <div className="col col-12" style={{"padding": "5px"}}>
                        <div className="card card-chronic-condition">
                            {body}
                        </div>
                    </div>
                </div>

            }}/>
            <Allergies medicalProfile={this.props.medicalProfile} callbackfn={allergy => {
                let body = allergy.type !== undefined ? <div className="card-body">
                        <h6 className="card-title">{CONVERTER.getAllergyNameForDisplay(allergy.type)}</h6>
                        <p className="text-danger">{allergy.name}</p>
                        <p className="text-danger">{$$("Severity")}: {CONVERTER.getSeverityForDisplay(allergy.severity)}</p>
                        {allergy.description ?
                            <p>{$$("details")}: {allergy.description}</p> : ""}
                        {allergy.medications ?
                            <p>{$$("Medications")}: {allergy.medications}</p> : ""}
                        <p className="small">{$$("diagnosed_in")}:
                            {allergy.date_diagnosed > 0 ? " " + moment(allergy.date_diagnosed).locale(this.props.i18n.selected.lang).format('MMMM YYYY') : " --"}</p>
                    </div> :
                    <div className="card-body light-green">
                        <h6 className="card-title">{$$("no_allergies")}</h6>
                    </div>;

                return <div key={allergy.id} className="row">
                    <div className="col col-12" style={{"padding": "5px"}}>
                        <div className="card card-allergy">
                            {body}
                        </div>
                    </div>
                </div>
            }}/>
        </>
    }
}

class BloodGroup extends Component {
    render() {
        return <div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-blood-group'/> {$$('blood_group')}
            </div>
            <div className="card-body row" style={{ /*"paddingTop": "5px"*/}}>
                {this.props.blood_type ?
                    <div className="col col-12">
                        <strong>{CONVERTER.getBloodGroupByType(this.props.blood_type)}</strong>
                    </div> : ""
                }
            </div>
        </div>
    }
}

class WeightHeight extends Component {
    render() {
        return <div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-weight-height'/> {$$("weight_and_height")}
            </div>
            <div className="card-body row" style={{ /*"paddingTop": "5px"*/}}>
                {this.props.medicalProfile.data.weight ?
                    <>
                        <div className="col col-6">
                            {$$("Weight")}: {+CONVERTER.convertByUnit(WEIGHT, this.props.medicalProfile.data.weight).toFixed(1)} {CONVERTER.getUnitByType(WEIGHT)}
                        </div>
                        <div className="col col-6">
                            {$$("Height")}: {+CONVERTER.convertByUnit(HEIGHT, this.props.medicalProfile.data.height).toFixed(1)} {CONVERTER.getUnitByType(HEIGHT)}
                        </div>
                    </> : ""
                }
            </div>
        </div>;
    }
}

class ChronicConditions extends Component {
    render() {
        return <div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-chronic-conditions'/> {$$("chronic_conditions")}
            </div>
            <div className="card-body" style={{ /*"paddingTop": "2px"*/}}>
                {
                    this.props.medicalProfile.data.chronic_conditions ?
                        this.props.medicalProfile.data.chronic_conditions.map(
                            this.props.condition
                        ) : ""
                }
            </div>

        </div>;
    }
}

ChronicConditions.propTypes = {
    medicalProfile: PropTypes.any,
    condition: PropTypes.func
};

class Allergies extends Component {
    render() {
        return <div className="card">
            <div className="card-header">
                <i className='kt-menu__link-icon flaticon2-allergies'/> {$$("allergies")}
            </div>
            <div className="card-body" style={{ /*"paddingTop": "2px"*/}}>
                {
                    this.props.medicalProfile.data.allergies ?
                        this.props.medicalProfile.data.allergies.map(
                            this.props.callbackfn
                        ) : ""
                }
            </div>
        </div>;
    }
}

Allergies.propTypes = {
    medicalProfile: PropTypes.any,
    callbackfn: PropTypes.func
};
