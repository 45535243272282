import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'

export default function CholesterolEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title">{CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>HDL: {CONVERTER.cholesterolPerUnit(props.entry.hdl)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                <div>LDL: {CONVERTER.cholesterolPerUnit(props.entry.ldl)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                <div>{$$("triglycerides")}: {CONVERTER.cholesterolPerUnit(props.entry.triglycerides)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                <div>{$$("total_cholesterol")}: {CONVERTER.cholesterolPerUnit(props.entry.total_cholesterol)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
