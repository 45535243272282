import { REGISTER_SUCCESS, REGISTER_CLEAR, REGISTER_ERROR } from "./actions";
import { authService } from '../service/auth_service'

/**
 * Register user, call the service and dispatch the appropiate reducer method 
 *
 * @param {object} user - The user data to persist
 * @returns {function} dispatch function
 */
export function register(user) {
    return (dispatch, getState) => {
        authService.register(user)
            .then(res => {
                if (res.id) {
                    dispatch({ type: REGISTER_SUCCESS, response: res });
                }
            })
            .catch((error) => {
                dispatch({ type: REGISTER_ERROR, response: error });
            });
    }
}

/**
 * Clear registration data action
 *
 * @returns {function} dispatch function
 */
export function clearRegistrationData() {
    return (dispatch, getState) => {
        dispatch({ type: REGISTER_CLEAR });
    }
}