import { REGISTER_SUCCESS, REGISTER_ERROR, REGISTER_CLEAR } from '../actions/actions'

/**
 * Register reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the register
 * @param {action} action the action to execute on the state
 */
export function register(state = {}, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
        case REGISTER_ERROR: {
            return { ...state, response: action.response };
        }
        case REGISTER_CLEAR: {
            const { response, ...rest } = state;
            return rest;
        }
        default: {
            return state
        }
    }
}
