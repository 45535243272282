import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'

export default function SaturationEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><span className="logbook-entry-icon-o2"><b>O<sub>2</sub></b></span> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("Saturation")}: {props.entry.oxygen_saturation.toFixed(2)} %</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
