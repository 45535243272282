export const en = {
    text:
    {
        title: "Medrec:M Monitor",
        main_page_label: "My Medical record",
        select_language_label: "Select language",
        login_label: "Log in",
        social_media_login_separator:"Or",
        logout_label: "Log out",
        save_btn_label: "Save",
        username_label: "Username",
        email_label: "E-mail",
        password_label: "Password",
        new_password_label: "New password",
        repeat_password_label: "Repeat password",
        email_required_message: "E-mail is required!",
        password_required_message: "Password is required!",
        login_form_invalid_credentials_message: "Invalid credentials!",
        register_label: "Register",
        fullname_label: "Fullname",
        register_form_password_confirm_label: "Confirm password",
        register_form_email_exists_message: "E-mail already exists!",
        register_form_email_not_correct_message: "E-mail is not correct!",
        register_form_passwords_not_match_message: "Passwords do not match!",
        register_form_password_length_message: "Password should have minimum 8 characters!",
        register_form_password_whitespace_message: "Password cannot start or end with whitespace character!",
        register_form_fullname_required: "Fullname is required!",
        register_form_password_confirm_required_message: "Password confirmation is required!",
        register_form_successful_message: "User successfully created!",
        confirm_btn_label: "Confirm",
        close_btn_label: "Close",
        Charts: "Charts",
        Logbook: "Logbook",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Systolic",
        Diastolic: "Diastolic",
        Pulse: "Pulse",
        notes: "Notes",
        total_cholesterol: "Total Cholesterol",
        triglycerides: "Triglycerides",
        Cholesterol: "Cholesterol",
        Weight: "Weight",
        Height: "Height",
        weight_and_height: "Weight and Height",
        chronic_conditions: "Chronic Conditions",
        allergies: "Allergies",
        blood_group: "Blood Group",
        details: "Details",
        blood_pressure: "Blood Pressure",
        settings_label: "Settings",
        unit_of_measurement_label: "Unit of measurement",
        cholesterol_units_label: "Cholesterol unit",
        classification_method_label: "Classification method",
        date_format_label: "Date format",
        time_24hour_label: "Use 24 hour clock",
        settings_updated_message: "Settings saved successfully",
        US: "US (lbs, oz, fl oz, inch)",
        metric: "Metric (kg, gram, ml, cm)",
        kg: "kg",
        pound: "lbs",
        inches: "inches",
        cm: "cm",
        None: "None",
        diagnosed_in: "Diagnosed in",
        mild: "Mild",
        mild_to_moderate: "Mild to moderate",
        moderate: "Moderate",
        moderate_to_severe: "Moderate to severe",
        severe: "Severe",
        life_threatening: "Life threatening",
        drug_allergy: "Drug Allergy",
        dust_allergy: "Dust Allergy",
        food_allergy: "Food Allergy",
        insect_allergy: "Insect Allergy",
        latex_allergy: "Latex Allergy",
        mold_allergy: "Mold Allergy",
        pet_allergy: "Pet Allergy",
        pollen_allergy: "Pollen Allergy",
        other_allergy: "Other Allergy",
        Severity: "Severity",
        Medications: "Medications",
        millimol_per_litre: "mmol/L",
        milligram_per_decilitre: "mg/dl",
        millimetre_of_mercury: "mmHg",
        week_label: "7 days",
        fortnight_label: "14 days",
        month_label: "30 days",
        quarter_label: "90 days",
        custom_label: "Custom",
        Symptoms: "Symptoms",
        Temperature: "Temperature",
        Hydration: "Hydration",
        Respiratory_rate: "Respiratory rate",
        Saturation: "Oxygen saturation",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Session timeout',
        sharing_label: "Sharing",
        pending_permission_list_label: "Permissions pending for aproval",
        approved_permission_list_label: "Approved permissions",
        deny_permission_label: "Deny",
        approve_permission_label: "Approve",
        request_label: "Request",
        request_permission_label: "Request permission",
        requested_user_does_not_exist_message: "User with requested e-mail does not exist",
        premission_request_success_message: "Permission requested successfuly",
        forgot_password: "Forgot password",
        privacy_policy: "Privacy policy",
        change_password_label: "Change password",
        gender_label: "Gender",
        country_label: "Country",
        male: "Male",
        female: "Female",
        city_label: "City",
        birthday_label: "Birthday",
        Dashboard: "Dashboard",
        user_info_label: "User info",
        latest_measurements_label: "Latest measurements",
        last_week_data: "Weekly charts",
        no_recent_measurements: "There are no measurements for this user.",
        loading_data_label: "Loading data...",
        logbook_no_data_label: "This user has not logged any entries yet.",
        symptoms_no_data_label: "This user has not logged any symptoms yet.",
        search_placeholder_label: "Search...",
        latest_symptoms_label: "Last 24 hours symptoms log",
        no_latest_symptoms_label: "No symptoms logged the last 24 hours.",
        profile_label: 'Profile',
        edit_profile_label: 'Edit profile',
        update_btn: 'Update',
        upload_image_label: 'Upload profile picture',
        user_info_updated_message: 'User profile updated successfuly',
        user_password_updated_message: 'Password updated successfully',
        user_password_update_error_message: 'Server error!',
        unsupported_image_format: 'The selected file format is not supported',
        choose_file_label: 'Choose image',
        Documents: "Documents",
        category_lobal: "Category",
        document_type_label: "Document type",
        type_description_label: "Type description",
        document_download_dialog_title: "Download document",
        confirmation_dialog_message: "The document ({fileName}) you want to download belongs to {owner} and can contain sensitive data. Make sure you keep it in a safe location or delete it after use.",
        documents_no_data_label: "This user has not uploaded any documents yet.",
        download_btn: "Download",
        cancel_btn: "Cancel",
        document_type_none: "None",
        document_type_epicrisis: "Epicrisis",
        document_type_prescription: "Prescription",
        document_type_lab_results: "Laboratory results",
        document_type_ambulance_sheet: "Medical report",
        document_type_referral: "Referral",
        document_type_other: "Other",
        all_label: "All",
        select_category: "Select category",
        documents_no_data_for_filters_label: "There are no documents corresponding to the selected filters.",
        pending_for_user_approval_label: "Users who need to approve your requests",
        start_date: "From date",
        end_date: "To date",
        load_more: "Show next",
        afternoon: "Afternoon",
        morning: "Morning",
        evening: "Evening",
        select_gender: "Select gender",
        my_documents_label: "My documents",
        my_children_label: "My children",
        Blood_sugar: "Blood sugar",
        HBA1C: "HbA1c",
        Ketones: "Ketones",
        Flow: "Flow",
        Color: "Color",
        Consistency: "Consistency",
        Urine_pH: "Urine pH",
        no_flow: "No flow",
        light: "Light",
        medium: "Medium",
        heavy: "Heavy",
        unexpected: "Unexpected",
        none: "None",
        black: "Black",
        brown: "Brown",
        dark_red: "Dark red",
        bright_red: "Bright red",
        pink: "Pink",
        orange: "Orange",
        gray: "Gray",
        waterly: "Waterly",
        clots: "Clots",
        mmol_mol: "mmol/mol",
        no_chronic_conditions: "No chronic conditions",
        no_allergies: "No allergies",
        Communication: "Communication",
        my_communication_label: "My communications",
        new_chat: "New chat",
        recent_chats_label: "Recent chats",
        available_users_label: "Available users",
        back: "Back",
        type_message: "Type message...",
        call: "Incoming call",
        incoming_call_message: "Incoming call from %1",
        incoming_call_another_user_message: "Incoming call from another user",
        accept: "Accept",
        no_user_selected_message: 'No user selected to call',
        ok: 'ok',
        no_answer: "No answer",
        no_answer_message: "No answer from %1",
        you_wrote: "You wrote",
        missed_call_label: "Missed call",
        rejected_call_label: "Rejected call",
        in_progress_call_label: "Call in progress",
        completed_call_label: "Call ended",
        ongoing_call: "Call ongoing!",
        medication_plan: "Medication plan",
        current_medications: "Current Medications",
        general_practitioner: "General Practitioner",
        practice_name:"Practice Name",
        practice_address:"Practice Address",
        practice_contact:"Contact number",
        practice_centre_code:"Centre code",
        practice_practitioner_code:"Practitioner Code",
        na:"N/A",
        times_per_day: "%1 %2 x %3 time(s) a day",
        every_x_hours: "%1 %2 x every %3 hour(s)",
        daily: 'Daily',
        every_x_days: "Every %1 day(s)",
        take_x_rest_x: "Take %1 day(s) and rest %2 day(s)",
        when_needed: "When needed",
        ongoing: "Ongoing",
        until_date: 'Until %1',
        for_x_days_until: "For %1 day(s), until %2",
        effective_from: "Effective from %1",
        medical_record_label: "Medical record",
        no_available_data: "Not provided",
        Lab_Results: "Lab Results",
        select_laboratory_label: "Select laboratory",
        lab_results_open_results_label: "Open results",
        lab_results_close_results_label: "Close results",
        lab_results_no_data_for_filters_label: "There are no laboratories results corresponding to the selected filters.",
        lab_results_no_data_label: "This user has not imported any laboratories results yet.",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Select',
        preview_image: 'Preview',
        OK: 'OK',
        delete_image_modal_title: 'Remove image',
        delete_image_modal_message: 'Are you sure you want to remove your image?',
        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ampule(s)',
                        display_short: ''
                    },
                    application: {
                        display_long: 'application(s)',
                        display_short: ''
                    },
                    capsule: {
                        display_long: 'capsule(s)',
                        display_short: ''
                    },
                    drop: {
                        display_long: 'drop(s)',
                        display_short: ''
                    },
                    gram: {
                        display_long: 'gram(s)',
                        display_short: 'g(s)'
                    },
                    injection: {
                        display_long: 'injection(s)',
                        display_short: ''
                    },
                    mg: {
                        display_long: 'milligram(s)',
                        display_short: 'mg(s)'
                    },
                    ml: {
                        display_long: 'milliliter(s)',
                        display_short: 'ml(s)'
                    },
                    packet: {
                        display_long: 'packet(s)',
                        display_short: ''
                    },
                    patch: {
                        display_long: 'patch(es)',
                        display_short: ''
                    },
                    piece: {
                        display_long: 'piece(s)',
                        display_short: ''
                    },
                    tablet: {
                        display_long: 'tablet(s)',
                        display_short: ''
                    },
                    puff: {
                        display_long: 'puff(s)',
                        display_short: ''
                    },
                    spray: {
                        display_long: 'spray(s)',
                        display_short: ''
                    },
                    suppository: {
                        display_long: 'suppository(ies)',
                        display_short: ''
                    },
                    tbsp: {
                        display_long: 'tablespoon(s)',
                        display_short: 'tbsp(s)'
                    },
                    tsp: {
                        display_long: 'teaspoon(s)',
                        display_short: 'tsp(s)'
                    },
                    unit: {
                        display_long: 'unit(s)',
                        display_short: ''
                    }
                }
            }
        }
    }
}
