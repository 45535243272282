/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter, Link } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { LayoutContextConsumer } from "../LayoutContext";
import { ReactComponent as SortNum1Icon } from "../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg";
import * as builder from "../../ducks/builder";
import { $$ } from '../../../helpers/localization'
import { Routes } from "../../../constants/routes";
import Communication from '../../../components/communication/Communication'
import { CHAT_VIEWPOSITION } from "../../../constants/enums";
import {
  changeChatViewPosition,
  setOngoingCallFlag
} from "../../../actions/chat_actions"
// import BreadCrumbs from "./components/BreadCrumbs";

class SubHeader extends React.Component {

    /**
     * Set the ongoing call flag to show label in subheader
     * 
     * @param {boolean} value - true if call is ongoing
     */
    setOngoingCallFlag = (value) => {
      this.props.setOngoingCallFlag(value);
  }

    /**
     * Set flag to indicate the chat view expansion
     */
    expandView = () => {
        this.props.changeChatViewPosition(CHAT_VIEWPOSITION.FULL);
    }

    /**
     * Set flag to indicate the chat view shrinking
     */
    shrinkView = () => {
        this.props.changeChatViewPosition(CHAT_VIEWPOSITION.ONLY_CHAT);
    }

    render() {
        const {
            subheaderCssClasses,
            subheaderContainerCssClasses,
            subheaderMobileToggle,
            unreadMessages
        } = this.props;

        const unreadMessagesCount = unreadMessages && unreadMessages.list ? unreadMessages.list.reduce((sum, m) => sum + m.count, 0) : 0;
        return (
            <div>
                <div
                    id="kt_subheader"
                    className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
                >
                    <div className={`kt-container ${subheaderContainerCssClasses}`}>
                        <div className="kt-subheader__main">
                            {subheaderMobileToggle && (
                                <button
                                    className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left"
                                    id="kt_subheader_mobile_toggle"
                                >
                                    <span />
                                </button>
                            )}

                            <LayoutContextConsumer>
                                {/*{({ subheader: { title, breadcrumb } }) => (*/}

                                {({ subheader: { title, translate, page } }) => (
                                    <>
                                        {translate &&
                                            <><h3 className="kt-subheader__title">{$$(translate)}</h3>
                                                <span className="kt-subheader__separator kt-subheader__separator--v" /></>
                                        }
                                        {/*<BreadCrumbs items={breadcrumb} />*/}
                                        <span className="kt-subheader__desc">
                                            {`/${page}` === Routes.MAIN_PAGE || `/${page}` === Routes.USER_MEDICAL_RECORD ? this.props.selectedUser.fullname : this.props.userInfo.fullname}
                                        </span>
                                        {this.props.selectedUser.email && this.props.selectedUser.email.includes('@') && 
                                            <span className="kt-subheader__separator kt-subheader__separator--v" />
                                        }

                                        <a href={`mailto:${this.getEmail(page)}`}>
                                            {this.getEmail(page)}
                                        </a>
                                    </>
                                )}
                            </LayoutContextConsumer>

                        </div>
                    </div>
                </div>
                <Communication 
                    viewPosition={this.props.chatViewPosition} 
                    expandView={this.expandView} 
                    shrinkView={this.shrinkView} 
                    setOngoingCallFlag={this.setOngoingCallFlag}/>
            </div>
        );
    }

    getEmail(page) {
        let selectedUserEmail = this.props.selectedUser.email && this.props.selectedUser.email.includes('@') ? this.props.selectedUser.email : '';
        return `/${page}` === Routes.MAIN_PAGE || `/${page}` === Routes.USER_MEDICAL_RECORD ? selectedUserEmail : this.props.userInfo.email;
    }
}

const mapStateToProps = store => ({
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    subheaderMobileToggle: objectPath.get(
        store.builder.layoutConfig,
        "subheader.mobile-toggle"
    ),
    subheaderCssClasses: builder.selectors.getClasses(store, {
        path: "subheader",
        toString: true
    }),
    subheaderContainerCssClasses: builder.selectors.getClasses(store, {
        path: "subheader_container",
        toString: true
    }),
    selectedUser: store.selectedUser.data,
    userInfo: store.userInfo.data,
    unreadMessages: store.chat.unreadMessages,
    chatViewPosition: store.chat.chatViewPosition,
    onGoingCall: store.chat.onGoingCall
});

export default withRouter(connect(mapStateToProps, { changeChatViewPosition, setOngoingCallFlag })(SubHeader));
