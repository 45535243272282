import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import { formatUtils } from '../../utils/formatUtils';
import LabResultTable from './LabResultTable';
import LabResultMicrobiologyTable from './LabResultMicrobiologyTable';

export class LabResultEntry extends Component {
    constructor(props) {
        super(props);
    }

    state = {
      expandTable: false,
      result: null,
      items: [],
      microbiologyTableWidth: null
    }

    componentDidMount() {
        const result = formatUtils.formatAsJSON(this.props.entry.result);
        let items = [];
        for(var i = 0; i < result.tests.length; ++i) {
            for(var j = 0; j < result.tests[i].groups.length; ++j) {
                var item = {};
                item.group = result.tests[i].groups[j].label;
                item.tests = result.tests[i].groups[j].tests.map(t => t.label);
                items.push(item);
            }
        }

        for(var i = 0; i < result.microbiology.length; ++i) {
            var item = {};
            item.group = result.microbiology[i].label;
            item.tests = [].concat(result.microbiology[i].test);
            items.push(item);
        }

        this.setState({ items: this.state.items.concat(items) });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.filters.laboratory !== this.props.filters.laboratory
          || prevProps.filters.before_date_time !== this.props.filters.before_date_time
          || prevProps.filters.after_date_time !== this.props.filters.after_date_time) {
            this.setState({ expandTable: false,  result: null });
        }
    }

    /**
	   * Handles click event on a laboratory result
     *
     * @param {object} e the event object
	   */
    onLaboratoryResultClick = (e) => {
        e.preventDefault();
        const shouldExpandTable = !this.state.expandTable;
        if(shouldExpandTable) {
            this.setState({ expandTable: shouldExpandTable, result: formatUtils.formatAsJSON(this.props.entry.result) });
        } else {
            this.setState({ expandTable: shouldExpandTable, result: null });
        }
    }

    /**
	   * Returns the laboratory name of the current lab result entry
     *
     * @returns {string} the laboratory name name of the current entry
	   */
    getLaboratoryName = () => {
        if(this.props.labs) {
            return this.props.labs.find(l => l.lab === this.props.entry.laboratory).name;
        }
    }

    /**
	   * Returns the DOM elements representing the group and the tests of the particular lab result entry
     *
     * @returns {Array} the created DOM elements based on the lab entry
	   */
    getGroupsAndTests = () => {
        if(this.state.items) {
            var groupAndTestElements = this.state.items.map((item, index) => {
              return <div key={index}>
                        <b>{formatUtils.format(item.group)}</b>
                        <br/>
                          {item.tests.map((t) => {
                            return <div key={t} className={"non-test-cells"}>
                                        <span>{t}</span>
                                        <br/>
                                    </div>
                          })}
                      </div>
                    });
            return groupAndTestElements;
        }
    }

    /**
     * Sets the microbiology table width equal to the tests table's width
     * 
     * @param {number} width the value of the width in the tests table
     */
    handleTestTableWidthChange = (width) => (
        this.setState({ microbiologyTableWidth: width })
    )

    render() {
        return (
            <div className="card">
                <div className="card-body" style={{ "paddingTop": "7px", "paddingBottom": "7px" }}>
                    <div>{this.getGroupsAndTests()}</div>
                    <br />
                    {this.props.entry.notes && !this.props.entry.notes !== "" && 
                        <div>
                            {$$("notes")}: <i>{formatUtils.format(this.props.entry.notes)}</i>
                            <br /><br />
                        </div>
                    }
                    <a href="#" id="details" onClick={this.onLaboratoryResultClick}>{this.state.expandTable ? $$("lab_results_close_results_label") : $$("lab_results_open_results_label")}</a>
                    {this.state.expandTable &&
                        <div>
                            <br />
                            <LabResultTable resize={this.handleTestTableWidthChange} result={this.state.result} laboratory={this.props.entry.laboratory}/>
                            <br/>
                            <LabResultMicrobiologyTable width={this.state.microbiologyTableWidth} result={this.state.result} />
                            <br/>
                        </div>
                    }
                    <h6 className="small text-right">
                        <i>{this.getLaboratoryName()}</i>
                    </h6>
                </div>
            </div>
        )
    }
}

export default LabResultEntry