import { fetchHelper } from '../helpers/request_helper';
import { GET_CHILDREN_URL } from '../constants/api_paths';

export const childrenService = {
    getChildren
};

/**
 * Get the children of the logged user
 * 
 * @returns {object} promise object
 */
export function getChildren() {
    return fetchHelper.callGet(GET_CHILDREN_URL);
}
