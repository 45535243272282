import React, { Component } from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import { $$ } from '../../helpers/localization'
import moment from 'moment'


export class LabResultsFilters extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        laboratory: this.props.filters.laboratory,
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time
    }
    
    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({ name, value }) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    };

    /**
	 * Change the lab results filters state.
	 *
	 * @param {object} range - the custom range selected
	 */
    onRangeSelected = (range) => {
        this.setState({
            beforeDateTime: range.endDate ? moment(range.endDate).valueOf() : null,
            afterDateTime: range.startDate ? moment(range.startDate).valueOf() : null
        }, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    }

    render() {
        return (
            <div className="form-inline">
                <div>
                    <Select
                        name="laboratory"
                        options={this.props.laboratoryOptions}
                        value={this.state.laboratory}
                        onChange={this.onSelectChange}
                        placeHolder={$$('laboratory_label')} />
                </div>
                <div>
                    <DateRangePicker
                        onRangeSelected={this.onRangeSelected}
                        startDate={this.props.filters.after_date_time}
                        endDate={this.props.filters.before_date_time} />
                </div>
            </div>
        )
    }
}

export default LabResultsFilters
