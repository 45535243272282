import React, { Component } from 'react'
import SubHeader from './SubHeader';

export class Tabs extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        activeTabIndex: this.props.defaultActiveTabIndex,
        activeSubTabIndex: null
    }

    /**
     * Handle tab click, set active tab index to state
     * 
     * @param {number} tabIndex the index of the tab to be set to active
     */
    handleTabClick = (tabIndex) => {
        this.setState({
            activeTabIndex: tabIndex ? tabIndex : this.props.defaultActiveTabIndex,
            activeSubTabIndex: null
        });
    }

    /**
     * Handle sub tab click, set active sub tab index to state
     * 
     * @param {number} subTabIndex the index of the sub tab to be set to active
     */
    handleSubTabClick = (subTabIndex) => {
        this.setState({
            activeSubTabIndex: subTabIndex ? subTabIndex : this.props.defaultActiveSubTabIndex,
        });
    }

    /**
     * Render tabs, add properties
     * 
     * @returns {Array} array of React elements
     */
    renderTabs() {
        return React.Children.map(this.props.children, (child, index) => {
            if (child) {
                return React.cloneElement(child, {
                    onClick: this.handleTabClick,
                    tabIndex: index,
                    isActive: index === this.state.activeTabIndex,
                });
            }
            return '';
        });
    }

    /**
     * Render sub header. If sub header contains tabs,
     * pass the necesary properties to the sub header component(activeSubHeaderIndex is mandatory in the Tabs component). 
     * If not filter only SubHeader elements.
     * 
     * @returns {Array} array of React elements
     */
    renderSubHeader() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild.props && activeChild.props.children) {
            const subChild = activeSubTabIndex !== null && activeSubTabIndex !== undefined ? activeChild.props.children : null;
            if (subChild && subChild.props.children && subChild.props.children[activeSubTabIndex]) {
                return React.cloneElement(subChild, {
                    onClick: this.handleSubTabClick,
                    activeSubTabIndex: activeSubTabIndex,
                });
            } else if (activeChild.props.children.length > 0 && activeChild.props.children.some(c => c.type === SubHeader)) {
                let subHeaders = activeChild.props.children.filter(c => c.type === SubHeader);
                return subHeaders;
            }
        }
    }

    /**
     * Render the active tab content. If tab has subtabs render active subtab content.
     * 
     * @returns {Array} array of React elements
     */
    renderTabContent() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild && activeChild.props.children) {
            if (activeSubTabIndex !== null && activeSubTabIndex !== undefined) {
                return activeChild.props.children.props.children[activeSubTabIndex].props.children;
            } else if (Array.isArray(activeChild.props.children) && activeChild.props.children.length > 0) {
                return activeChild.props.children.filter(c => c.type !== SubHeader);
            } else if (activeChild.props.children.type !== SubHeader) {
                return activeChild.props.children;
            }
        }
    }

    /**
     * Get the active sub tab index if there is any
     * 
     * @param {object} children - children of the tabs component
     * @param {number} activeTabIndex - index of the active tab
     * @returns {Array} array of React elements
     */
    getActiveSubTabIndex(children, activeTabIndex) {
        let activeSubTabIndex = null;
        if (this.state.activeSubTabIndex) {
            activeSubTabIndex = this.state.activeSubTabIndex;
        } else if (children[activeTabIndex] && children[activeTabIndex].props) {
            activeSubTabIndex = children[activeTabIndex].props.defaultActiveSubTabIndex;
        }
        return activeSubTabIndex;
    }

    render() {
        return (
            <div>
                <div className="card-header tab-btn-header">
                    <div className="btn-group" role="group">
                        {this.renderTabs()}
                    </div>
                    <div>
                        {this.renderSubHeader()}
                    </div>
                </div>
                <div className="card-body main-card">
                    {this.renderTabContent()}
                </div>
            </div>
        )
    }
}

export default Tabs
