import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateUserPassword, clearUserPassword } from '../../actions/users_actions'
import PasswordForm from './PasswordForm';

class Password extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <PasswordForm
                userPassword={this.props.userPassword}
                updateUserPassword={this.props.updateUserPassword}
                clearUserPassword={this.props.clearUserPassword}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    userPassword: state.userPassword,
    i18n: state.language.selected
})

export default connect(mapStateToProps, { updateUserPassword, clearUserPassword })(Password)
