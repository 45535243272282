import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { $$ } from '../../helpers/localization'
import { FORGOT_PASSWORD_URL } from '../../constants/api_paths'
import { PRIVACY_POLICY_URL } from '../../constants/api_paths'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth_actions'

export class LoginForm extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        email: '',
        password: '',
        formclass: '',
        errors: {}
    };

    static getDerivedStateFromProps(props) {
        if (props.email && props.password) {
            return { email: props.email, password: props.password };
        }
        return null;
    }

    onSuccess() {
        const authInstance = window.gapi.auth2.getAuthInstance();
        const googleUser = authInstance.currentUser.get();
        var id_token = googleUser.getAuthResponse().id_token;
        this.props.onLoginWithGoogle(id_token, true);

    }

    initialiseGoogleSignIn(){
        window.gapi.load('auth2',() =>{
            window.gapi.auth2.init({
                client_id:process.env.GOOGLE_SIGIN_CLIENTID,
                scope: 'profile email'
            })
            window.gapi.load('signin2', () =>{
                const opts  = {
                    width: 245,
                    height: 40,
                    'longtitle': true,
                    'theme': 'dark',
                    onsuccess: this.onSuccess.bind(this),
                }
                window.gapi.signin2.render('gapi-button', opts);
            })
        });
    }
    
    componentDidMount() {
        this.initialiseGoogleSignIn();
        if (this.props.email && this.props.password) {
            this.props.onLogin(this.props.email, this.props.password, true);
        }
        var self = this;
        FB.Event.subscribe('auth.authResponseChange', function(response) {
            if(response.status=="connected")
            {
                self.props.onLoginWithFacebook(response.authResponse.accessToken, true);
            }
        });
        FB.XFBML.parse();
    }

	/**
	 * Set the state to the latest change in the input value.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
	 * On checkbox checked handler, set the correct value to state
	 *
	 * @param {object} evt - The event handler argument
	 */
    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    /**
    * On label clicked handler, set the correct value to state
    *
    * @param {object} evt - The event handler argument
    */
    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

	/**
	 * Form submit handler, validate data and set error in state if any. Call login action and clear state.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onSubmit = (evt) => {
        evt.preventDefault();

        if (this.state.formclass !== "was-validated") {
            this.setState({ formclass: "was-validated" });
        }

        if (evt.target.checkValidity() === true) {
            const email = this.state.email;
            const password = this.state.password;
            this.props.onLogin(email, password, null);
            this.setState({
                email: '',
                password: '',
                formclass: ''
            })
        }
    }

    /**
	 * Open new tab with the forgot password url
	 */
    onForgotPasswordClick = () => {
        window.open(FORGOT_PASSWORD_URL.replace("{lang}", this.props.i18n.selected.lang), "_blank");
    }

    /**
     * Open new tab with the privacy policy
     */
    onPrivacyPolicyClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(this.createPPUrl(), "_blank");
    }

    /**
     * Create a language aware Privacy Policy URL
     * @returns {string}
     */
    createPPUrl = () => {
        let lang = this.props.i18n.selected.lang;
        lang = lang.toString() === "sq" ? "en" : lang;
        return PRIVACY_POLICY_URL.replace("{lang}", lang)
    }


    /**
	 * Get error message to display based on error code
	 *
	 * @returns {string} error message to display
	 */
    getErrorMessage() {
        if (this.props.auth_data.request.error) {
            switch (this.props.auth_data.request.error.status) {
                case 401: return $$('login_form_invalid_credentials_message');
                default:
                    return this.props.auth_data.request.error.message
            }
        } else if (this.props.auth_data.refreshRequest.error) {
            switch (this.props.auth_data.refreshRequest.error.status) {
                case 401: return $$('refresh_token_expired_message');
                default:
                    return this.props.auth_data.refreshRequest.error.message
            }
        }
    }

    render() {
        let errorMsg = '';
        if (this.props.auth_data.request.error || this.props.auth_data.refreshRequest.error) {
            errorMsg = (
                <div className="alert alert-danger">
                    {this.getErrorMessage()}
                </div>
            );
        }

        const spinnerClass = classnames({
            "spinner-border": true,
            "hidden": !(this.props.email && this.props.password)
        });

        return (
            <div className="form-backgroud">
                <div className="login-form">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="text-center card-title">{$$("title")}</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                                {errorMsg}
                                <div className="form-group">
                                    <input type="text" className="form-control" value={this.state.email} placeholder={$$('email_label')} name="email" onChange={this.onInputChange} required />
                                    <div className="invalid-feedback">
                                        {$$('email_required_message')}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" value={this.state.password} placeholder={$$('password_label')} name="password" onChange={this.onInputChange} required />
                                    <div className="invalid-feedback">
                                        {$$('password_required_message')}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <a href="#" onClick={this.onForgotPasswordClick} style={{ "marginLeft": "5px" }}>{$$('forgot_password')}</a>
                                </div>
                                <div className="form-group">
                                    <div className="mx-auto text-center" style={{maxWidth:"250px"}}>
                                        <button type="submit" className="btn btn-primary btn-block">
                                            {$$("login_label")}
                                            <div className={spinnerClass} style={{ "marginLeft": "10px" }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                        <Link className="btn btn-primary btn-block" to="/register">{$$("register_label")}</Link>
                                        <hr className="hr-text" data-content={$$("social_media_login_separator")}/>
                                        <div id="gapi-button" className="g-signin2" data-width="300" data-height="200"></div>
                                        <div className="fb-login-button mt-3" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <a href={this.createPPUrl()} onClick={this.onPrivacyPolicyClick} >{$$('privacy_policy')}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { logout })(LoginForm);
