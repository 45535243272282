import React, { Component } from 'react'
import 'regenerator-runtime/runtime';
import { $$ } from '../../helpers/localization'
import GroupedSymptoms from './GroupedSymptoms'
import _ from 'underscore'
import moment from 'moment'
import { CONVERTER } from '../../utils/converter';

export class SymptomsLog extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Fetch selected user symptoms
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: 0,
            size: 0
        };
        if (this.props.userId && (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished)) {
            if (this.props.symptoms.entries && this.props.symptoms.entries.length > 0) {
                const entiresLength = this.props.symptoms.entries.length;
                params.before_date_time = this.props.symptoms.entries[entiresLength - 1].date_time;
            }
            this.props.fetchEntries(this.props.userId, params);
        }
    }

    /**
	 * Get the symptoms grouped by date time
     * 
     * @returns {Array} of grouped symptoms
	 */
    getGroupedSymptoms = () => {
        const noData = <div className="loader" key={0}>{$$('symptoms_no_data_label')}</div>;
        if (this.props.symptoms.entries && this.props.symptoms.entries.length > 0) {
            const groupedByDate = _.groupBy(this.props.symptoms.entries, (result) => moment(result.date_time).format('YYYY-MM-DD'));
            const keys = Object.keys(groupedByDate);
            if (keys.length > 0) {
                return keys.map((dateTime, idx) => {
                    return <GroupedSymptoms
                        date_time={dateTime}
                        key={idx}
                        symptoms={groupedByDate[dateTime]}
                        settings={this.props.settings}
                        i18n={this.props.i18n}
                        nomenclature={this.props.nomenclature}
                    />
                });
            }
        } else {
            return noData;
        }
    }

    render() {
        const hasMore = !this.props.symptoms.isLastPage && (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished) && !this.props.symptoms.request.error;
        return (
            <div className="card inner-card">
                <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getGroupedSymptoms()}
                        </div>
                        {hasMore &&
                            <div className="text-center" style={{ "marginTop": "10px" }}>
                                <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default SymptomsLog
