import { $$ } from '../helpers/localization'

export const SETTING_OPTIONS = {
    UNITS_OF_MEASUREMENTS: [
        {
            value: "US",
            text: "US"
        },
        {
            value: "METRIC",
            text: "metric"
        }
    ],
    CHOLESTEROL_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    CLASSIFICATION_METHODS: [
        {
            value: "ACC_AHA",
            text: "ACC_AHA"
        },
        {
            value: "ESC_ESH",
            text: "ESC_ESH"
        },
        {
            value: "JNC8",
            text: "JNC8"
        },
        {
            value: "NICE_2019",
            text: "NICE_2019"
        },
        {
            value: "CANADA",
            text: "CANADA"
        }
    ],
    DATE_FORMATS: [
        {
            value: "dd.MM.yyyy",
            text: "dd.MM.yyyy"
        },
        {
            value: "dd/MM/yyyy",
            text: "dd/MM/yyyy"
        },
        {
            value: "dd-MM-yyyy",
            text: "dd-MM-yyyy"
        },
        {
            value: "MM/dd/yyyy",
            text: "MM/dd/yyyy"
        },
        {
            value: "yyyy-MM-dd",
            text: "yyyy-MM-dd"
        },
        {
            value: "yyyy.MM.dd",
            text: "yyyy.MM.dd"
        },
        {
            value: "yyyy/MM/dd",
            text: "yyyy/MM/dd"
        }
    ]
}

export const CHART_FILTERS = {
    PERIOD_OPTIONS: [
        {
            value: "WEEK",
            text: "week_label"
        },
        {
            value: "FORTNIGHT",
            text: "fortnight_label"
        },
        {
            value: "MONTH",
            text: "month_label"
        },
        {
            value: "QUARTER",
            text: "quarter_label"
        },
        {
            value: "CUSTOM",
            text: "custom_label"
        }
    ]
}

export const GENDER = {
    OPTIONS: [
        {
            value: "",
            text: "select_gender"
        },
        {
            value: "MALE",
            text: "male"
        },
        {
            value: "FEMALE",
            text: "female"
        }
    ]
}

export const DOCUMENTS_FILTER = {
    TYPE: [
        {
            value: "",
            text: "all_label"
        },
        {
            value: "EPICRISIS",
            text: "document_type_epicrisis"
        },
        {
            value: "PRESCRIPTION",
            text: "document_type_prescription"
        },
        {
            value: "LAB_RESULTS",
            text: "document_type_lab_results"
        },
        {
            value: "AMBULANCE_SHEET",
            text: "document_type_ambulance_sheet"
        },
        {
            value: "REFERRAL",
            text: "document_type_referral"
        },
        {
            value: "OTHER",
            text: "document_type_other"
        },
    ]
}

export const MENSTRUATION = {
    FLOW: [
        {
            value: 0,
            text: "no_flow"
        },
        {
            value: 1,
            text: "light"
        },
        {
            value: 2,
            text: "medium"
        },
        {
            value: 3,
            text: "heavy"
        },
        {
            value: 4,
            text: "unexpected"
        }
    ],
    COLOR: [
        {
            value: 0,
            color: "",
            text: "none"
        },
        {
            value: 1,
            color: "#000000",
            text: "black"
        },
        {
            value: 2,
            color: "#b33c00",
            text: "brown"
        },
        {
            value: 3,
            color: "#cc0000",
            text: "dark_red"
        },
        {
            value: 4,
            color: "#FF0000",
            text: "bright_red"
        },
        {
            value: 5,
            color: "#ff1a75",
            text: "pink"
        },
        {
            value: 6,
            color: "#FFA500",
            text: "orange"
        },
        {
            value: 7,
            color: "#808080",
            text: "gray"
        }
    ],
    CONSISTENCY: [
        {
            value: 0,
            text: "none"
        },
        {
            value: 1,
            text: "waterly"
        },
        {
            value: 2,
            text: "clots"
        }
    ],
}

/**
 * Return select options with the text resolved to the selected language
 *
 * @param {Array} options - the list of options
 */
export function getResolvedOptions(options) {
    return options.map(o => {
        return { value: o.value, text: o.text ? $$(o.text) : o.text }
    });
}

/**
 * Return text for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getResolvedText(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return $$(option.text);
        }
    }
    return '';
}

/**
 * Return color for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getColorForOption(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return option.color;
        }
    }
    return '';
}