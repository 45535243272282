import { combineReducers } from 'redux'
import { authentication } from './auth_reducers'
import { register } from './register_reducers'
import { language } from './language_reducers'
import { users, selectedUser, logBook, charts, dashboardCharts, latestUserMeasurements, userInfo, userPassword, medicalProfile } from './users_reducers'
import { settings } from './settings_reducer'
import { nomenclature, symptoms } from './symptoms_reducers'
import { permissions } from './permissions_reducers'
import { metronic } from "../_metronic";
import { documents} from './documents_reducers'
import { chat } from './chat_reducers'
import { medication } from './medications_reducers'
import { practitioner } from './general_practitioner_reducer'
import { laboratories } from './lab-results_reducers'
import { labResults } from './lab-results_reducers'

const reducer = combineReducers({
    builder: metronic.builder.reducer,
    authentication,
    register,
    language,
    users,
    selectedUser,
    logBook,
    charts,
    dashboardCharts,
    settings,
    nomenclature,
    symptoms,
    permissions,
    latestUserMeasurements,
    userInfo,
    userPassword,
    documents,
    medicalProfile,
    chat,
    medication,
    practitioner,
    laboratories,
    labResults
});

export default reducer;
