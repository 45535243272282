
import {
    ALL_PERMISSIONS_REQUEST_SENT,
    ALL_PERMISSIONS_REQUEST_SUCCESS,
    ALL_PERMISSIONS_REQUEST_ERROR,
    PERMISSION_DENY_REQUEST_SUCCESS,
    PERMISSION_APPROVE_REQUEST_SUCCESS,
    PERMISSION_DENY_REQUEST_ERROR,
    PERMISSION_APPROVE_REQUEST_ERROR,
    REQUEST_PERMISSION_SUCCESS,
    REQUEST_PERMISSION_ERROR
} from './actions';
import { permissionsService } from '../service/permissions_service';
import { getAllUsers } from './users_actions';

/**
 * Get all permissions for user.
 *
 * @returns {function} dispatch function
 */
export function getPermissions() {
    return (dispatch, getState) => {
        dispatch({ type: ALL_PERMISSIONS_REQUEST_SENT });
        permissionsService.getPermissions().then((res) => {
            if (res) {
                dispatch({ type: ALL_PERMISSIONS_REQUEST_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: ALL_PERMISSIONS_REQUEST_ERROR, result: err });
        });
    }
}

/**
 * Approve pending permission. Select the approved user after refreshing the list.
 *
 * @param {number} permissionId - id of the pending permission
 * @param {number} userId - id of the user to select after refreshing the list
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function approvePermission(permissionId, userId, params) {
    return (dispatch, getState) => {
        return permissionsService.approvePermission(permissionId, params).then((res) => {
            dispatch({ type: PERMISSION_APPROVE_REQUEST_SUCCESS });
            dispatch(getPermissions());
            dispatch(getAllUsers(null, userId, true));
        }).catch((err) => {
            dispatch({ type: PERMISSION_APPROVE_REQUEST_ERROR, result: err });
        })
    }
}

/**
 * Deny the permission for an user. Deselect the user if it is the selected one.
 *
 * @param {number} permissionId - id of the permission to deny
 * @param {number} userId - id of the user to de-select
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function denyPermission(permissionId, userId, params) {
    return (dispatch, getState) => {
        return permissionsService.denyPermission(permissionId, params).then((res) => {
            dispatch({ type: PERMISSION_DENY_REQUEST_SUCCESS });
            dispatch(getPermissions());
            dispatch(getAllUsers(null, userId, true));
        }).catch((err) => {
            dispatch({ type: PERMISSION_DENY_REQUEST_ERROR, result: err });
        })
    }
}

/**
 * Request permission for an user
 *
 * @param {string} email - email of the user to request permission for
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function requestPermission(email, params) {
    return (dispatch, getState) => {
        return permissionsService.requestPermission(email, params).then((res) => {
            dispatch({ type: REQUEST_PERMISSION_SUCCESS, result: res });
            dispatch(getPermissions());
        }).catch((err) => {
            dispatch({ type: REQUEST_PERMISSION_ERROR, result: err });
        })
    }
}