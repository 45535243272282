import React, { Component } from 'react'

export class Select extends Component {

    state = {
        value: this.props.value,
        error: false
    };

    static getDerivedStateFromProps(nextProps) {
        return { value: nextProps.value, error: nextProps.error }
    }

    /**
	 * Set the state to the latest selected option.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onChange = (evt) => {
        const name = this.props.name;
        const value = evt.target.value;
        this.setState({ value });
        this.props.onChange({ name, value });
    };

    render() {
        const options = this.props.options.map((o, i) => {
            return <option value={o.value} key={i}>{o.text}</option>
        });

        return (
            <div>
                <label>{this.props.label}</label>
                <select onChange={this.onChange} value={this.state.value} className="custom-select my-1 mr-sm-2" required={this.props.required}>
                    {options}
                </select>
                <div className="invalid-feedback">{this.state.error}</div>
            </div>
        )
    }
}

export default Select
