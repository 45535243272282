import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { validators } from '../../helpers/validators'
import { $$ } from '../../helpers/localization'
import Select from "../shared/Select";
import { countryOptions } from "../../constants/countries";
import moment from "moment";
import { GENDER, getResolvedOptions } from '../../constants/select_options';

export class RegisterForm extends Component {

    static propTypes = {
        register: PropTypes.func
    }

    state = {
        fullname: '',
        email: '',
        city: '',
        country: 'bg',
        birthday: 0,
        gender: '',
        password: '',
        rePassword: '',
        formclass: '',
        errors: {}
    }

    static getDerivedStateFromProps(props, state) {
        //Check if there has been an error returned from the register submition and if so se the error state
        if (props.registration.response && props.registration.response.status === 403) {
            props.clearRegistrationData();
            return {
                errors: { email: 'register_form_email_exists_message' }
            }
        }
        return state;
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({ name, value }) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = moment(evt.target.value).valueOf();
        this.setState(fields);
    };

    /**
     * Form submit handler, validate data and set error in state if any. Call register action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({ errors: formErrors });
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({ formclass: "was-validated" });
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            const { errors, rePassword, ...data } = this.state;
            this.props.register(data);
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email)) {
            errors.email = 'register_form_email_not_correct_message';
        }
        if (this.state.password !== this.state.rePassword) {
            errors.password = 'register_form_passwords_not_match_message';
        }
        if (this.state.password && this.state.password.length < 8) {
            errors.password = 'register_form_password_length_message';
        }
        return errors;
    }

    render() {
        return (
            <div className="form-backgroud">
                <div className="register-form">
                    <div className="card">
                        <div className="card-header register-header">
                            <h2 className="text-center card-title register-title">{$$('register_label')}</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                                <div className="form-group register-control">
                                    <input type="text" className="form-control" value={this.state.fullname} placeholder={$$('fullname_label')} name="fullname" onChange={this.onInputChange} required />
                                    <div className="invalid-feedback">
                                        {$$('register_form_fullname_required')}
                                    </div>
                                </div>
                                <div className="form-group register-control">
                                    <input type="text" className={this.state.errors.email ? "custom-error form-control" : "form-control"} value={this.state.email} placeholder={$$('email_label')} name="email" onChange={this.onInputChange} required />
                                    <div className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.email ? $$(this.state.errors.email) : $$('email_required_message')}
                                    </div>
                                </div>
                                <div className="form-group register-control">
                                    <input type="password" className={this.state.errors.password ? "custom-error form-control" : "form-control"} value={this.state.password} placeholder={$$('password_label')} name="password" onChange={this.onInputChange} required minLength={8} />
                                    <div className={this.state.errors.password ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.password ? $$(this.state.errors.password) : $$('password_required_message')}
                                    </div>
                                </div>
                                <div className="form-group register-control">
                                    <input type="password" className="form-control" value={this.state.rePassword} placeholder={$$('register_form_password_confirm_label')} name="rePassword" onChange={this.onInputChange} required />
                                    <div className="invalid-feedback">
                                        {$$('register_form_password_confirm_required_message')}
                                    </div>
                                </div>
                                <div className="row register-control">
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('gender_label')}
                                            name="gender"
                                            value={this.state.gender}
                                            options={getResolvedOptions(GENDER.OPTIONS)}
                                            onChange={this.onSelectChange} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label>{$$('birthday_label')}</label>
                                        <input type="date" className="form-control  my-1 mr-sm-2" value={moment(this.state.birthday).format('YYYY-MM-DD')} placeholder={$$('birthday_label')} name="birthday" onChange={this.onDateChange} />
                                    </div>
                                </div>
                                <div className="row register-control" style={{ "marginTop": "5px" }}>
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('country_label')}
                                            name="country"
                                            options={countryOptions()}
                                            value={this.state.country}
                                            onChange={this.onSelectChange} />
                                    </div>
                                    <div className="col-sm-6 register-inline-control">
                                        <input type="text" className="form-control" value={this.state.city} placeholder={$$('city_label')} name="city" onChange={this.onInputChange} />
                                    </div>
                                </div>
                                <div className="form-group register-control" style={{ "marginTop": "10px" }}>
                                    <div className="col-xs-7 col-md-7 mx-auto">
                                        <button type="submit" className="btn btn-primary btn-block">{$$('register_label')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterForm
