import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import {$$} from "../../../helpers/localization";
import { CHAT_VIEWPOSITION } from "../../../constants/enums";
import { changeChatViewPosition } from "../../../actions/chat_actions";
import classnames from "classnames";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  state = {};

  componentDidMount() {
    new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Remove the event listener when the component unmounts
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  /**
   * Updates window dimensions when the resize event occurs
   */
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  /**
   * On chat icon click. Expand or shrink the chat view based on the given state
   * 
   * @param {object} e - the event object
   */
  onClickChatIcon = (e) => {
    if (this.props.chatViewPosition === CHAT_VIEWPOSITION.INITIAL) {
        this.props.changeChatViewPosition(CHAT_VIEWPOSITION.ONLY_CHAT);
    } else if (this.props.chatViewPosition === CHAT_VIEWPOSITION.ONLY_CHAT || this.props.chatViewPosition === CHAT_VIEWPOSITION.FULL) {
        this.props.changeChatViewPosition(CHAT_VIEWPOSITION.INITIAL);
    }
  }

  render() {
    const {
      headerLogo,
      asideDisplay,
      headerMenuSelfDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
      unreadMessages
    } = this.props;

    const unreadMessagesCount = unreadMessages && unreadMessages.list ? unreadMessages.list.reduce((sum, m) => sum + m.count, 0) : 0;
    const chatClass = classnames("chat-icon-margins", {
        "hidden": this.state.width > 1024
    });

    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          {/*<Link to="/">
            <img alt="logo" src={headerLogo} />
          </Link>*/}
          <Link to="">
            <h4>{$$('title')}</h4>
            {/* <img alt="logo" src={this.props.headerLogo} /> */}
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="kt-header-mobile__toggler kt-header-mobile__toggler--left"
              id="kt_aside_mobile_toggler"
            >
              <span />
            </button>
          )}

          {/*{headerMenuSelfDisplay && (
            <button
              className="kt-header-mobile__toggler"
              id="kt_header_mobile_toggler"
            >
              <span />
            </button>
          )}*/}

          <div className={chatClass}>
              <div className="kt-header-mobile__wrapper">
                  <div id="chatIcon" style={{ "marginRight": "1em", "marginTop": "1.1em", "position": "relative" }}>
                      <a className="flaticon2-chat-2" style={{ "fontSize": "1.5em" }} onClick={this.onClickChatIcon}></a>
                      {unreadMessagesCount > 0 &&
                          <span className="undread-messages-pill chat-icon-unread-messages"></span>
                      }
                  </div>
              </div>
          </div>

          <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerLogo: builder.selectors.getStickyLogo(store),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  headerMenuSelfDisplay:
    objectPath.get(store.builder.layoutConfig, "header.menu.self.display") ===
    true,
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "aside_menu"
  }),
  unreadMessages: store.chat.unreadMessages,
  chatViewPosition: store.chat.chatViewPosition
});

export default connect(mapStateToProps, { changeChatViewPosition })(HeaderMobile);
