import React, { Component } from 'react'
import WeightEntry from './WeightEntry';
import {
    WEIGHT,
    CHOLESTEROL,
    BLOOD_PRESSURE,
    MEDICATION,
    HYDRATION,
    TEMPERATURE,
    RESPIRATORY_RATE,
    SATURATION,
    MENSTRUATION,
    HEIGHT,
    URINE_PH,
    KETONES,
    HBA1C,
    BLOOD_SUGAR
} from '../../constants/entry_types';
import CholesterolEntry from './CholesterolEntry';
import BloodPressureEntry from './BloodPressureEntry';
import MedicationEntry from './MedicationEntry';
import { $$ } from '../../helpers/localization'
import HydrationEntry from './HydrationEntry';
import TemperatureEntry from './TemperatureEntry';
import RespiratoryRateEntry from './RespiratoryRateEntry';
import SaturationEntry from './SaturationEntry';
import { CONVERTER } from '../../utils/converter';
import moment from 'moment'
import MenstruationEntity from './MenstruationEntity';
import HeightEntry from './HeightEntry';
import UrinePHEntry from './UrinePHEntry';
import KetonesEntry from './KetonesEntry';
import HBA1CEntry from './HBA1CEntry';
import BloodSugarEntry from './BloodSugarEntry';

export class GroupedLogBookEntries extends Component {
    constructor(props) {
        super(props);
    }

    /**
	 * Get the logbook entries grouped by date time
     * 
     * @returns {Array} of logbook entries
	 */
    getLogBookEntries = () => {
        if (this.props.logBook && this.props.logBook.length > 0) {
            return this.props.logBook.map((entry, idx) => {
                switch (entry.entry_type) {
                    case WEIGHT:
                        return <WeightEntry entry={entry} key={idx} unit={this.props.settings.data.unitsOfMeasurement} />
                    case CHOLESTEROL:
                        return <CholesterolEntry entry={entry} key={idx} unit={this.props.settings.data.cholesterolUnit} />
                    case BLOOD_PRESSURE:
                        return <BloodPressureEntry entry={entry} key={idx} />
                    case MEDICATION:
                        return <MedicationEntry entry={entry} key={idx} />
                    case HYDRATION:
                        return <HydrationEntry entry={entry} key={idx} />
                    case TEMPERATURE:
                        return <TemperatureEntry entry={entry} key={idx} />
                    case RESPIRATORY_RATE:
                        return <RespiratoryRateEntry entry={entry} key={idx} />
                    case SATURATION:
                        return <SaturationEntry entry={entry} key={idx} />
                    case MENSTRUATION:
                        return <MenstruationEntity entry={entry} key={idx} />
                    case HEIGHT:
                        return <HeightEntry entry={entry} key={idx} />
                    case URINE_PH:
                        return <UrinePHEntry entry={entry} key={idx} />
                    case KETONES:
                        return <KetonesEntry entry={entry} key={idx} />
                    case HBA1C:
                        return <HBA1CEntry entry={entry} key={idx} />
                    case BLOOD_SUGAR:
                        return <BloodSugarEntry entry={entry} key={idx} />
                    default:
                        return '';
                }
            });
        }
    }

    render() {
        return (
            <div className="card grouped-card">
                <div className="card-header">
                    {moment(this.props.date_time).format(CONVERTER.getSelectedDateTimeFormat(true))}, {moment(this.props.date_time).locale(this.props.i18n.lang).format('dddd')}
                </div>
                <div className="card-body" style={{ "padding": "0" }}>
                    {this.getLogBookEntries()}
                </div>
            </div>
        )
    }
}

export default GroupedLogBookEntries
