import React from 'react'
import Navbar from './NavBar'

export const DefaultContainer = (props) => {
    return (
        <div>
            <Navbar />
            {props.children}
        </div>
    )
}
