import React, { Component } from 'react'
import { connect } from 'react-redux'
import VideoChat from '../chat/VideoChat'
import { chatService } from '../../service/chat_service';
import { permissionsService } from '../../service/permissions_service';
import TabsContainer from '../main/TabsContainer'
import { getUnreadMessages } from '../../actions/chat_actions'

export class Communication extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    state = {
        lastMessages: [],
        availableUsers: [],
    }

    /**
     * Fetch the available users for chat when the component mounts and the latest messages.
     */
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.getLastMessages();
            this.props.getUnreadMessages();
            permissionsService.getPermissions().then((res) => {
                if (res && res.length > 0) {
                    this.setState({ availableUsers: res.filter(r => r.userApproved !== null && r.userApproved !== undefined && r.authorizingUser !== this.props.loggedUser.id) });
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Get last messages for the logged user.
     */
    getLastMessages = () => {
        chatService.getLatestMessages().then((res) => {
            if (res) {
                this.setState({ lastMessages: res.filter(m => m.to_user_id !== this.props.loggedUser.id || m.from_user_id !== this.props.loggedUser.id) });
            }
        });
    }

    render() {
        return (
            <VideoChat
                lastMessages={this.state.lastMessages}
                refreshLastMessages={this.getLastMessages}
                availableUsers={this.state.availableUsers}
                viewPosition={this.props.viewPosition}
                expandView={this.props.expandView}
                shrinkView={this.props.shrinkView}
                setOngoingCallFlag={this.props.setOngoingCallFlag}>
                <div className="row">
                    <div className="col-sm-12">
                        <div>
                            <div className="card">
                                <TabsContainer selectedUser={this.props.selectedUser} i18n={this.props.i18n} />
                            </div>
                        </div>
                    </div>
                </div>
            </VideoChat>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.authentication,
    loggedUser: state.userInfo.data,
    selectedUser: state.selectedUser.data,
    i18n: state.language
})

const mapDispatchToProps = {
    getUnreadMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(Communication)
