import React from 'react'
import { $$ } from '../../helpers/localization'

export default function PermissionItem(props) {
    return (
        <li className="list-group-item" style={{ "border": "none" }}>
            <div className="row">
                <div className="col-xs-8 col-md-8">
                    <div style={{"marginTop": props.permission.authorizingUserEmail && props.permission.authorizingUserEmail.includes('@') ? "" : '10px'}}>
                      <strong>{props.permission.authorizingUserFullName}</strong>
                    </div>
                    <div style={{ "marginTop": "10px" }}>
                        {props.permission.authorizingUserEmail && props.permission.authorizingUserEmail.includes('@') ? props.permission.authorizingUserEmail : ''}
                    </div>
                </div>
                <div className="col-xs-2 col-md-2">
                    {!props.permission.authApproved ?
                        (
                            <button className="btn btn-primary" onClick={() => props.onApprove(props.permission.id, props.permission.authorizingUser)}>
                                {$$('approve_permission_label')}
                            </button>
                        ) : ''}
                </div>
                <div className="col-xs-2 col-md-2">
                    <button className="btn btn-primary" onClick={() => props.onDeny(props.permission.id, props.permission.authorizingUser)}>
                        {props.cancelBtnLabel ? props.cancelBtnLabel : $$('deny_permission_label')}
                    </button>
                </div>
            </div>
        </li>
    )
}
