import React from 'react'
import { authHelper } from '../../helpers/auth_helper';
import { Routes } from '../../constants/routes';
import { Route, Redirect } from 'react-router-dom'
import MainContainer from '../layout/MainContainer';
import { DefaultContainer } from '../layout/DefaultContainer';
import Layout from "../../_metronic/layout/Layout";
import { useLocation } from 'react-router-dom'
import { logout } from '../../actions/auth_actions'
import { connect } from 'react-redux'
import VideoCall from './VideoCall';

function VideoCallWrapper({
    ...rest
}) {

    const isLoggedIn = authHelper.isLoggedIn();

    /**
     * If not included on both previous cases, redirect user to the desired route.
     */
    return (
        <div className="kt-grid--animateContent kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid kt-grid--animateContent-finished video-call-wrapper">
            {isLoggedIn && <VideoCall />}
            {rest.children}
        </div>
    );
}

export default VideoCallWrapper
