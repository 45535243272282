import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUserInfo, updateUserInfo } from '../../actions/users_actions'
import UserProfileForm from './UserProfileForm';
import moment from 'moment'

class UserProfile extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        successfulUpdate: false
    }

    /**
	 * Check if update opeartin is done, if so display successful update message.
	 *
	 * @param {object} prevProps - the previous props
	 */
    componentDidUpdate(prevProps) {
        const prevUserInfo = prevProps.userInfo;
        const currentUserInfo = this.props.userInfo
        if (prevUserInfo) {
            if (moment(prevUserInfo.date_modified).valueOf() < moment(currentUserInfo.date_modified).valueOf()) {
                this.setState({ successfulUpdate: true });
            }
        }
    }

    render() {
        return (
            <UserProfileForm
                showSuccessfulAlert={this.state.successfulUpdate}
                userInfo={this.props.userInfo}
                updateUserInfo={this.props.updateUserInfo}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo.data,
    i18n: state.language.selected
})

export default connect(mapStateToProps, { getUserInfo, updateUserInfo })(UserProfile)
