import { fetchHelper } from '../helpers/request_helper';
import { GET_USER_DOCUMENTS_URL, DOWNLOAD_DOCUMENT_URL } from '../constants/api_paths';

export const documentsService = {
    fetchSelectedUserDocuments,
    downloadFile
};

/**
 * Fetch the selected user uploaded documents
 * 
 * @param {string} userId id of the user to fetch documents for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserDocuments(userId, params) {
    return fetchHelper.callGet(GET_USER_DOCUMENTS_URL.replace('{userId}', userId), params);
}

/**
 * Download file with the given id.
 * 
 * @param {number} documentId id of the document to download
 * @returns {object} promise object
 */
export function downloadFile(documentId) {
    return fetchHelper.getBlob(DOWNLOAD_DOCUMENT_URL.replace('{documentId}', documentId));
}


