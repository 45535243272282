import { symptomsService } from '../service/symptoms_service'
import {
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    NO_MORE_SYMPTOMS,
    DUMMY
} from './actions';

/**
 * Get nomencalture action.
 *
 * @returns {function} dispatch function
 */
export function getNomencalture() {
    return (dispatch, getState) => {
        dispatch({ type: GET_NOMENCLATURE_REQUEST_SENT });
        symptomsService.getNomencalture().then((res) => {
            if (res) {
                dispatch({ type: GET_NOMENCLATURE_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: GET_NOMENCLATURE_ERROR, result: err });
        });
    }
}

/**
 * Fetch symptoms logs for a given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserSymptomsLog(userId, params) {
    return (dispatch, getState) => {
        if(!params) {
            params = {
                before_date_time: 0,
                size: 0
            };
        }
        dispatch({ type: FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT });
        return symptomsService.fetchSelectedUserSymptoms(userId, params).then((res) => {
            if(getState().selectedUser.data.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                dispatch({ type: FETCH_SELECTED_USER_SYMPTOMS_SUCCESS, result: res })
            } else {
                dispatch({ type: NO_MORE_SYMPTOMS })
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_SYMPTOMS_ERROR, result: err });
        })
    }
}