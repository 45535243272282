import React, { Component } from 'react'
import { CONVERTER } from '../../utils/converter'
import moment from 'moment'
import { DocumentEntry } from './DocumentEntry'

export class GroupedDocuments extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const documents = this.props.documents && this.props.documents.length > 0 ? this.props.documents.map((entry, idx) => {
            return <DocumentEntry key={idx} index={idx} entry={entry} showModal={this.props.showModal} />
        }, this) : noData;
        return (
            <div className="card grouped-card">
                <div className="card-header">
                    {moment(this.props.date_time).format(CONVERTER.getSelectedDateTimeFormat(true))}, {moment(this.props.date_time).locale(this.props.i18n.lang).format('dddd')}
                </div>
                <div className="card-body" style={{ "padding": "0" }}>
                    {documents}
                </div>
            </div>
        )
    }
}

export default GroupedDocuments
