import {
    LOGIN_REQUEST_SENT,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_ERROR,
    LOGOUT,
    TOKEN_REFRESH_REQUEST_SENT,
    TOKEN_REFRESH_REQUEST_SUCCESS,
    TOKEN_REFRESH_REQUEST_ERROR
} from "./actions";
import { authService } from '../service/auth_service'
import { getUserSettings } from "./settings_actions";
import { getNomencalture } from "./symptoms_actions";
import history from '../helpers/history'
import { Routes } from '../constants/routes';
import { getUserInfo } from "./users_actions";
import { getChildren } from "./children_actions";

/**
 * Login action, dispatch login, store user date in localstorage
 *
 * @param {string} email - E-mail used for login
 * @param {string} password - Password used for login
 * @param {boolean} linkLogin - flag that if set means that the login is from a temporary account link
 * @returns {function} dispatch function
 */
export function login(email, password, linkLogin) {
    return (dispatch, getState) => {
        dispatch({ type: LOGIN_REQUEST_SENT });
        authService.login(email, password)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({ type: LOGIN_REQUEST_SUCCESS, response: res });
                    dispatch(getUserInfo());
                    dispatch(getUserSettings());
                    dispatch(getNomencalture());
                    dispatch(getChildren());
                    history.push(Routes.MAIN_PAGE);
                }
            })
            .catch((error) => {
                if (linkLogin) {
                    history.push(Routes.MAIN_PAGE);
                } else {
                    dispatch({ type: LOGIN_REQUEST_ERROR, response: error });
                }
            });
    }
}

/**
 * Login with google action, dispatch login, store user date in localstorage
 *
 * @param {string} id_token
 * @param {boolean} linkLogin - flag that if set means that the login is from a temporary account link
 * @returns {object} promise object
 */
export function loginWithGoogle(id_token,linkLogin) {
    return (dispatch, getState) => {
        dispatch({ type: LOGIN_REQUEST_SENT });
        authService.loginWithGoogle(id_token)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({ type: LOGIN_REQUEST_SUCCESS, response: res });
                    dispatch(getUserInfo());
                    dispatch(getUserSettings());
                    dispatch(getNomencalture());
                    dispatch(getChildren());
                    history.push(Routes.MAIN_PAGE);
                }
            })
            .catch((error) => {
                if (linkLogin) {
                    history.push(Routes.MAIN_PAGE);
                } else {
                    dispatch({ type: LOGIN_REQUEST_ERROR, response: error });
                }
            });
    }
}

/**
 * Login with facebook action, dispatch login, store user date in localstorage
 *
 * @param {string} acessToken - access token from facebook
 * @param {boolean} linkLogin - flag that if set means that the login is from a temporary account link
 * @returns {object} promise object
 */
export function loginWithFacebook(accessToken, linkLogin) {
    return (dispatch, getState) => {
        dispatch({ type: LOGIN_REQUEST_SENT });
        authService.loginWithFacebook(accessToken)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({ type: LOGIN_REQUEST_SUCCESS, response: res });
                    dispatch(getUserInfo());
                    dispatch(getUserSettings());
                    dispatch(getNomencalture());
                    dispatch(getChildren());
                    history.push(Routes.MAIN_PAGE);
                }
            })
            .catch((error) => {
                if (linkLogin) {
                    history.push(Routes.MAIN_PAGE);
                } else {
                    dispatch({ type: LOGIN_REQUEST_ERROR, response: error });
                }
            });
    }
}

/**
 * Refresh token action, dispatch action.
 *
 * @param {string} token - the refresh token
 * @returns {function} dispatch function
 */
export function refreshToken(token) {
    return (dispatch, getState) => {
        dispatch({ type: TOKEN_REFRESH_REQUEST_SENT });
        authService.refreshToken(token)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({ type: TOKEN_REFRESH_REQUEST_SUCCESS, response: res });
                }
            })
            .catch((error) => {
                dispatch({ type: TOKEN_REFRESH_REQUEST_ERROR, response: error });
                dispatch({ type: LOGOUT });
                history.push(Routes.LOGIN);
            });
    }
}

/**
 * Logout action, clear user data and tokens from storage.
 */
export function logout(username, password) {
    return (dispatch, getState) => {
        dispatch({ type: LOGOUT });
        if (username && password) {
            history.push(Routes.LOGIN + `?username=${username}&password=${password}`);
        } else {
            history.push(Routes.LOGIN);
        }
    }
}
