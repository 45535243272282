import { fetchHelper } from '../helpers/request_helper';
import { GET_NOMENCLATURE_URL, GET_USER_SYMPTOMS_LOG_URL } from '../constants/api_paths';

export const symptomsService = {
    getNomencalture,
    fetchSelectedUserSymptoms
};

/**
 * Get nomencalture from the service
 * 
 * @returns {object} promise object
 */
export function getNomencalture() {
    return fetchHelper.callGet(GET_NOMENCLATURE_URL);
}

/**
 * Fetch the selected user symptoms
 * 
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserSymptoms(userId, params) {
    return fetchHelper.callGet(GET_USER_SYMPTOMS_LOG_URL.replace('{userId}', userId), params);
}


