import React from "react";
import LanguageSelector from "../../../components/layout/LanguageSelector"
import { connect } from 'react-redux'
import { logout } from '../../../actions/auth_actions'
import { changeLanguage } from '../../../actions/language_actions'
import { Routes } from '../../../constants/routes'
import { Link } from 'react-router-dom'
import { $$ } from '../../../helpers/localization'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'
import { CHAT_VIEWPOSITION } from "../../../constants/enums";
import { changeChatViewPosition } from "../../../actions/chat_actions";
import classnames from "classnames";

class Topbar extends React.Component {
    // google sign-in is here so that we know that we have .gapi.auth2.getAuthInstance() for sign out
    initialiseGoogleSignIn(){
        window.gapi.load('auth2',() =>{
            window.gapi.auth2.init({
                client_id:process.env.GOOGLE_SIGIN_CLIENTID,
                scope: 'profile email'
            })
            window.gapi.load('signin2', () =>{
            })
        });
    }


    state = {};

    componentDidMount() {
      this.initialiseGoogleSignIn()
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * Remove the event listener when the component unmounts
     */
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * Updates window dimensions when the resize event occurs
     */
    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /**
     * On chat icon click. Expand or shrink the chat view based on the given state
     *
     * @param {object} e - the event object
     */
    onClickChatIcon = (e) => {
      if (this.props.chatViewPosition === CHAT_VIEWPOSITION.INITIAL) {
          this.props.changeChatViewPosition(CHAT_VIEWPOSITION.ONLY_CHAT);
      } else if (this.props.chatViewPosition === CHAT_VIEWPOSITION.ONLY_CHAT || this.props.chatViewPosition === CHAT_VIEWPOSITION.FULL) {
          this.props.changeChatViewPosition(CHAT_VIEWPOSITION.INITIAL);
      }
    }

    logoutFromFacebook() {
        var tthis = this;
        FB.getLoginStatus(function (response) {
            if (response && response.status === 'connected') {
                FB.logout(() => {
                    tthis.props.logout();
                });
            } else {
                tthis.props.logout();
            }
        });
    }

    render() {
        const {
          unreadMessages
        } = this.props;

        const unreadMessagesCount = unreadMessages && unreadMessages.list ? unreadMessages.list.reduce((sum, m) => sum + m.count, 0) : 0;
        const chatClass = classnames("kt-header__toolbar", {
          "hidden": this.state.width <= 1024,
        });

        return (
            <div className="kt-header__topbar">
                <LanguageSelector className="show" changeLanguage={this.props.changeLanguage} />

                <div className={chatClass}>
                    <div id="chatIcon" style={{ "marginTop": "1.7em", "position": "relative" }}>
                        <a className="flaticon2-chat-2 text-info" style={{ "fontSize": "1.3em" }} onClick={this.onClickChatIcon}></a>
                        {unreadMessagesCount > 0 &&
                            <span className="undread-messages-pill chat-icon-unread-messages"></span>
                        }
                    </div>
                </div>

                <div className="dropdown" style={{ "alignSelf": "center" }}>
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <FontAwesomeIcon icon={faUserCog} />
                    </a>

                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="dropdown-item" to={Routes.PROFILE}><i className="kt-nav__link-icon flaticon-profile-1" /> <span className="kt-nav__link-text dropdown-link">{$$('profile_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.CHANGE_PASSWORD}><i className="kt-nav__link-icon flaticon-lock" /> <span className="kt-nav__link-text dropdown-link">{$$('change_password_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.LOGIN} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            var tthis=this;
                            var auth2 = window.gapi.auth2.getAuthInstance();
                            if(auth2.isSignedIn.get()) {
                                auth2.signOut().then(()=>{
                                    tthis.props.logout();
                                });
                                auth2.disconnect();
                            } else {
                                this.logoutFromFacebook();
                            }
                        }}><i className="kt-nav__link-icon flaticon-logout" /> <span className="kt-nav__link-text dropdown-link">{$$('logout_label')}</span></Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication.data,
        i18n: state.language,
        unreadMessages: state.chat.unreadMessages,
        chatViewPosition: state.chat.chatViewPosition
    }
}

export default connect(mapStateToProps, { logout, changeLanguage, changeChatViewPosition })(Topbar);
