import { usersService } from "../service/users_service"
import {
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    ALL_USERS_ERROR,
    ALL_USERS_FILTER_CHANGE,
    CLEAR_USERS,
    SELECT_USER,
    CLEAR_SELECTED_USER,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    NO_MORE_ENTRIES,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    CHANGE_CHARTS_FILTERS,
    DUMMY,
    GET_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    REQUEST_SENT,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    CLEAR_USER_PASSWORD,
    LOGIN_REQUEST_SUCCESS
} from "./actions"
import history from '../helpers/history'
import { Routes } from "../constants/routes";
import { fetchSelectedUserSymptomsLog } from "./symptoms_actions";
import { fetchSelectedUserDocuments } from "./documents_actions";
import moment from "moment";
import { fetchSelectedUserMedicationPlan } from "./medications_actions";
import { fetchSelectedUserGeneralPractitioner } from "./generalPractitioner_actions";
import { fetchSelectedUserLabResults } from "./lab-results_actions"

/**
 * Get all users action, call the service and dispatch the appropiate reducer method
 *
 * @param {object} params - params to send to service
 * @param {string} userToSelect - id of the user to select after the data is fetched
 * @param {boolean} resetList - flag, if set to true reset the users list, otherwise append to it
 * @returns {function} dispatch function
 */
export function getAllUsers(params, userToSelect, resetList) {
    if (!params) {
        params = {
            size: 500,
            page: 0
        }
    }
    return (dispatch, getState) => {
        dispatch({ type: ALL_USERS_REQUEST_SENT });
        usersService.getAllUsers(params)
            .then((res) => {
                const selectedUser = getState().selectedUser.data;
                dispatch({ type: ALL_USERS_SUCCESS, result: res, reset: resetList });
                if (res.content.length > 0) {
                    //Check if we should select an user after the refresh or if there is any selected already
                    if (userToSelect || !selectedUser.id) {
                        let user;
                        //Get the user to select
                        if (userToSelect) {
                            user = res.content.find(u => u.id === userToSelect)
                            if (!user) {
                                const childrenMenu = getState().builder.menuConfig.aside.items.find(i => i.title === "Children");
                                if(childrenMenu && childrenMenu.submenu) {
                                    user = childrenMenu.submenu.find(u => u.id === userToSelect);
                                }
                            }
                        }
                        //If the user to select is in the list, select it. Else if there is no selected user currently
                        //or the user to select id is the same as the selected user deselect this user and select the first one
                        if (!user && (!selectedUser.id || selectedUser.id === userToSelect)) {
                            //If a permission is denied, set selected user the current logged-in user
                            if(getState().userInfo.data && getState().userInfo.data.id) {
                                user = res.content.find(u => u.id === getState().userInfo.data.id);
                            }
                            if(!user) {
                                user = res.content[0];
                            }
                        }

                        if (user) {
                            dispatch(selectUser(user, true));
                        }
                    }
                }
            })
            .catch((err) => {
                dispatch({ type: ALL_USERS_ERROR, result: err });
            })
    }
}

/**
 * Get user info action.
 *
 * @returns {function} dispatch function
 */
export function getUserInfo() {
    return (dispatch, getState) => {
        usersService.getUserInfo()
            .then((res) => {
                if (res) {
                    dispatch({ type: GET_USER_PROFILE, result: res });
                }
            })
            .catch((err) => {
                dispatch({ type: GET_USER_PROFILE_ERROR, result: err });
            })
    }
}

/**
 * Update user info action
 *
 * @param {object} userInfo the new user info data
 * @returns {function} dispatch function
 */
export function updateUserInfo(userInfo) {
    return (dispatch, getState) => {
        usersService.updateUserInfo(userInfo)
            .then((res) => {
                if (res) {
                    dispatch({ type: UPDATE_USER_PROFILE, result: res });
                    const selectedUser = getState().selectedUser.data;
                    if (selectedUser && selectedUser.id === res.id) {
                        dispatch(selectUser(res, true));
                    }
                }
            })
            .catch((err) => {
                dispatch({ type: UPDATE_USER_PROFILE_ERROR, result: err });
            })
    }
}

/**
 * Update user password action
 *
 * @param {object} userPassword the new user password
 * @returns {function} dispatch function
 */
export function updateUserPassword(userPassword) {
    return (dispatch, getState) => {
        dispatch({ type: REQUEST_SENT });
        usersService.updateUserPassword(userPassword)
            .then((res) => {
                if (res) {
                    if (res.access_token && res.refresh_token) {
                        dispatch({ type: LOGIN_REQUEST_SUCCESS, response: res });
                        dispatch({ type: UPDATE_USER_PASSWORD, result: res });
                    }
                }
            })
            .catch((err) => {
                dispatch({ type: UPDATE_USER_PASSWORD_ERROR, result: err });
            })
    }
}

/**
 * Clear the user password action.
 *
 * @returns {function} dispatch function
 */
export function clearUserPassword() {
    return (dispatch, getState) => {
        dispatch({ type: CLEAR_USER_PASSWORD });
    }
}

/**
 * Clear the user list action.
 *
 * @returns {function} dispatch function
 */
export function clearUsers() {
    return (dispatch, getState) => {
        dispatch({ type: CLEAR_USERS });
    }
}

/**
 * Select user action, call the service and dispatch the appropiate reducer method
 *
 * @param {object} user - the selected user object
 * @param {boolean} stopRedirect - flag, if set to true do not redirect to main page after selecting user
 * @returns {function} dispatch function
 */
export function selectUser(user, stopRedirect) {
    return (dispatch, getState) => {
        dispatch({ type: SELECT_USER, user: user });
        dispatch(fetchSelectedUserMedicalProfile(user.id));
        dispatch(fetchDashboardChartsData(user.id));
        dispatch(fetchSelectedUserLatestMeasurements(user.id));
        dispatch(fetchSelectedUserLogbookEntries(user.id));
        dispatch(fetchSelectedUserSymptomsLog(user.id));
        dispatch(fetchChartsData(user.id));
        dispatch(fetchSelectedUserDocuments(user.id));
        dispatch(fetchSelectedUserMedicationPlan(user.id));
        dispatch(fetchSelectedUserGeneralPractitioner(user.id));
        dispatch(fetchSelectedUserLabResults(user.id));

        if (history.location !== Routes.USER_MEDICAL_RECORD && history.location !== Routes.MAIN_PAGE && !stopRedirect) {
            history.push(Routes.USER_MEDICAL_RECORD);
        }
    }
}

/**
 * Change user filter action
 *
 * @param {string} filter - the given filter
 * @returns {function} dispatch function
 */
export function filterUsers(filter) {
    return (dispatch, getState) => {
        dispatch({ type: ALL_USERS_FILTER_CHANGE, filter: filter });
    }
}

/**
 * Clear the seleced user data action
 *
 * @returns {function} dispatch function
 */
export function clearSelectedUserData() {
    return (dispatch, getState) => {
        dispatch({ type: CLEAR_SELECTED_USER })
    }
}

/**
 * Fetch the medical profile for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserMedicalProfile(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserMedicalProfile(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res) {
                dispatch({ type: FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR, result: err });
        })
    }
}

/**
 * Fetch latest logged data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLatestMeasurements(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserLatestMeasurements(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                dispatch({ type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR, result: err });
        })
    }
}

/**
 * Fetch the logbook entries for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLogbookEntries(userId, params) {
    return (dispatch, getState) => {
        if (!params) {
            params = {
                before_date_time: moment().valueOf()
            };
        }
        dispatch({ type: FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT });
        return usersService.fetchSelectedUserLogbookEntries(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                dispatch({ type: FETCH_SELECTED_USER_ENTRIES_SUCCESS, result: res });
            } else {
                dispatch({ type: NO_MORE_ENTRIES });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_ENTRIES_ERROR, result: err });
        })
    }
}

/**
 * Fetch the charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: state.charts.filters.period
        };
        if (state.charts.filters.before_date_time) {
            params.before_date_time = state.charts.filters.before_date_time;
        }
        if (state.charts.filters.after_date_time) {
            params.after_date_time = state.charts.filters.after_date_time;
        }
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res) {
                dispatch({ type: FETCH_CHARTS_DATA_SUCCESS, result: res, userId: userId })
            }
        }).catch((err) => {
            dispatch({ type: FETCH_CHARTS_DATA_ERROR, result: err });
        });
    }
}

/**
 * Fetch the latest charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchDashboardChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: 'WEEK'
        };
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res) {
                dispatch({ type: FETCH_DASHBOARD_CHARTS_DATA_SUCCESS, result: res, userId: userId })
            }
        }).catch((err) => {
            dispatch({ type: FETCH_DASHBOARD_CHARTS_DATA_ERROR, result: err });
        });
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeChartsFilters(filters) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_CHARTS_FILTERS,
            period: filters.period,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}
