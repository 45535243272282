import {
  FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT,
  FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS,
  FETCH_SELECTED_USER_LAB_RESULTS_ERROR,
  NO_MORE_SELECTED_USER_LAB_RESULTS,
  CHANGE_LAB_RESULTS_FILTERS,
  RESET_SELECTED_USER_LAB_RESULTS_SUCCESS,
  DUMMY,
  FETCH_LABORATORIES_REQUEST_SENT,
  FETCH_LABORATORIES_SUCCESS,
  FETCH_LABORATORIES_ERROR
} from './actions';
import moment from 'moment';
import { labResultsService } from '../service/lab-results_service';


/**
 * Fetch all the available laboratories
 * 
 * @param {object} params params sent together with the request
 * @returns {object} dispatch function
 */
export function fetchLaboratories(params) {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_LABORATORIES_REQUEST_SENT });
        return labResultsService.fetchLaboratories(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({ type: FETCH_LABORATORIES_SUCCESS, result: res });
            } 
        }).catch((err) => {
            dispatch({ type: FETCH_LABORATORIES_ERROR, result: err });
        })
    }
}

/**
* Fetch the lab results of the selected user, dispatch the appropriate action.
*
* @param {object} userId - id of the selected user
* @param {object} params - params to send with the request
* @param {boolean} resetList - flag, if set to true reset the lab results list
* @returns {function} dispatch function
*/
export function fetchSelectedUserLabResults(userId, params, resetList) {
    return (dispatch, getState) => {
        if (!params) {
            const beforeDateTime = getState().labResults.filters.before_date_time;
            params = {
                before_date_time: beforeDateTime ? beforeDateTime : moment().valueOf(),
                size: 500
            };
        }
        dispatch({ type: FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT });
        return labResultsService.fetchSelectedUserLabResults(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({ type: RESET_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res });
                } else {
                    dispatch({ type: FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res });
                }
            } else {
                dispatch({ type: NO_MORE_SELECTED_USER_LAB_RESULTS });
                if (resetList) {
                    dispatch({ type: RESET_SELECTED_USER_LAB_RESULTS_SUCCESS, result: res });
                }
            }
        }).catch((err) => {
            dispatch({ type: FETCH_SELECTED_USER_LAB_RESULTS_ERROR, result: err });
        })
    }
}

/**
* Change the filters to apply to lab results requests.
*
* @param {object} filters - the new set of filters
* @returns {function} dispatch function
*/
export function changeLabResultsFilters(filters) {
    return (dispatch, getState) => {
        dispatch({
            type: CHANGE_LAB_RESULTS_FILTERS,
            laboratory: filters.laboratory,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}