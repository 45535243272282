import React, { Component } from 'react'
import classnames from 'classnames'
import { formatUtils } from '../../utils/formatUtils'
import { $$ } from '../../helpers/localization'
import { BODIMED, ACIBADEM } from '../../constants/laboratories'

export class LabResultTable extends Component {
    constructor(props) {
        super(props);

        this.index = 0;
        this.tests = this.props.result.tests[0];
        this.maxNumberOfColumns = this.getMaxNumberOfColumns();
    }

    componentDidMount() {
        this.updateTestTableDimensions();
        window.addEventListener("resize", this.updateTestTableDimensions);
    }

    /**
     * Updates tests table dimensions when the window resize occurs and calls the resize prop to handle the following actions
     */
    updateTestTableDimensions = () => {
        this.props.resize(this.refs["table-ref"].clientWidth);
    }

    /**
     * Remove the event listener when the component unmounts
     */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTestTableDimensions);
    }

    /**
     * Returns the name of the first column based on the laboratory
     *
     * @param {string} lab - the laboratory for which we are displaying the table
     * @returns {string} the name of the first column
     */
    getFirstColumnNameByLaboratory = (lab) => {
        return lab === BODIMED ? [$$("lab_test_col_name_name")] : [""];
    }

    /**
     * Returns the bulgarian label based on the english content
     *
     * @param {string} label - the english content of the string whose bulgarian counterpart is needed
     * @returns {string} the bulgarian label
     */
    getBulgarianLabel = (label) => {
        return ["Result", "Units", "Flag", "Range"].indexOf(label) > -1 ? $$("lab_test_col_name_" + label.toLowerCase()) : label;
    }

    /**
     * Returns the formatted label for the table header based on some conditions
     *
     * @param {string} label - the header cell content of the table row
     * @param {string} lab - the actual laboratory whose data are being displayed
     * @returns {string} the label to display in the header cell
     */
    format = (label, lab) => {
        return lab === ACIBADEM && label === label.toUpperCase() ? label : formatUtils.format(label);
    }

    /**
     * Finds the maximum number of table columns
     *
     * @returns {number} the number of table columns
     */
    getMaxNumberOfColumns = () => {
        if(this.tests) {
            if(this.tests.groups[0].tests[0].subElements.length > 0) {
                return this.tests.groups[0].tests[0].subElements[0].cells.length + 1;
            } else {
                return this.tests.groups[0].tests[0].cells.length + 1;
            }
        }
    }

    /**
     * Prepares an array containing the table columns labels
     *
     * @returns {Array} the array of column names
     */
    getColumnNames = () => {
        let columns = this.tests.groups[0].tests[0].cells.map(c => {
            return this.getBulgarianLabel(c.label);
        });
        
        if(columns.length === 0) {
          columns = this.tests.groups[0].tests[0].subElements[0].cells.map(c => {
              return this.getBulgarianLabel(c.label); 
          });
        }
        
        return this.getFirstColumnNameByLaboratory(this.props.laboratory).concat(columns);
    }

    /**
     * Created the table header
     *
     * @returns {Array} the array of table headers
     */
    getHeader = () => {
        let columnNames = this.getColumnNames();
        let headerCells = [];

        headerCells = columnNames.map((col, index) => {
        return <th key={col} className="table-cells">
                  {this.format(col, this.props.laboratory)}
              </th>
        });

        for(let i = headerCells.length; i < this.maxNumberOfColumns; ++i) {
            headerCells.push(<th key={i} className="table-cells"></th>);
        }

        return <tr>{headerCells}</tr>;
    }

    /**
     * Creates the main table row
     *
     * @param {string} subCol - the value of the td in the main row
     * @returns {object} the row element created
     */
    getMainRow = (subCol) => {
        let cells = [];

        for(let i = 1; i < this.maxNumberOfColumns; ++i) {
            cells.push(<td key={i}></td>);
        }

        return <tr key={subCol} style={{ "backgroundColor": "#f2f2f2" }}>
                  <td className="table-cells">
                      <b>
                          {formatUtils.format(subCol)}
                      </b>
                  </td>
                  {cells}
            </tr>;
    }

    /**
     * Prepares the rows data of the table
     *
     * @returns {Array} the table rows data prepared
     */
    prepareRowsData = (data) => {
        let rowsData = [];
        let i = 0;
        rowsData[i] = [data.label].concat(data.cells.map(c => c.value));
        rowsData[i].isTest = true;

        for(i = 1; i <= data.subElements.length; ++i) {
          rowsData[i] = [data.subElements[i - 1].label].concat(data.subElements[i - 1].cells.map(c => c.value));
        }

        return rowsData;
    }
    
    /**
     * Creates the rows of the table
     *
     * @returns {Array} the table rows created
     */
    getRows = (items) => {
        let rows = [];

        for(let i = 0; i < items.length; ++i) {
            let cells = this.getCells(items[i]);
            rows.push(<tr key={++this.index}>{cells}</tr>);
        }

        return rows;
    }

    /**
     * Creates the cells of the table
     *
     * @returns {Array} the table cells created
     */
    getCells = (item) => {
        let cells = [];

        const nonTestCellsClass = classnames({
          "non-test-cells": !item.isTest
        });

        for(let i = 0; i < item.length; ++i) {
            i === 0 ? (item.isTest ? cells.push(<td key={i} className={"table-cells"}>{item[i]}</td>) : cells.push(<td key={i} className={nonTestCellsClass}>{item[i]}</td>)) 
                    : cells.push(<td key={i} className={"table-cells"}>{item[i]}</td>);
        }

        for(let j = cells.length; j < this.maxNumberOfColumns; ++j) {
          cells.push(<td key={j}></td>);
        }

        return cells;
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];
        let mainRows = this.tests.groups.map(g => g.label);

        for(let i = 0; i < mainRows.length; ++i) {
            let mainRow = this.getMainRow(mainRows[i]);
            let allPreparedRowsData = [];

            for(let j = 0; j < this.tests.groups[i].tests.length; ++j) {
              allPreparedRowsData.push(this.prepareRowsData(this.tests.groups[i].tests[j]));
            }

            allPreparedRowsData = [].concat.apply([], allPreparedRowsData);
            allRows.push(mainRow);
            allRows.push(this.getRows(allPreparedRowsData));
        }
        return allRows = [].concat.apply([], allRows);
    }

    render() {
        return (
            <div style={{ "overflowX": "auto" }}>
                <table ref="table-ref" className={"lab-results-table"} id="lab-results-table">
                    <thead>
                        {this.getHeader()}
                    </thead>
                    <tbody>
                        {this.getBody()}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default LabResultTable