import {
    FETCH_CHAT_MESSAGES_REQUEST_SENT,
    FETCH_CHAT_MESSAGES_REQUEST_SUCCESS,
    FETCH_CHAT_MESSAGES_REQUEST_ERROR,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR,
    CLEAR_CHAT,
    SET_MESSAGE_AS_SEEN,
    NEW_MESSAGE,
    NO_MORE_MESSAGES,
    DUMMY,
    CHANGE_CHAT_VIEW_POSITION,
    SET_ONGOING_CALL_FLAG
} from './actions';
import { chatService } from '../service/chat_service';
import moment from 'moment';
import _ from 'underscore'

/**
 * Fetch conversation between users, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchChatMessages(userId, params, reset) {
    return (dispatch, getState) => {
        if (!params) {
            params = {
                before_date_time: moment().valueOf(),
                size: 500
            };
        }
        return chatService.getChatWithUser(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({ type: DUMMY });
            }
            if (res && res.length > 0) {
                let messages = res;
                messages = _.chain(res).reverse().value();
                dispatch({ type: FETCH_CHAT_MESSAGES_REQUEST_SUCCESS, result: messages, reset: reset })
            } else {
                dispatch({ type: NO_MORE_MESSAGES })
            }
        }).catch((err) => {
            dispatch({ type: FETCH_CHAT_MESSAGES_REQUEST_ERROR, result: err });
        })
    }
}

/**
 * Send message.
 *
 * @param {object} message - the message to send to user
 * @returns {function} dispatch function
 */
export function sendMessage(message) {
    return (dispatch, getState) => {
        dispatch({ type: FETCH_CHAT_MESSAGES_REQUEST_SENT });
        return chatService.sendMessage(message).catch((err) => {
            dispatch({ type: FETCH_CHAT_MESSAGES_REQUEST_ERROR, result: err });
        });
    }
}

/**
 * Replace message with new updated version
 *
 * @param {object} message - the message to update
 */
export function updateMessage(message) {
    return (dispatch, getState) => {
        dispatch({ type: SET_MESSAGE_AS_SEEN, message: message });
    }
}

/**
 * Attach new message to the messages list
 *
 * @param {object} message - the message to attach
 * @returns {function} dispatch function
 */
export function attachNewMessage(message) {
    return (dispatch, getState) => {
        dispatch({ type: NEW_MESSAGE, message: message });
    }
}

/**
 * Mark message as read.
 *
 * @param {number} messageId - the message to mark as read
 * @returns {function} dispatch function
 */
export function markAsRead(messageId) {
    return (dispatch, getState) => {
        return chatService.markAsRead(messageId).then((res) => {
            if (res !== null) {
                console.log("Mesage market as read.");
            }
        }).catch((err) => {
            console.log("Error marking message as read.")
        })
    }
}

/**
 * Clear the stored chat messages
 *
 * @returns {function} dispatch function
 */
export function clearChat() {
    return (dispatch, getState) => {
        dispatch({ type: CLEAR_CHAT });
    }
}

/**
 * Get unread messages from server.
 *
 * @returns {function} dispatch function
 */
export function getUnreadMessages() {
    return (dispatch, getState) => {
        return chatService.getUnreadMessages().then((res) => {
            if (res !== null) {
                dispatch({ type: FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS, result: res });
            }
        }).catch((err) => {
            dispatch({ type: FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR, result: res });
        })
    }
}

/**
 * Changes chat view position
 *
 * @param {string} chatViewPosition - the chat view position
 * @returns {function} dispatch function
 */
export function changeChatViewPosition(chatViewPosition) {
    return (dispatch, getState) => {
        dispatch({ type: CHANGE_CHAT_VIEW_POSITION, position: chatViewPosition });
    }
}

/**
 * Changes the ongoing call flag
 *
 * @param {boolean} ongoingCall - the ongoing call flag
 * @returns {function} dispatch function
 */
export function setOngoingCallFlag(ongoingCallFlag) {
    return (dispatch, getState) => {
        dispatch({ type: SET_ONGOING_CALL_FLAG, flag: ongoingCallFlag });
    }
}