import {
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    FETCH_SELECTED_USER_MEDICATIONS_SUCCESS,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    LOGOUT,
    CLEAR_USERS,
    SELECT_USER
} from "../actions/actions";
import { requestStatus } from './requests_reducers';

/**
 * Medication reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const medicationInitialState = { medicationPlan: { entries: [], request: requestStatus(undefined, {}) } }
export function medication(state = medicationInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_MEDICATIONS_SUCCESS: {
            return { ...state, medicationPlan: { entries: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) } }
        }
        case FETCH_SELECTED_USER_MEDICATIONS_ERROR: {
            return { ...state, medicationPlan: { entries: medicationInitialState.medicationPlan.entries, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.response }) } }
        }
        case SELECT_USER:
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...medicationInitialState };
        }
        default: {
            return state;
        }
    }
}