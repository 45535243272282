
export const PLUGINS = {
    resizeChartFont,
    classificationLabelsPlugin,
    barChartBoundaryLabelsPlugin
};

/**
 * Clear the labels from the chart, resize font
 * 
 * @param {object} chartsDataClassificationChart the classification chart data
 * @returns {object} the chart plugin
 */
export function resizeChartFont() {
    return {
        id: "resizeChartFont",
        beforeDraw: function (chart, easing) {
            let ctx = chart.chart.ctx;
            let width = chart.chart.width;
            let height = chart.chart.height;
            let xScale1 = chart.scales['x-axis-1'];
            let yScale1 = chart.scales['y-axis-1'];
            let xScale0 = chart.scales['x-axis-0'];
            let yScale0 = chart.scales['y-axis-0'];
            ctx.restore();
            if (width < 300) {
                const fontSize = height * 3 / 100;
                if (yScale1) {
                    yScale1.options.ticks.fontSize = fontSize;
                    yScale1.options.scaleLabel.fontSize = fontSize;
                }
                if (xScale1) {
                    xScale1.options.ticks.fontSize = fontSize;
                    xScale1.options.scaleLabel.fontSize = fontSize;
                }
                if (yScale0) {
                    yScale0.options.ticks.fontSize = fontSize;
                    yScale0.options.scaleLabel.fontSize = fontSize;
                }
                if (xScale0) {
                    xScale0.options.ticks.fontSize = fontSize;
                    xScale0.options.scaleLabel.fontSize = fontSize;
                }
                ctx.font = `${fontSize}px sans-serif`;
            }
            ctx.save();
        }
    }
}

/**
 * Get plugin for adding labels for classification chart zones
 * 
 * @param {object} chartsDataClassificationChart the classification chart data
 * @returns {object} the chart plugin
 */
export function classificationLabelsPlugin(chartsDataClassificationChart) {
    return {
        id: "classificationLabelsPlugin",
        afterDraw: function (chart, easing) {
            if (chartsDataClassificationChart && chartsDataClassificationChart.ranges && chart.config.options.shouldDrawRectangleLabels) {
                let ctx = chart.chart.ctx;
                let width = chart.chart.width;
                let xScale = chart.scales['x-axis-1'];
                let yScale = chart.scales['y-axis-1'];
                const labelStartX = width > 1000 ? 5 : 5;
                ctx.restore();
                ctx.textAlign = "center";
                ctx.textBaseline = "middle";
                chartsDataClassificationChart.ranges.forEach(r => {
                    ctx.fillText(r.label, xScale.getPixelForValue(labelStartX), yScale.getPixelForValue(r.y2 + 5));
                });
                ctx.save();
            }
        }
    }
}

/**
 * Add labels for bar charts top value, resize fonts
 * 
 * @returns {object} the chart plugin
 */
export function barChartBoundaryLabelsPlugin() {
    return {
        id: "barChartBoundaryLabelsPlugin",
        afterDraw: function (chart, easing) {
            if (chart.config.options.showDatapoints) {
                var helpers = Chart.helpers;
                var width = chart.chart.width;
                var height = chart.chart.height;
                var ctx = chart.chart.ctx;
                var fontColor = helpers.getValueOrDefault(chart.config.options.showDatapoints.fontColor, chart.config.options.defaultFontColor);
                if (width < 300) {
                    const fontSize = height * 3 / 100;
                    ctx.font = `${fontSize}px sans-serif`;
                } else {
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
                }
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                ctx.fillStyle = fontColor;
                chart.data.datasets.forEach(function (dataset) {
                    for (var i = 0; i < dataset.data.length; i++) {
                        if (dataset.data[i]) {
                            var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                            var scaleMax = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                            var yPosTop = (scaleMax - model.y) / scaleMax >= 0.93 ? model.y + 20 : model.y - 5;
                            ctx.fillText(dataset.data[i][1], model.x, yPosTop);
                        }
                    }
                });
            }
        }
    }
}
