import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as MenstrautionIcon } from '../../../public/media/icons/menstruation.svg'
import { MENSTRUATION, getResolvedText, getColorForOption } from '../../constants/select_options'

export default function MenstruationEntity(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><span className="logbook-entry-custom-icon"><MenstrautionIcon fill="#646c9a"/></span> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div className="row">
                    <div className="col-xs-2 col-md-2">
                        <div className="mentruation-entity-label">{$$('Flow')}</div>
                        <div className="mentruation-entity-text"><b>{getResolvedText(MENSTRUATION.FLOW, props.entry.menstruationFlow)}</b></div>
                    </div>
                    <div className="col-xs-2 col-md-2">
                        <div className="mentruation-entity-label">{$$('Color')}</div>
                        <div className="mentruation-entity-text" style={{ "color": getColorForOption(MENSTRUATION.COLOR, props.entry.menstruationColor) }}><b>{getResolvedText(MENSTRUATION.COLOR, props.entry.menstruationColor)}</b></div>
                    </div>
                    <div className="col-xs-2 col-md-2">
                        <div className="mentruation-entity-label">{$$('Consistency')}</div>
                        <div className="mentruation-entity-text"><b>{getResolvedText(MENSTRUATION.CONSISTENCY, props.entry.menstruationConsistency)}</b></div>
                    </div>
                </div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
