import React, { Component } from 'react'
import classnames from 'classnames'
import { $$ } from '../../helpers/localization';
import ChatUserItem from './ChatUserItem';

export class ChatUsers extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        selectedId: null
    }

    /**
     * On item clicked handler. Handle the user with latest message click. Show the correct chat.
     *
     * @param {object} item - the item of the list, the user whith its last message
     */
    onItemClick = (message) => {
        this.setState({ selectedId: message.id });
        const userToChatId = message.from_user_id === this.props.loggedUser.id ? message.to_user_id : message.from_user_id;
        const userToChatFullName = message.from_user_id === this.props.loggedUser.id ? message.to : message.from;
        this.props.showChat(userToChatId, userToChatFullName);
    }

    render() {
        const users = this.props.lastMessages && this.props.lastMessages.length > 0 ? this.props.lastMessages.map((m, idx) => {
            const isSent = m.from_user_id === this.props.loggedUser.id;
            const userToChatId = isSent ? m.to_user_id : m.from_user_id;
            const unreadMessages = this.props.unreadMessages.find(m => m.sender === userToChatId);
            return <ChatUserItem userId={userToChatId} isSent={isSent} message={m} key={idx} onItemClick={this.onItemClick} unreadMessages={unreadMessages} />
        }) : '';
        return (
            <div className="card chat-users-card">
                <div className="card-header">
                    <div className="chat-card-title">
                        <div>{$$('recent_chats_label')}</div>
                        <div>
                            <button className="btn btn-success" onClick={this.props.showAvailableUsers}>
                                {$$("new_chat")}
                            </button>
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    {users}
                </div>
            </div>
        )
    }
}

export default ChatUsers
