import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore'
import Message from './Message';

export class ChatMessages extends Component {
    constructor(props) {
        super(props);
        this.messagesContainer = React.createRef();
        this.anchorRef = React.createRef();
    }

    state = {
        firstTimeScrollVisible: false,
        scrollIsAtBottom: null
    }

    componentDidMount() {
        this.messagesContainer.current.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        //Scroll to bottom if this is the initial load
        if (_.isEmpty(prevProps.messages) && this.props.messages && this.props.messages.length > 0) {
            this.messagesContainer.current.scrollTop = this.anchorRef.current.offsetTop;
            //this.anchorRef.current.scrollIntoView();
        }

        //If the current position of the scroll is at bottom and new messages come, scroll to new bottom
        //If the position of the scroll is not a bottom but the logged user sends a message, scroll to new bottom
        if (prevProps.messages && prevProps.messages.length > 0 && this.props.messages && this.props.messages.length) {
            const prevPropsSentMessages = prevProps.messages.filter(m => !m.delete && m.from_user_id === this.props.loggedUser.id);
            const currentSentMessages = this.props.messages.filter(m => !m.delete && m.from_user_id === this.props.loggedUser.id);
            if (prevState.scrollIsAtBottom && prevProps.messages.length < this.props.messages.length) {
                this.messagesContainer.current.scrollTop = this.anchorRef.current.offsetTop;
                //this.anchorRef.current.scrollIntoView();
            } else if (currentSentMessages.length > prevPropsSentMessages.length) {
                this.messagesContainer.current.scrollTop = this.anchorRef.current.offsetTop;
                //this.anchorRef.current.scrollIntoView();
            }
        }

        //If there was no scroll before, and there is no scroll currenly yet initiate the flag.
        if (prevState.firstTimeScrollVisible === null && this.props.messages && this.messagesContainer.current.scrollHeight <= this.messagesContainer.current.clientHeight) {
            this.setState({ firstTimeScrollVisible: false });
        }
        //If the scroll is shown for the first time set the flag. Else if the flag is already set scroll to bottom and destroy the flag
        if (prevState.firstTimeScrollVisible === false && this.messagesContainer.current.scrollHeight > this.messagesContainer.current.clientHeight) {
            this.setState({ firstTimeScrollVisible: true });
        } else if (prevState.firstTimeScrollVisible === true) {
            this.messagesContainer.current.scrollTop = this.anchorRef.current.offsetTop;
            //this.anchorRef.current.scrollIntoView();
            this.setState({ firstTimeScrollVisible: null });
        }
    }

    componentWillUnmount() {
        this.messagesContainer.current.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * On scroll handler. Handle scroll position changed, set flag is scroll is at bottom of container
     */
    handleScroll = () => {
        const scrollAtBottomValue = Math.ceil(this.messagesContainer.current.scrollTop) - (this.messagesContainer.current.scrollHeight - this.messagesContainer.current.offsetHeight);
        const currentScrollAtBottom = scrollAtBottomValue >= 0 && scrollAtBottomValue <= 1;
        if (!this.state.scrollIsAtBottom && currentScrollAtBottom) {
            this.setState({ scrollIsAtBottom: true });
        } else if (this.state.scrollIsAtBottom && !currentScrollAtBottom) {
            this.setState({ scrollIsAtBottom: false });
        }
    }

    getMessages = () => {
        return this.props.messages ? this.props.messages.map((m, idx) => {
            if (!m.deleted) {
                const isSent = m.from_user_id === this.props.loggedUser.id;
                return <Message key={idx} isSent={isSent} message={m} />
            }
            return '';
        }) : '';
    }

    render() {

        return (
            <div className="conversation-container" ref={this.messagesContainer}>
                {this.getMessages()}
                <div id="anchor" ref={this.anchorRef}>
                </div>
            </div>
        )
    }
}

export default ChatMessages

