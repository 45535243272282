import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-datepicker/dist/react-datepicker.css'
import './app.scss'
import './flags.css'
import { BrowserRouter } from 'react-router-dom'
import store from './store';
import { ThemeProvider } from "./_metronic";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


window.strings = {}
Object.entries(require('./i18n')).forEach(([langKey, language]) => window.strings[langKey] = language);
const Root = ({ store }) => (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

render(<Root store={store} />, document.getElementById('root')) 
