import React, { Component } from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import { CHART_FILTERS, getResolvedOptions } from '../../constants/select_options'
import classnames from 'classnames'
import { $$ } from '../../helpers/localization'
import moment from 'moment'


export class ChartFilters extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        period: this.props.filters.period,
        showCustomRange: this.props.filters.period === 'CUSTOM'
    }

    /**
	 * On custom period change. Set the new state and change the charts filters state.
	 *
	 * @param {object} - Name of the input and the new value
	 */
    onPeriodChanged = ({ name, value }) => {
        this.setState({
            period: value,
            showCustomRange: value === 'CUSTOM'
        }, function () {
            if (this.state.period !== 'CUSTOM') {
                const filters = {
                    period: this.state.period,
                    before_date_time: null,
                    after_date_time: null
                }
                this.props.changeChartsFilters(filters);
            }
        });

    };

    /**
	 * Change the charts filters state.
	 *
	 * @param {object} range - the custom range selected
	 */
    onRangeSelected = (range) => {
        if (range.endDate && range.startDate) {
            const filters = {
                period: this.state.period,
                beforeDateTime: moment(range.endDate).valueOf(),
                afterDateTime: moment(range.startDate).valueOf()
            }
            this.props.changeChartsFilters(filters);
        }
    }

    render() {
        const datePickerClasses = classnames({
            "hidden": !this.state.showCustomRange
        });
        return (
            <div className="form-inline">
                <div>
                    <Select
                        name="period"
                        options={getResolvedOptions(CHART_FILTERS.PERIOD_OPTIONS)}
                        value={this.state.period}
                        onChange={this.onPeriodChanged} />
                </div>
                <div className={datePickerClasses}>
                    <DateRangePicker className={datePickerClasses}
                        onRangeSelected={this.onRangeSelected}
                        startDate={this.props.filters.after_date_time}
                        endDate={this.props.filters.before_date_time} />
                </div>
            </div>
        )
    }
}

export default ChartFilters
