const SERVER_URL = process.env.REACT_APP_SERVER_API_URL;

export const LOGIN_URL = `${SERVER_URL}/auth/token`;
export const GOOGLE_LOGIN_URL = `${SERVER_URL}/auth/token/google`;
export const FACEBOOK_LOGIN_URL = `${SERVER_URL}/auth/token/facebook`;
export const REGISTER_URL = `${SERVER_URL}/public/user`;
export const REFRESH_TOKEN_URL = `${SERVER_URL}/auth/token/refresh`;
export const FORGOT_PASSWORD_URL = `${SERVER_URL}/public/password/forgotPassword?lang={lang}`;
export const PRIVACY_POLICY_URL = `https://medrec-m.com/privacy-policy/{lang}`;

export const ALL_USERS_URL = `${SERVER_URL}/user/list`;
export const ALL_LOGBOOK_ENTIRES_FOR_USER = `${SERVER_URL}/logbook-api/{userId}?app=Medrec-M`;
export const USER_CHART_DATA_URL = `${SERVER_URL}/charts/{userId}`;
export const LATEST_MEASUREMENtS_FOR_USER = `${SERVER_URL}/logbook-api/{userId}/latest?app=Medrec-M`;
export const USER_PICTURE_URL = `${SERVER_URL}/user/photo/downloadFile/{userId}`;


export const GET_USER_SETTINGS_URL = `${SERVER_URL}/cardiac/settings`;
export const UPDATE_USER_SETTINGS_URL = `${SERVER_URL}/cardiac/settings`;

export const GET_NOMENCLATURE_URL = `${SERVER_URL}/logbook-api/symptoms/nomenclature`;
export const GET_USER_SYMPTOMS_LOG_URL = `${SERVER_URL}/logbook-api/symptoms-log/{userId}?app=Medrec-M`;

export const GET_ALL_PERMISSIONS_URL = `${SERVER_URL}/permission/authorized?app=Medrec-M`;
export const GET_ALL_AUTHORIZING_PERMISSIONS_URL = `${SERVER_URL}/permission/authorizing?app=Medrec-M`;
export const APPROVE_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/approve`;
export const DENY_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/deny`;
export const REQUEST_PERMISSION_URL = `${SERVER_URL}/user/code/{email}/request?app=Medrec-M&types=READ`;

export const GET_USER_INFO_URL = `${SERVER_URL}/user`;
export const UPDATE_USER_INFO_URL = `${SERVER_URL}/user`;
export const UPDATE_USER_PASSWORD_URL = `${SERVER_URL}/user/password`;
export const UPLOAD_USER_IMAGE_URL = `${SERVER_URL}/user/photo/uploadFile`;
export const DELETE_USER_IMAGE_URL = `${SERVER_URL}/user/photo/delete`;

export const GET_USER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/documents/{userId}?app=Medrec-M`;
export const DOWNLOAD_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/{documentId}?app=Medrec-M`;

export const GET_CHILDREN_URL = `${SERVER_URL}/user/children`;

export const MEDICAL_PROFILE_FOR_USER = `${SERVER_URL}/user/medical_profile/{userId}?app=Medrec-M`;

export const GET_CHAT_WITH_USER = `${SERVER_URL}/user/chat/{userId}`;
export const GET_LATEST_MESSAGES_PER_CHAT = `${SERVER_URL}/user/chat/latest`;
export const SEND_MESSAGE_TO_USER = `${SERVER_URL}/user/chat/message`;
export const MARK_MESSAGE_AS_READ_URL = `${SERVER_URL}/user/chat/message/read/{messageId}`;
export const GET_UNREAD_MESSAGES = `${SERVER_URL}/user/chat/unread/count`;
export const START_VIDEO_CALL_SESSION_UTR = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const GET_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const STOP_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session`
export const UPDATE_VIDEO_DURATION_AND_STATUS_URL = `${SERVER_URL}/user/chat/message/video/{messageId}`;
export const CHAT_WEBSOCKET_URL = `${SERVER_URL}/public/user/ws`;

export const USER_MEDICATIONS_URL = `${SERVER_URL}/medication/plans/{userId}`;

export const USER_GENERAL_PRACTITIONERS_URL = `${SERVER_URL}/user/general-practitioner/{userId}?app=Medrec-M`;

export const GET_USER_LAB_RESULTS_URL = `${SERVER_URL}/logbook-api/laboratory-results/{userId}?app=Medrec-M`;
export const GET_LABORATORIES_URL = `${SERVER_URL}/public/logbook-api/laboratories?app=Medrec-M`;