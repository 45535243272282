import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserInfo from './UserInfo';
import LatestMeasurements from './LatestMeasurements';
import LatestDataCharts from './LatestDataCharts';
import LatestSymptoms from './LatestSymptoms';
import {MedicalProfile} from "./MedicalProfile";
import MedicationPlan from './MedicationPlan';
import GeneralPractitioner from "./GeneralPractitioner";

class Dashboard extends Component {
    MedicationPlan;
    GeneralPractitioner
    constructor(props) {
        super(props);
    }



    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-5 col-md-5 user-info-container">
                        <UserInfo selectedUser={this.props.selectedUser} isLoggedUserProfile={this.props.isLoggedUserProfile} userInfo={this.props.userInfo}/>
                        <MedicalProfile medicalProfile={this.props.medicalProfile} i18n={this.props.i18n} />
                        <MedicationPlan medicationPlan={this.props.medicationPlan} i18n={this.props.i18n}/>
                        <GeneralPractitioner generalPractitioner={this.props.generalPractitioner} i18n={this.props.i18n}/>

                    </div>
                    <div className="col-xs-7 col-md-7 latest-measurements-container">
                        <LatestMeasurements latestUserMeasurements={this.props.latestUserMeasurements} settings={this.props.settings} />
                        <LatestSymptoms symptoms={this.props.symptoms} settings={this.props.settings} nomenclature={this.props.nomenclature} i18n={this.props.i18n} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <LatestDataCharts dashboardCharts={this.props.dashboardCharts} settings={this.props.settings} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    latestUserMeasurements: state.latestUserMeasurements,
    dashboardCharts: state.dashboardCharts.chartsData,
    symptoms: state.symptoms,
    nomenclature: state.nomenclature,
    i18n: state.language,
    medicalProfile: state.medicalProfile,
    medicationPlan: state.medication.medicationPlan,
    generalPractitioner: state.practitioner.generalPractitioner.value,
    userInfo: state.userInfo.data
})
export default connect(mapStateToProps, null)(Dashboard)
