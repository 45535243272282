import React, { Component } from 'react'
import { connect } from 'react-redux'
import Video from './Video'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { chatService } from '../../service/chat_service'
import { INITIATED } from '../../constants/video_message_status'

class VideoCall extends Component {
    constructor(props) {
        super(props);
        this.appId = process.env.REACT_APP_QUICK_BLOX_APP_ID;
        this.authKey = process.env.REACT_APP_QUICK_BLOX_AUTH_KEY;
        this.authSecret = process.env.REACT_APP_QUICK_BLOX_AUTH_SECRET;
        this._isMounted = false;
        this.config = {
            debug: true,
            webrtc: {
                answerTimeInterval: 30,
                dialingTimeInterval: 5,
                disconnectTimeInterval: 35,
                statsReportTimeInterval: 5
            }
        };
    }

    state = {
        videoSession: {},
        callerQBId: null,
        recipientQBId: null,
        startVideoCall: this.props.startVideoCall,
        videoMessageId: null
    }

    /**
     * Log in to quickblox when component mounts. Select the user to login temporary via the role.
     */
    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.stopVideoSessions();
    }

    /**
     * Check if the start video call is set, and if so call the other user
     *
     * @param {object} prevProps - the previous properties object
     */
    componentDidUpdate(prevProps) {
        if (prevProps.videoMessages.length < this.props.videoMessages.length) {
            const idx = this.props.videoMessages.map((v) => !v.delete && v.to_user_id === this.props.loggedUser.id && v.video_status === INITIATED).lastIndexOf(true);
            if (idx !== null && idx !== undefined && this.props.videoMessages[idx]) {
                chatService.getVideoCallSession(this.props.videoMessages[idx].from_user_id).then((res) => {
                    !window.QB.webrtc && window.QB.init(Number(this.appId), this.authKey, this.authSecret, this.config);
                    window.QB.createSession(() => {
                        this.setState({ videoSession: res, callerQBId: res.callerQBId, videoMessageId: this.props.videoMessages[idx].id });
                    });
                });
            }
        }

        if (!prevProps.startVideoCall && this.props.startVideoCall && this.props.userToChatId) {
            chatService.startVideoCallSession(this.props.userToChatId).then((res) => {
                if (res) {
                    !window.QB.webrtc && window.QB.init(Number(this.appId), this.authKey, this.authSecret, this.config);
                    window.QB.createSession(() => {
                        this.setState({ videoSession: res, recipientQBId: res.destinationQBId, videoMessageId: res.message_id });
                    });
                }
            });
        }
    }

    /**
     * Call the stop video session resource
     */
    stopVideoSessions = () => {
        if (this.state.videoSession && this.state.videoSession.callerUserId) {
            const params = {
                callerId: this.state.videoSession.callerUserId,
                destinationId: this.state.videoSession.destinationUserId
            }
            chatService.stopVideoCallSession(params);
        }
    }

    /**
     * Call function on parent to set flag that call is stopped, stop the video session in the server,
     * dissconnect the chat sesion, destroy QB session and reset state
     */
    onHangupCall = () => {
        this.stopVideoSessions();
        this.props.onStopCall();
        if (window.QB.chat && !window.QB.chat._isLogout) {
            window.QB.chat.disconnect();
        }
        window.QB.destroySession(() => null);
        this.setState({ videoSession: {}, currentUserQBId: null, callerQBId: null, recipientQBId: null });
    }

    render() {
        const currentUserId = this.state.recipientQBId ? this.state.videoSession.callerQBId : this.state.videoSession.destinationQBId;
        const sessionToken = this.state.recipientQBId ? this.state.videoSession.callerQBSessionToken : this.state.videoSession.destinationQBSessionToken;
        return (
            <div className="videocall-contianer">
                {this._isMounted &&
                    <Video
                        videoMessageId={this.state.videoMessageId}
                        startVideoCall={this.props.startVideoCall}
                        callerQBId={this.state.callerQBId}
                        recipientQBId={this.state.recipientQBId}
                        currentUserQBId={currentUserId}
                        sessionToken={sessionToken}
                        setUsers={this.setUsers}
                        onHangupCall={this.onHangupCall}
                        incomingVideoCallStart={this.props.incomingVideoCallStart}
                        userToChatFullName={this.props.userToChatFullName}
                    />
                }

                {(!this.props.startVideoCall && !this.props.incomingVideoCall) &&
                    <div className="qb-video-logo">
                        <FontAwesomeIcon icon={faVideo} className="fas fa-camera fa-7x" />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedUser: state.selectedUser.data,
    i18n: state.language,
    auth: state.authentication,
    loggedUser: state.userInfo.data
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall)
