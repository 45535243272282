import React, { Component } from 'react'
import { CONVERTER } from '../../utils/converter';
import classnames from 'classnames'

export class Symptoms extends Component {

    constructor(props) {
        super(props);
    }

    /**
	 * Iterate the syptoms and return the formatted symptoms components
     * 
     * @returns the html to render
	 */
    getFormatedSymptoms = () => {
        return this.props.entry.symptoms.map((s, idx) => {
            let strength = s.strength;
            if (s.type === 'NO_SYMPTOMS') {
                strength = 3;
            }
            const progressBarClass = classnames({
                'progress-bar': true,
                'progress-1-green': s.type === 'NO_SYMPTOMS',
                'progress-1': strength === 1,
                'progress-2': strength === 2,
                'progress-3': strength === 3,
            });
            if (strength !== 0) {
                const width = 100 / (3 / strength);
                const symptomName = this.getSymptomName(s);
                return (
                    <li key={idx} className="list-group-item symptoms-list-item" style={{ "border": "none" }}>
                        <div className="row">
                            <div className="col-4 symptom-title">{symptomName}</div>
                            <div className="progress custom-progress col-8" style={{ "padding": "0" }}>
                                <div className={progressBarClass} role="progressbar" style={{ "width": `${width}%` }} aria-valuenow={`${width}%`} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </li>
                );
            } else {
                return '';
            }
        })
    }

    getSymptomName(s) {
        let lang;
        if (this.props.nomenclature.hasOwnProperty(this.props.i18n.lang)) {
            lang = this.props.i18n.lang;
        } else {
            lang = "en";
        }

        let symptom = this.props.nomenclature[lang].all.find(n => n.type === s.type);
        if (symptom) {
            return symptom.description;
        } else {
            if (s.description) {
                return s.description;
            }
            return "N/A";
        }
        /*return this.props.nomenclature.hasOwnProperty(this.props.i18n.lang) ?
            this.props.nomenclature[this.props.i18n.lang].all.find(n => n.type === s.type).description :
            this.props.nomenclature["en"].all.find(n => n.type === s.type).description;*/
    }

    render() {
        const cardClass = classnames('card', 'symptom-card', { 'dashboard-symptom-card': this.props.isDashboardComponent });
        const cardHeaderClass = classnames('card-header', { 'dashboard-symptom-card-header': this.props.isDashboardComponent });

        return (
            <div className={cardClass}>
                <div className={cardHeaderClass}>
                    {CONVERTER.getEntryDateTimeFormat(this.props.entry.date_time, this.props.isDashboardComponent)}, {CONVERTER.getDayPeriodFromTime(this.props.entry.date_time)}
                </div>
                <ul className="list-group list-group-flush symptoms-list">
                    {this.getFormatedSymptoms()}
                </ul>
            </div>
        )
    }
}

export default Symptoms
