/* eslint-disable react/jsx-no-duplicate-props */
import React, { createRef } from "react";
import clsx from "clsx";
import { filterUsers } from '../../actions/users_actions'
import { connect } from 'react-redux'
import { $$ } from "../../helpers/localization";

class SearchDefault extends React.Component {
    inputRef = createRef();
    state = { open: false, data: null, searchValue: "" };

    setFocusToInput = () => {
        this.inputRef.current.focus();
    };

    handleSearchChange = event => {
        this.setState({ data: null, searchValue: event.target.value }, function () {
            this.props.filterUsers(this.state.searchValue);
        });

        this.setState({ open: !this.state.open });
    };

    clear = () => {
        this.setState({ searchValue: "" }, function () {
            this.props.filterUsers(this.state.searchValue);
        });
        this.setState({ open: !this.state.open });
    };

    render() {
        const { open, data, searchValue } = this.state;

        return (
            <div className="kt-header-toolbar">
                <div
                    id="kt_quick_search_default"
                    className={clsx("kt-quick-search ", {
                        "kt-quick-search--has-result": data && data.length
                    })}
                >
                    <form className="kt-quick-search__form">
                        <div
                            className="input-group"
                        >
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="flaticon2-search-1" />
                                </span>
                            </div>

                            <input
                                type="text"
                                ref={this.inputRef}
                                placeholder={$$('search_placeholder_label')}
                                value={searchValue}
                                onChange={this.handleSearchChange}
                                className="form-control kt-quick-search__input"
                            />
                            {(searchValue) && (
                                <div className="input-group-append" onClick={this.clear}>
                                    <span className="input-group-text">
                                        <i className="la la-close kt-quick-search__close"
                                            style={{ display: "flex" }} />
                                    </span>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default connect(null, { filterUsers })(SearchDefault);
