import React from 'react'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as KetonesIcon } from '../../../public/media/icons/ketones.svg'

export default function KetonesEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon">
                    <KetonesIcon fill="#646c9a"/>
                </span>
                <span className="logbook-entry-dashboard-text">
                    {CONVERTER.ketonesPerUnit(props.entry.ketones)} {CONVERTER.getFormattedKetonesUnit()}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
