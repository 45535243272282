import React, { Component } from 'react'
import {deleteImage, getUserImage, uploadImage} from '../../service/users_service'
import '../../_metronic'
import noImage from '../../../public/media/profile/no_image.png'

export class UserImage extends Component {

    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.fileInputRef = React.createRef();

    }

    componentDidUpdate(prevProps) {
        if(prevProps != this.props) {
            this.fetchUserImage();
        }
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userID !== this.props.userID) {
            this.imgRef.current.src = "";
        }
        if (prevProps.userID !== this.props.userID) {
            this.fetchUserImage();
        }
    }

    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.isLoggedUserProfile) {
            if(this.props.userID === this.props.userInfo.id) {
                this.fetchUserImage();
            }
        } else if(this.props.userID) {
            this.fetchUserImage();
        }
    }

    componentWillUnmount() {
        URL.revokeObjectURL(this.imgRef.current.src);
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.userID).then((res) => {
                if(this.imgRef.current) {
                    if(this.props.setProfilePictureState) {
                        this.props.setProfilePictureState(true);
                    }
                    this.imgRef.current.type = "image/jpg"
                    this.imgRef.current.src = res;
                }
            }).catch(() => { 
                if(this.props.setProfilePictureState) {
                    this.props.setProfilePictureState(false);
                }
            });
        } finally {
            if(this.imgRef.current) {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = noImage;
            }
        }
    }

    /**
     * On select image handler. Upload new image. If successful show the image.
     * Check the image type for unsupported media, if so display error message.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectImage = (evt) => {
        const file = evt.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                uploadImage(file).then(() => {
                    this.imgRef.current.src = URL.createObjectURL(file);
                    this.setState({
                        errors: { ...this.state.errors, unsupprotedImage: false },
                        hasImage: true
                    });
                });
            } else {
                this.setState({
                    errors: { ...this.state.errors, unsupprotedImage: true }
                });
            }
        }
    };


    /**
     * Delet user image, if request is successful show default placeholder
     */
    onDeleteImage = () => {
        deleteImage().then(() => {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
            this.setState({ hasImage: false });
        });
    };


    render() {
        return (
            <img className="user-info-image" ref={this.imgRef} />
        )
    }
}

export default UserImage
