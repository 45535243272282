import React, { Component } from 'react'
import noImage from '../../../public/media/profile/no_image.png'
import { getUserImage } from '../../service/users_service';

export class AvailableUserItem extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }
    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userId !== this.props.userId) {
            this.imgRef.current.src = "";
        }
        if (prevProps.userId !== this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.userId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.userId).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => { });
        } finally {
            this.imgRef.current.type = "image/png"
            this.imgRef.current.src = noImage;
        }
    }

    render() {
        return (
            <a href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
                onClick={(e) => { e.preventDefault(); this.props.onItemClick(this.props.userId, this.props.name); }}>
                <div className="user-chat-info">
                    <img className="user-chat-image" ref={this.imgRef} />
                    <div>
                        <b>{this.props.name}</b>
                        <div>{this.props.email}</div>
                    </div>
                </div>
            </a>
        )
    }
}

export default AvailableUserItem
