import React, { Component } from 'react'
import { connect } from 'react-redux'
import Chat from './Chat'
import { sendMessage, markAsRead, fetchChatMessages, clearChat } from '../../actions/chat_actions'
import ChatUsers from './ChatUsers'
import AvailableUsers from './AvailableUsers'
import VideoCall from '../videocall/VideoCall'
import history from '../../helpers/history'
import { Routes } from '../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import _ from 'underscore'
import { CHAT_VIEWPOSITION } from '../../constants/enums'
import classnames from 'classnames'
import { CHAT_WEBSOCKET_URL } from '../../constants/api_paths'
import { fetchHelper } from '../../helpers/request_helper'
import moment from 'moment'
import { getUnreadMessages, updateMessage, attachNewMessage } from '../../actions/chat_actions'

export class VideoChat extends Component {
    constructor(props) {
        super(props);
        this.stopRetry = false;
        this.retryCount = 0;
    }

    state = {
        startVideoCall: false,
        incomingVideoCall: false,
        showChat: false,
        showLastMessages: true,
        showUsers: false,
        userToChatId: this.props.userToChatId,
        userToChatFullName: this.props.userToChatFullName,
        isCommunicationMenu: false,
        viewPosition: this.props.viewPosition
    }

    /**
     * Connect the stopm client via the websocket. Add handlers to get messages and fallback to polling when there is any error
     */
    connectSocket = () => {
        let protocol, webSocketProtocol;
        if (location.protocol.indexOf('https') !== -1) {
            protocol = 'https';
            webSocketProtocol = 'wss';
        } else if (location.protocol.indexOf('http') !== -1) {
            protocol = 'http';
            webSocketProtocol = 'ws';
        }
        var socket = new WebSocket(CHAT_WEBSOCKET_URL.replace(protocol, webSocketProtocol));
        this.stompClient = Stomp.over(socket);
        this.stompClient.heartbeat.outgoing = 25000;
        this.stompClient.heartbeat.incoming = 25000;
        this.stompClient.reconnect_delay = 5000;
        this.stompClient.connect(fetchHelper.getTokenHeader(), function (frame) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.stompClient.subscribe('/user/queue/messages', function (messageOutput) {
                const message = messageOutput && messageOutput.body ? JSON.parse(messageOutput.body) : null;
                const messages = this.props.chat.messages.entries;
                if (this.state.showLastMessages) {
                    this.props.refreshLastMessages();
                } else if (this.showChat && this.state.userToChatId && message && (message.to_user_id === this.state.userToChatId || message.from_user_id === this.state.userToChatId)) {
                    if (messages && messages.length > 0 && messages.some(m => m.id === message.id)) {
                        this.props.updateMessage(message);
                    } else {
                        this.props.attachNewMessage(message);
                    }
                }
                this.props.getUnreadMessages();
            }.bind(this));
        }.bind(this), function () {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            if (this.state.userToChatId) {
                this.intervalId = setInterval(() => {
                    this.props.getUnreadMessages();
                    this.props.refreshLastMessages();
                    this.props.fetchChatMessages(this.state.userToChatId, null, true)
                }, 5000);
            }
            this.reConnectSocket();
        }.bind(this));
    }

    /**
     * Re-conect socket function, try to reconect for several times before stoping
     */
    reConnectSocket = () => {
        if (!this.stopRetry && this.retryCount < 10) {
            const factor = this.retryCount ? this.retryCount : 0;
            setTimeout(() => this.connectSocket(), 3000 * factor);
            this.retryCount++;
        } else if (this.retryCount >= 10) {
            this.stopRetry = true;
        }
    }

    /**
     * Disconect the stopm client over the socket
     */
    disconnectSocket = () => {
        if (this.stompClient != null) {
            this.stompClient.disconnect();
        }
    }

    /**
     * Check the current path, if the component is in communication menu or tab to set a flag. Clear chat messages,
     * fetch new ones and set interval
     */
    componentDidMount() {
        this.connectSocket();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.setState({ isCommunicationMenu: history.location.pathname === Routes.COMMUNICATION });
        if (this.state.userToChatId) {
            this.refreshMessagesAndMarkAsRead();
        }
    }

    /**
     * Clear chat messages, fetch new ones and set interval
     *
     * @param {object} prevProps - the previous properties object
     * @param {object} prevState - the previous state object
     */
    componentDidUpdate(prevProps, prevState) {
        if ((!prevState.userToChatId && this.state.userToChatId) || (prevState.userToChatId && this.state.userToChatId && prevState.userToChatId !== this.state.userToChatId)) {
            this.refreshMessagesAndMarkAsRead();
        }

        if (prevProps.chat &&
            this.props.chat &&
            prevProps.chat.messages &&
            prevProps.chat.messages.entries &&
            this.props.chat.messages &&
            this.props.chat.messages.entries &&
            prevProps.chat.messages.entries.length !== this.props.chat.messages.entries.length) {
            this.markMessagesAsRead();
        }

        if (!prevState.showLastMessages && this.state.showLastMessages) {
            this.props.refreshLastMessages();
        }

        if (prevState.showChat && !this.state.showChat && this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /**
     * Clear chat messages, fetch new ones and set interval. Mark unread messages as seen.
     */
    refreshMessagesAndMarkAsRead = () => {
        this.props.clearChat();
        this.props.fetchChatMessages(this.state.userToChatId, null, true)
        this.markMessagesAsRead();
    }

    /**
     * Get the user to chat from the properties
     *
     * @param {object} props - the properties object
     * @param {object} state - the state object
     */
    static getDerivedStateFromProps(props, state) {
        if (props.userToChatId && props.userToChatFullName) {
            return {
                userToChatId: props.userToChatId,
                userToChatFullName: props.userToChatFullName
            }
        }
        return state;
    }

    /**
     * Mark unread messages as read
     */
    markMessagesAsRead = () => {
        if (this.props.chat && this.props.chat.messages && this.props.chat.messages.entries && this.props.chat.messages.entries.length > 0) {
            for (let message of this.props.chat.messages.entries) {
                if (message.to_user_id === this.props.loggedUser.id && !message.seen) {
                    this.props.markAsRead(message.id);
                }
            }
        }
    }

    /**
     * Clear the interval when the component unmounts
     */
    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        this.stopRetry = true;
        this.disconnectSocket();
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    /**
     * Set flag to indicate that there is an incoming videocall
     */
    incomingVideoCallStart = () => {
        this.setState({ incomingVideoCall: true });
        this.props.setOngoingCallFlag(true);
    }

    /**
     * Set flag to indicate that the call has started
     */
    startVideoCall = () => {
        this.setState({ startVideoCall: true });
        this.props.setOngoingCallFlag(true);
    }

    /**
     * Change flags when the video call is stopped
     */
    onStopCall = () => {
        this.setState({ startVideoCall: false, incomingVideoCall: false });
        this.props.setOngoingCallFlag(false);
    }

    /**
     * Find the uset to chat and open chat.
     *
     * @param {number} userToChatId - the id of the user to chat
     */
    showChat = (userToChatId, userToChatFullName) => {
        this.setState({ showChat: true, showLastMessages: false, showUsers: false, userToChatId: userToChatId, userToChatFullName: userToChatFullName });
    }

    /**
     * Set flags to show the available users component
     */
    showAvailableUsers = () => {
        this.setState({ showChat: false, showLastMessages: false, showUsers: true, userToChatId: null, userToChatFullName: null });
    }

    /**
     * Set flags to show the latest messages component
     */
    showLastMessages = () => {
        this.setState({ showChat: false, showLastMessages: true, showUsers: false, userToChatId: null, userToChatFullName: null });
    }

    render() {
        if (!this.props.chat) {
            return '';
        }
        let textMessages = [], videoMessages = [];
        if (this.props.chat.messages && this.props.chat.messages.entries) {
            const messages = _.partition(this.props.chat.messages.entries, (m) => m.type === 'TEXT');
            textMessages = messages[0];
            videoMessages = messages[1];
        }

        
        const shouldHideVideo = process.env.REACT_APP_HIDE_VIDEO === "true";
        //If window's width is smaller than 768, chat should be shown inside SubHeader and user data to be hidden
        const shouldExpand = this.state.width <= 768;

        //Assign classes to the chat, video, and children components to manage visibility based on the chat overlay position
        const videoChatClass = classnames("card", {
            // "hidden": this.props.viewPosition === CHAT_VIEWPOSITION.INITIAL && shouldExpand,
            "chat-hidden-visibility": (this.props.viewPosition === CHAT_VIEWPOSITION.INITIAL && shouldExpand) || (this.props.viewPosition === CHAT_VIEWPOSITION.INITIAL && !shouldExpand),
            "col-xs-4 col-md-4": this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && !shouldExpand,
            "col-xs-12 col-md-12 expanded": this.props.viewPosition === CHAT_VIEWPOSITION.FULL && !shouldExpand || (this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && shouldExpand),
        });

        const chatClasses = classnames({
            "expanded": this.props.viewPosition === CHAT_VIEWPOSITION.FULL && !shouldExpand,
            "col-xs-12 col-md-12": this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT,
            // "col-xs-12 col-md-12": this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && !shouldExpand,
            // "col-xs-4 col-md-4": this.props.viewPosition === CHAT_VIEWPOSITION.FULL || (this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && shouldExpand)
        });

        const videoClasses = classnames({
            "hidden": (this.state.startVideoCall === false && this.state.incomingVideoCall === false && this.props.viewPosition !== CHAT_VIEWPOSITION.FULL && !shouldExpand) || shouldHideVideo,
            "col-xs-8 col-md-8": this.props.viewPosition === CHAT_VIEWPOSITION.FULL || (this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && shouldExpand),
            "col-xs-12 col-md-12": this.props.viewPosition === CHAT_VIEWPOSITION.ONLY_CHAT && (this.state.startVideoCall || this.state.incomingVideoCall) && !shouldExpand,
        });

        const childrenClass = classnames({
            "hidden": (this.props.viewPosition !== CHAT_VIEWPOSITION.FULL && !shouldExpand) || shouldExpand,
        });

        const expandBtnClass = classnames("chat-expand", {
            "hidden": shouldExpand || shouldHideVideo,
            "expanded": (this.props.viewPosition === CHAT_VIEWPOSITION.FULL && !shouldExpand) || shouldExpand,
        });

        return (
            <div className="video-chat-wrapper">
                <div id="chatPanel" className={videoChatClass}>
                    <div className="card-body">
                        <div className="row">
                            <div className={videoClasses}>
                                <VideoCall
                                    startVideoCall={this.state.startVideoCall}
                                    incomingVideoCall={this.state.incomingVideoCall}
                                    onStopCall={this.onStopCall}
                                    incomingVideoCallStart={this.incomingVideoCallStart}
                                    userToChatFullName={this.state.userToChatFullName}
                                    userToChatId={this.state.userToChatId}
                                    videoMessages={videoMessages}
                                />
                            </div>
                            {this.state.showLastMessages &&
                                <div className={chatClasses}>
                                    <ChatUsers
                                        lastMessages={this.props.lastMessages}
                                        loggedUser={this.props.loggedUser}
                                        showChat={this.showChat}
                                        showAvailableUsers={this.showAvailableUsers}
                                        unreadMessages={this.props.chat.unreadMessages.list}
                                    />
                                </div>
                            }
                            {this.state.showUsers &&
                                <div className={chatClasses}>
                                    <AvailableUsers
                                        availableUsers={this.props.availableUsers}
                                        loggedUser={this.props.loggedUser}
                                        showChat={this.showChat}
                                        showLastMessages={this.showLastMessages}
                                    />
                                </div>
                            }
                            {this.state.showChat &&
                                <div className={chatClasses}>
                                    <Chat
                                        messages={this.props.chat.messages.entries}
                                        loggedUser={this.props.loggedUser}
                                        selectedUserId={this.state.userToChatId}
                                        selectedUserFullName={this.state.userToChatFullName}
                                        sendMessage={this.props.sendMessage}
                                        unreadMessages={this.props.chat.unreadMessages.list}
                                        auth={this.props.auth}
                                        showLastMessages={this.showLastMessages}
                                    />
                                </div>
                            }
                        </div>
                        <div className={childrenClass}>
                            {this.props.children}
                        </div>
                    </div>
                    <div className={expandBtnClass}>
                        {(this.props.viewPosition !== CHAT_VIEWPOSITION.FULL) && <button className="btn btn-default" onClick={this.props.expandView}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        }
                        {(this.props.viewPosition === CHAT_VIEWPOSITION.FULL) && <button className="btn btn-default" onClick={this.props.shrinkView}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    users: state.users,
    selectedUser: state.selectedUser.data,
    loggedUser: state.userInfo.data,
    chat: state.chat,
    auth: state.authentication,
})

const mapDispatchToProps = {
    getUnreadMessages,
    updateMessage,
    attachNewMessage,
    fetchChatMessages,
    sendMessage,
    markAsRead,
    clearChat
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoChat)
