import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getPermissions, approvePermission, denyPermission, requestPermission } from '../../actions/permissions_actions'
import PermissionList from './PermissionList'
import _ from 'underscore'
import { $$ } from '../../helpers/localization'
import RequestPermission from './RequestPermission'
import { Alert } from 'react-bootstrap'

class SharingPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getPermissions();
    }

    /**
	 * Get error message based on error code
	 *
	 * @returns {string} error message to display
	 */
    getErrorMessage = () => {
        const error = this.props.permissions.permissionOperationError;
        if (error) {
            return error.message;
        }
    }

    render() {
        const permissions = _.partition(this.props.permissions.entries, (p) => !!p.authApproved);
        const pendingPermissions = permissions[1];
        const approved = _.partition(permissions[0], (p) => !!p.userApproved);
        const approvedPermissions = approved[0];
        const pendingForUserApproval = approved[1];

        const permissionOperationError = this.props.permissions.permissionOperationError ?
            (<div className="row justify-content-md-center" style={{ "marginTop": "20px" }} >
                <div className="col-xs-8 col-md-8">
                    <Alert variant="danger">
                        {this.getErrorMessage()}
                    </Alert>
                </div>
            </div>
            )
            : '';

        return (
            <div>
                <div className="row justify-content-md-center">
                    <RequestPermission
                        error={this.props.permissions.requestPermissionError}
                        requestPermission={this.props.requestPermission}
                        success={this.props.permissions.requestPermissionSuccess}
                        pending={pendingForUserApproval}
                        onDeny={this.props.denyPermission} />
                </div>
                {permissionOperationError}
                <div className="row justify-content-md-center" style={{ "marginTop": "20px" }}>
                    <div id="accordion" className="col-xs-8 col-md-8">
                        <div className="card">
                            <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <h5 className="mb-0">
                                    <button className="btn">
                                        {$$('pending_permission_list_label')}
                                    </button>
                                    <span className="float-right">
                                        <i className="fas fa-angle-down" style={{ "marginTop": "10px" }}></i>
                                    </span>
                                </h5>
                            </div>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    <PermissionList permissions={pendingPermissions} onDeny={this.props.denyPermission} onApprove={this.props.approvePermission} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center" style={{ "marginTop": "20px" }}>
                    <div id="accordion2" className="col-xs-8 col-md-8">
                        <div className="card">
                            <div className="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                <h5 className="mb-0">
                                    <button className="btn">
                                        {$$('approved_permission_list_label')}
                                    </button>
                                    <span className="float-right">
                                        <i className="fas fa-angle-down" style={{ "marginTop": "10px" }}></i>
                                    </span>
                                </h5>
                            </div>

                            <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion2">
                                <div className="card-body">
                                    <PermissionList permissions={approvedPermissions} onDeny={this.props.denyPermission} onApprove={this.props.approvePermission} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        permissions: state.permissions
    }
}

export default connect(mapStateToProps, { getPermissions, approvePermission, denyPermission, requestPermission })(SharingPage)
