import { GET_CHILDREN, GET_CHILDREN_ERROR } from "./actions";
import { childrenService } from "../service/children_service";

/**
 * Get children of the logged user
 *
 * @returns {function} dispatch function
 */
export function getChildren() {
    return (dispatch, getState) => {
        childrenService.getChildren()
            .then((res) => {
                if (res) {
                    dispatch({ type: GET_CHILDREN, payload: res });
                }
            })
            .catch((err) => {
                dispatch({ type: GET_CHILDREN_ERROR, result: err });
            })
    }
}