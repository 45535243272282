import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'

export default function SaturationEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon" style={{ "left": "10px" }}>
                    <b>O<sub>2</sub></b>
                </span>
                <span className="logbook-entry-dashboard-text">
                    {props.entry.oxygen_saturation.toFixed(2)} %
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
