import React, { Component } from 'react'
import { $$ } from '../../helpers/localization';

export class ChatInput extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        message: ''
    }

    /**
	 * Set the state to the latest change in the input value.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Send message to other user in chat
     *
     * @param {object} e - the event object
     */
    onSendMessage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.message.trim()) {
            this.props.sendMessage(this.state.message.trim());
            this.setState({ message: '' });
        }
    }

    render() {
        return (
            <form className="conversation-input" onSubmit={this.onSendMessage}>
                <input type="text" className="form-control input" value={this.state.message} placeholder={$$('type_message')} name="message" onChange={this.onInputChange} />
                <button className="send">
                    <div className="circle">
                        <i className="flaticon2-telegram-logo"></i>
                    </div>
                </button>
            </form>
        )
    }
}

export default ChatInput
