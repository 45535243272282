import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectUser } from '../../actions/users_actions'
import TabsContainer from './TabsContainer';

class MainPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const selectedUser =  this.props.users_data && this.props.users_data.list ? this.props.users_data.list.find(u => (
            u.id === this.props.userInfo.id
        )) : null;
        if(selectedUser !== null && selectedUser !== undefined) {
            this.props.selectUser(selectedUser, true);
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="card">
                            <TabsContainer selectedUser={this.props.selectedUser} isLoggedUserProfile={true} i18n={this.props.i18n} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo.data,
        auth_data: state.authentication.data,
        users_data: state.users,
        selectedUser: state.selectedUser.data,
        i18n: state.language
    }
}

export default connect(mapStateToProps, { selectUser })(MainPage);
