import { fetchHelper } from '../helpers/request_helper';
import { LOGIN_URL, GOOGLE_LOGIN_URL, FACEBOOK_LOGIN_URL, REGISTER_URL, REFRESH_TOKEN_URL } from '../constants/api_paths';

export const authService = {
    login,
    loginWithGoogle,
    loginWithFacebook,
    register,
    refreshToken,
    logout
};
const app = "hlth_mon";
/**
 * Perform the login request
 *
 * @param {string} email the user email
 * @param {string} password the user password
 * @returns {object} promise object
 */
export function login(email, password) {
    const body = {
        username: email,
        password,
        app: app,
        device_hash: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    };
    return fetchHelper.callPost(LOGIN_URL, body);
}

/**
 * Perform the Google login request
 *
 * @param {string} id_token
 * @returns {object} promise object
 */
export function loginWithGoogle( id_token) {
    const body = {
        id_token: id_token,
        app: app,
        device_hash: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    };
    return fetchHelper.callPost(GOOGLE_LOGIN_URL, body);
}

/**
 * Perform the Facebook login request
 *
 * @param {string} accessToken access token from facebook
 * @returns {object} promise object
 */
export function loginWithFacebook(accessToken) {
    const body = {
        id_token: accessToken,
        app: app,
        device_hash: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    };
    return fetchHelper.callPost(FACEBOOK_LOGIN_URL, body);
}

/**
 * Perform the login request
 *
 * @param {string} email the user email
 * @param {string} password the user password
 * @returns {object} promise object
 */
export function refreshToken(refreshToken) {
    const body = {
        refreshToken: refreshToken,
    };
    return fetchHelper.callPost(REFRESH_TOKEN_URL, body);
}

/**
 * Perform the register request
 *
 * @param {object} user the user to create
 * @returns {object} promise object
 */
export function register(user) {
    return fetchHelper.callPost(REGISTER_URL, user);
}

export function logout() {
    //log out
}
