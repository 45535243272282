export const bg = {
    text:
    {
        title: "Medrec:M Monitor",
        main_page_label: "Моят Медицински картон",
        select_language_label: "Избери език",
        login_label: "Влизане",
        social_media_login_separator:"Или",
        logout_label: "Изход",
        save_btn_label: "Запазване",
        username_label: "Електронна поща",
        email_label: "Електронна поща",
        password_label: "Парола",
        new_password_label: "Нова парола",
        repeat_password_label: "Повтори паролата",
        email_required_message: "Моля, въведете електронната си поща!",
        password_required_message: "Моля, въведете парола!",
        login_form_invalid_credentials_message: "Невалидни поща/парола!",
        register_label: "Регистриране",
        fullname_label: "Име",
        register_form_password_confirm_label: "Потвърди парола",
        register_form_email_exists_message: "Електронната поща вече е регистрирана!",
        register_form_email_not_correct_message: "Електронната поща е грешна!",
        register_form_passwords_not_match_message: "Паролите не съвпадат!",
        register_form_password_length_message: "Паролата трябва да е поне 8 символа!",
        register_form_password_whitespace_message: "Паролата не може да започне или да завърши с интервали!",
        register_form_fullname_required: "Името е задължително поле!",
        register_form_password_confirm_required_message: "Потвърдете паролата!",
        register_form_successful_message: "Потребителят е създаден!",
        confirm_btn_label: "Потвърди",
        close_btn_label: "Затвори",
        Charts: "Графики",
        Logbook: "Дневник",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Систолично",
        Diastolic: "Диастолично",
        Pulse: "Пулс",
        notes: "Бележки",
        total_cholesterol: "Общ холестерол",
        triglycerides: "Триглицериди",
        Cholesterol: "Холестерол",
        Weight: "Тегло",
        Height: "Височина",
        weight_and_height: "Тегло и височина",
        chronic_conditions: "Хронични заболявания",
        allergies: "Алергии",
        blood_group: "Кръвна група",
        details: "Детайли",
        blood_pressure: "Кръвно налягане",
        settings_label: "Настройки",
        unit_of_measurement_label: "Мерни единици",
        cholesterol_units_label: "Мерна единица на холестерола",
        classification_method_label: "Класификация",
        date_format_label: "Формат на датата",
        time_24hour_label: "Използвай 24 часов формат",
        settings_updated_message: "Настройките са запазени успешно",
        US: "САЩ (lbs, oz, fl oz, inch)",
        metric: "Метрична (kg, gram, ml, cm)",
        kg: "кг",
        pound: "фунт(а)",
        inches: "инча",
        cm: "см",
        None: "Няма",
        diagnosed_in: "Диагностициран на",
        mild: "Лека",
        mild_to_moderate: "Лека до умерена",
        moderate: "Умерена",
        moderate_to_severe: "Умерена до тежка",
        severe: "Тежка",
        life_threatening: "Животозастрашаваща",
        drug_allergy: "Медикаментозна алергия",
        dust_allergy: "Алергия към домашен прах",
        food_allergy: "Хранителна алергия",
        insect_allergy: "Алергия към насекоми",
        latex_allergy: "Алергия към латекс",
        mold_allergy: "Алергия към плесени",
        pet_allergy: "Алергия към животни",
        pollen_allergy: "Алергия към полени",
        other_allergy: "Друга алергия",
        Severity: "Степен",
        Medications: "Лекарства",
        millimol_per_litre: "ммол/л",
        milligram_per_decilitre: "mg/dL",
        millimetre_of_mercury: "mmHg",
        week_label: "7 дни",
        fortnight_label: "14 дни",
        month_label: "30 дни",
        quarter_label: "90 дни",
        custom_label: "Персонален",
        Symptoms: "Симптоми",
        Temperature: "Температура",
        Hydration: "Хидратация",
        Respiratory_rate: "Дихателна честота",
        Saturation: "Насищане с кислород",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Изтекла сесия',
        sharing_label: "Споделяне",
        pending_permission_list_label: "Очакващи потвърждение заявки за достъп",
        approved_permission_list_label: "Потвърдени заявки за достъп",
        deny_permission_label: "Откажи",
        approve_permission_label: "Разреши",
        request_label: "Заявка",
        request_permission_label: "Поискай достъп",
        requested_user_does_not_exist_message: "Не може да бъде намерен потребител с този имейл.",
        premission_request_success_message: "Заявката е успешно изпратена.",
        forgot_password: "Забравена парола",
        privacy_policy: "Политика за сигурност на личните данни",
        change_password_label: "Промяна на паролата",
        gender_label: "Пол",
        country_label: "Страна",
        male: "Мъж",
        female: "Жена",
        city_label: "Град",
        birthday_label: "Рождена дата",
        Dashboard: "Основен панел",
        user_info_label: "Персонална информация",
        latest_measurements_label: "Последни записи в дневника",
        last_week_data: "Седмични графики",
        no_recent_measurements: "Няма намерени записи.",
        loading_data_label: "Зареждане...",
        logbook_no_data_label: "Потребителят няма записани измервания.",
        symptoms_no_data_label: "Потребителят няма записани симптоми.",
        search_placeholder_label: "Търсене...",
        latest_symptoms_label: "Симптоми от последните 24 часа",
        no_latest_symptoms_label: "Няма намерени симптоми от последните 24 часа.",
        profile_label: 'Профил',
        edit_profile_label: 'Редактиране на профила',
        update_btn: 'Промени',
        upload_image_label: 'Качване на профилна снимка',
        user_info_updated_message: 'Профилът е променен',
        user_password_updated_message: 'Паролата е актуализирана успешно',
        user_password_update_error_message: 'Сървърна грешка!',
        unsupported_image_format: 'Този файлов формат не се поддържа',
        choose_file_label: 'Избери снимка',
        Documents: "Документи",
        category_lobal: "Категория",
        document_type_label: "Тип документ",
        type_description_label: "Описание на типа",
        document_download_dialog_title: "Свали документ",
        confirmation_dialog_message: "Документът ({fileName}), който искате да свалите принадлежи на {owner} и може да съдържа лична информация. Съхранявайте документа на сигурно място или го изтрийте след прочитането му.",
        documents_no_data_label: "Този потребител няма качени документи.",
        download_btn: "Свали",
        cancel_btn: "Откажи",
        document_type_none: "Без",
        document_type_epicrisis: "Епикриза",
        document_type_prescription: "Рецепта",
        document_type_lab_results: "Лабораторни резултати",
        document_type_ambulance_sheet: "Амбулаторен лист",
        document_type_referral: "Направление",
        document_type_other: "Друго",
        all_label: "Всички",
        select_category: "Избери категория",
        documents_no_data_for_filters_label: "Няма намерени документи отговарящи на избраните критерии.",
        pending_for_user_approval_label: "Потребители, които трябва да разрешат достъп",
        start_date: "От дата",
        end_date: "До дата",
        load_more: "Покажи следващите",
        afternoon: "Следобед",
        morning: "Сутрин",
        evening: "Вечер",
        select_gender: "Избери пол",
        my_documents_label: "Моите документи",
        my_children_label: "Моите деца",
        Blood_sugar: "Кръвна захар",
        HBA1C: "HbA1c (Гликиран хемоглобин)",
        Ketones: "Кетони",
        Flow: "Течение",
        Color: "Цвят",
        Consistency: "Консистенция",
        Urine_pH: "pH на урина",
        no_flow: "Няма",
        light: "Слабо",
        medium: "Средно",
        heavy: "Силно",
        unexpected: "Неочаквано",
        none: "Няма",
        black: "Черен",
        brown: "Кафяв",
        dark_red: "Тъмно червен",
        bright_red: "Ярко червен",
        pink: "Розов",
        orange: "Оранжев",
        gray: "Сив",
        waterly: "Водниста",
        clots: "Съсиреци",
        mmol_mol: "ммол/мол",
        no_chronic_conditions: "Няма хронични заболявания",
        no_allergies: "Няма алергии",
        Communication: "Съобщения",
        my_communication_label: "Моите съобщения",
        new_chat: "Нов разговор",
        recent_chats_label: "Последни разговори",
        available_users_label: "Достъпни потребители",
        back: "Назад",
        type_message: "Напиши съобщение...",
        call: "Входящо повикване",
        incoming_call_message: "Входящо повикване от %1",
        incoming_call_another_user_message: 'Входящо повикване от друг потребител',
        accept: "Приемам",
        no_user_selected_message: 'Няма избран потребител',
        ok: 'Потвърждавам',
        no_answer: "Няма отговор",
        no_answer_message: "Няма отговор от %1",
        you_wrote: "Вие написахте",
        missed_call_label: "Пропуснато обаждане",
        rejected_call_label: "Отказано обаждане",
        in_progress_call_label: "Текущ разговор",
        completed_call_label: "Приключен разговор",
        ongoing_call: "Call ongoing!",
        medication_plan: "График на лекарствата",
        current_medications: "Лекарства",
        general_practitioner: "Общопрактикуващ лекар",
        practice_name:"Име на клиника",
        practice_address:"Адрес на клиника",
        practice_contact:"Телефон за контакти",
        practice_centre_code:"Номер на клиника",
        practice_practitioner_code:"Номер на доктор",
        na:"Не е приложимо",
        times_per_day: "%1 %2 x %3 път(и) дневно",
        every_x_hours: "%1 %2 x на всеки %3 час(а)",
        daily: 'Ежедневно',
        every_x_days: "Всеки %1 ден(а)",
        take_x_rest_x: "Взимане %1 ден(а) и почивка %2 ден(а)",
        when_needed: "При нужда",
        ongoing: "Безсрочно",
        until_date: 'До %1',
        for_x_days_until: "За %1 ден(а), до %2",
        effective_from: "В действие от %1",
        medical_record_label: "Медицински картон",
        no_available_data: "Няма данни",
        Lab_Results: "Лабораторни резултати",
        select_laboratory_label: "Избери лаборатория",
        lab_results_open_results_label: "Отвори резултатите",
        lab_results_close_results_label: "Затвори резултатите",
        lab_results_no_data_for_filters_label: "Не са намерени резултати отговарящи на избраните критерии.",
        lab_results_no_data_label: "Този потребител няма качени лабораторни резултати.",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Избери',
        preview_image: 'Преглед',
        OK: 'OK',
        delete_image_modal_title: 'Премахни снимка',
        delete_image_modal_message: 'Сигурни ли сте, че искате да премахнете вашата снимка?',
        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ампула(и)',
                        display_short: ''
                    },
                    application: {
                        display_long: 'прилагане(ия)',
                        display_short: ''
                    },
                    capsule: {
                        display_long: 'капсула(и)',
                        display_short: ''
                    },
                    drop: {
                        display_long: 'капка(и)',
                        display_short: ''
                    },
                    gram: {
                        display_long: 'грам(а)',
                        display_short: 'г'
                    },
                    injection: {
                        display_long: 'инжекция(ии)',
                        display_short: ''
                    },
                    mg: {
                        display_long: 'милиграм(а)',
                        display_short: 'мг'
                    },
                    ml: {
                        display_long: 'милилитър(ри)',
                        display_short: 'мл'
                    },
                    packet: {
                        display_long: 'пакет(а)',
                        display_short: ''
                    },
                    patch: {
                        display_long: 'пластир(и)',
                        display_short: ''
                    },
                    piece: {
                        display_long: 'част(и)',
                        display_short: ''
                    },
                    tablet: {
                        display_long: 'таблетка(и)',
                        display_short: ''
                    },
                    puff: {
                        display_long: 'инхалиране(ия)',
                        display_short: ''
                    },
                    spray: {
                        display_long: 'впръскване(ия)',
                        display_short: ''
                    },
                    suppository: {
                        display_long: 'супозитория(ии)',
                        display_short: ''
                    },
                    tbsp: {
                        display_long: 'супена лъжица(и)',
                        display_short: 'с.л.'
                    },
                    tsp: {
                        display_long: 'чаена лъжица(и)',
                        display_short: 'ч.л.'
                    },
                    unit: {
                        display_long: 'единица(и)',
                        display_short: ''
                    }
                }
            }
        }
    }
}
