export const sq = {
    text:
    {
        title: "Medrec:M Monitor",
        main_page_label: "Rekordet e mia mjekësore",
        select_language_label: "Zgjidhni gjuhën",
        login_label: "Kyçuni",
        social_media_login_separator:"Ose",
        logout_label: "Dilni",
        save_btn_label: "Ruaj",
        username_label: "Username",
        email_label: "E-mail",
        password_label: "Fjalëkalimi",
        new_password_label: "Fjalëkalimi i ri",
        repeat_password_label: "Përsërit fjalëkalimin",
        email_required_message: "E-mail është i detyrueshëm!",
        password_required_message: "Fjalëkalimi është i detyrueshëm!",
        login_form_invalid_credentials_message: "Kredenciale të pa sakta!",
        register_label: "Regjistrohu",
        fullname_label: "Emri i plotë",
        register_form_password_confirm_label: "Konfirmo fjalëkalimin",
        register_form_email_exists_message: "E-mail është ekzistues!",
        register_form_email_not_correct_message: "E-mail nuk është i saktë!",
        register_form_passwords_not_match_message: "Fjalëkalimet nuk përputhen!",
        register_form_password_length_message: "Fjalëkalimi duhet të ketë minimumi 8 karaktere!",
        register_form_password_whitespace_message: "Fjalëkalimi nuk duhet të fillojë ose të mbarojë me hapësira!",
        register_form_fullname_required: "Emri i plotë është i detyrueshëm!",
        register_form_password_confirm_required_message: "Konfirmimi i fjalëkalimit është i detyrueshëm!",
        register_form_successful_message: "Regjistrimi u përfundua me sukses!",
        confirm_btn_label: "Konfirmo",
        close_btn_label: "Mbyll",
        Charts: "Grafikët",
        Logbook: "Ditari",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Sistolik",
        Diastolic: "Diastolik",
        Pulse: "Pulsi",
        notes: "Shënime",
        total_cholesterol: "Kolesteroli total",
        triglycerides: "Trigliceridet",
        Cholesterol: "Kolesteroli",
        Weight: "Pesha",
        Height: "Gjatësia",
        weight_and_height: "Pesha dhe gjatësia",
        chronic_conditions: "Gjëndje kronike",
        allergies: "Alergji",
        blood_group: "Grupi i gjakut",
        details: "Detaje",
        blood_pressure: "Presioni i gjakut",
        settings_label: "Konfigurime",
        unit_of_measurement_label: "Njësia e matjes",
        cholesterol_units_label: "Njësia e kolesterolit",
        classification_method_label: "Metoda e klasifikimit",
        date_format_label: "Formati i datës",
        time_24hour_label: "Koha format 24 orë",
        settings_updated_message: "Konfigurimet u ruajtën me sukses",
        US: "US (lbs, oz, fl oz, inch)",
        metric: "Metrikë (kg, gram, ml, cm)",
        kg: "Kg",
        pound: "Pound",
        inches: "inch",
        cm: "cm",
        None: "Asnjë",
        diagnosed_in: "Diagnostikuar në",
        mild: "E lehtë",
        mild_to_moderate: "E lehtë në të moderuar",
        moderate: "E moderuar",
        moderate_to_severe: "E moderuar në të rëndë",
        severe: "E rëndë",
        life_threatening: "Rrezik për jetën",
        drug_allergy: "Alergji ndaj barnave",
        dust_allergy: "Alergji ndaj pluhurit",
        food_allergy: "Alergji ndaj ushqimit",
        insect_allergy: "Alergji ndaj insekteve",
        latex_allergy: "Alergji ndaj laktozës",
        mold_allergy: "Alergji ndaj mykut",
        pet_allergy: "Alergji ndaj kafshëve shtëpiake",
        pollen_allergy: "Alergji ndaj polenës",
        other_allergy: "Alergji të tjera",
        Severity: "Ashpërsia",
        Medications: "Mjekime",
        millimol_per_litre: "mmol/L",
        milligram_per_decilitre: "mg/dL",
        millimetre_of_mercury: "mmHg",
        week_label: "7 ditë",
        fortnight_label: "14 ditë",
        month_label: "30 ditë",
        quarter_label: "90 ditë",
        custom_label: "Personalizuar",
        Symptoms: "Simptoma",
        Temperature: "Temperatura",
        Hydration: "Hidrimi",
        Respiratory_rate: "Shkalla e frymëmarrjes",
        Saturation: "Saturimi i oksigjenit",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Sesioni përfundoi',
        sharing_label: "Lejet e aksesit",
        pending_permission_list_label: "Lejet e aksesit në pritje për aprovim",
        approved_permission_list_label: "Lejet e aksesit të aprovuara",
        deny_permission_label: "Moho",
        approve_permission_label: "Aprovo",
        request_label: "Kërko",
        request_permission_label: "Kërko akses",
        requested_user_does_not_exist_message: "Përdoruesi me këtë e-mail nuk ekziston",
        premission_request_success_message: "Kërkesa për leje u realizua me sukses",
        forgot_password: "Keni harruar fjalëkalimin?",
        privacy_policy: "Politikat e privatësisë",
        change_password_label: "Ndrysho fjalëkalimin",
        gender_label: "Gjinia",
        country_label: "Shteti",
        male: "Mashkull",
        female: "Femër",
        city_label: "Qyteti",
        birthday_label: "Datëlindja",
        Dashboard: "Faqja kryesore",
        user_info_label: "Të dhënat e përdoruesit",
        latest_measurements_label: "Matjet e fundit",
        last_week_data: "Grafikët e javës",
        no_recent_measurements: "Nuk ka matje të bëra kohët të fundit",
        loading_data_label: "Duke ngarkuar të dhënat...",
        logbook_no_data_label: "Ky përdorues nuk ka shënuar akoma të dhëna.",
        symptoms_no_data_label: "Ky përdorues nuk ka shënuar akoma simptoma.",
        search_placeholder_label: "Kërko...",
        latest_symptoms_label: "Simptomat e 24 orëve të fundit",
        no_latest_symptoms_label: "Nuk ka simptoma të shënuara 24 orët e fundit",
        profile_label: 'Profili',
        edit_profile_label: 'Edito profilin',
        update_btn: 'Modifiko',
        upload_image_label: 'Ngarko foto profili',
        user_info_updated_message: 'Profili u përditësua me sukses',
        user_password_updated_message: 'Fjalëkalimi u përditësua me sukses',
        user_password_update_error_message: 'Gabim në server!',
        unsupported_image_format: 'Formati i imazhit të zgjedhur nuk është i suportuar',
        choose_file_label: 'Zgjidh',
        Documents: "Dokumente",
        category_lobal: "Kategori",
        document_type_label: "Tipi i dokumentit",
        type_description_label: "Përshkrim i tipit",
        document_download_dialog_title: "Shkarko dokumentin",
        confirmation_dialog_message: "Dokumenti ({fileName}) të cilin ju doni të shkarkoni i përket {owner} dhe përmban të dhëna sensitive. Ruajeni në një vend të sigurtë ose fshijeni pasi të mbaroni punë.",
        documents_no_data_label: "Ky përdorues nuk ka ngarkuar akoma dokumente.",
        download_btn: "Shkarko",
        cancel_btn: "Anulo",
        document_type_none: "Asnjë",
        document_type_epicrisis: "Epikrizë",
        document_type_prescription: "Recetë",
        document_type_lab_results: "Rezultatet laboratorike",
        document_type_ambulance_sheet: "Raport mjekësor",
        document_type_referral: "Rekomandim",
        document_type_other: "Të tjera",
        all_label: "Të githa",
        select_category: "Zgjidhni kategorinë",
        documents_no_data_for_filters_label: "Nuk ka dokumente të cilat korrespondojnë me filtrat e zgjedhur.",
        pending_for_user_approval_label: "Përdoruesit të cilët duhet të pranojnë kërkesën tuaj",
        start_date: "Datë nga",
        end_date: "Datë tek",
        load_more: "Shiko më shumë",
        afternoon: "Drekë",
        morning: "Mëngjes",
        evening: "Mbrëmje",
        select_gender: "Zgjidhni gjininë",
        my_documents_label: "Dokumentat e mia",
        my_children_label: "Fëmijët e mi",
        Blood_sugar: "Sheqeri në gjak",
        HBA1C: "HbA1c",
        Ketones: "Ketone",
        Flow: "Rrjedhja",
        Color: "Ngjyra",
        Consistency: "Konsistenca",
        Urine_pH: "pH i urinës",
        no_flow: "Pa rrjedhje",
        light: "Lehtë",
        medium: "Mesatar",
        heavy: "Rëndë",
        unexpected: "Pa pritur",
        none: "Asnjë",
        black: "E zezë",
        brown: "Kafe",
        dark_red: "E kuqe e errët",
        bright_red: "E kuqe e hapur",
        pink: "Rozë",
        orange: "Portokalli",
        gray: "Gri",
        waterly: "E lengshme",
        clots: "Mpiksur",
        mmol_mol: "mmol/mol",
        no_chronic_conditions: "Pa gjendje kronike",
        no_allergies: "Pa alergji",
        Communication: "Komunikim",
        my_communication_label: "Komunikimet e mia",
        new_chat: "Chat i ri",
        recent_chats_label: "Komunikimet e fundi",
        available_users_label: "Përdoruesit",
        back: "Mbrapa",
        type_message: "Shkruaj një mesazh...",
        call: "Thirrje hyrëse",
        incoming_call_message: "Thirrje hyrëse nga %1",
        incoming_call_another_user_message: "Thirrje hyrëse nga një përdorues tjetër",
        accept: "Prano",
        no_user_selected_message: 'Asnje përdorues i zgjedhur për të thirrur',
        ok: 'ok',
        no_answer: "Pa përgjigje",
        no_answer_message: "Asnjë përgjigje nga %1",
        you_wrote: "Ti ke shkruar",
        missed_call_label: "Thirrje e humbur",
        rejected_call_label: "Thirrje e refuzuar",
        in_progress_call_label: "Thirrje në progres",
        completed_call_label: "Thirrje e përfunduar",
        ongoing_call: "Videocall në progres!",
        medication_plan: "Plani i mjekimit",
        current_medications: "Barnat aktuale",
        general_practitioner: "Mjek i Përgjithshëm (Mjeku i Familjes)",
        practice_name:"Emri i Klinikës",
        practice_address:"Adresa e Klinikës",
        practice_contact:"Numri i kontaktit",
        practice_centre_code:"Kodi i Klinikës",
        practice_practitioner_code:"Kodi i Mjekut",
        na:"Jo e disponueshme",
        times_per_day: "%1 %2 x %3 herë në ditë",
        every_x_hours: "%1 %2 x çdo %3 orë",
        daily: 'Përditë',
        every_x_days: "Çdo %1 ditë",
        take_x_rest_x: "Merr %1 ditë dhe pushon %2 ditë",
        when_needed: "Kur duhet",
        ongoing: "Në vazhdimësi",
        until_date: 'Deri më %1',
        for_x_days_until: "Për %1 ditë, deri më %2",
        effective_from: "Efektive nga %1",
        medical_record_label: "Rekordet mjekësore",
        no_available_data: "Nuk ka të dhëna të disponueshme",
        Lab_Results: "Rezultatet laboratorike",
        select_laboratory_label: "Zgjidhni laboratorin",
        lab_results_open_results_label: "Hap rezultatet",
        lab_results_close_results_label: "Mbyll rezultatet",
        lab_results_no_data_for_filters_label: "Nuk ka rezultate nga laboratorë të cilat korrespondojnë me filtrat e zgjedhur.",
        lab_results_no_data_label: "Ky përdorues nuk ka importuar akoma rezultate nga laboratorë.",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Zgjidh',
        preview_image: 'Shiko',
        OK: 'OK',
        delete_image_modal_tittle: 'Hiqni imazhin',
        delete_image_modal_message: 'Jeni të sigurt që doni të hiqni imazhin tuaj?',
        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ampulë(a)',
                        display_short: ''
                    },
                    application: {
                        display_long: 'aplikim(e)',
                        display_short: ''
                    },
                    capsule: {
                        display_long: 'kapsulë(a)',
                        display_short: ''
                    },
                    drop: {
                        display_long: 'pikë(a)',
                        display_short: ''
                    },
                    gram: {
                        display_long: 'gram',
                        display_short: 'g'
                    },
                    injection: {
                        display_long: 'injeksion(e)',
                        display_short: ''
                    },
                    mg: {
                        display_long: 'miligram',
                        display_short: 'mg'
                    },
                    ml: {
                        display_long: 'mililitër(a)',
                        display_short: 'ml(s)'
                    },
                    packet: {
                        display_long: 'paketë(a)',
                        display_short: ''
                    },
                    patch: {
                        display_long: 'pjesë',
                        display_short: ''
                    },
                    piece: {
                        display_long: 'copë(a)',
                        display_short: ''
                    },
                    tablet: {
                        display_long: 'tabletë(a)',
                        display_short: ''
                    },
                    puff: {
                        display_long: 'thithje',
                        display_short: ''
                    },
                    spray: {
                        display_long: 'spraj(e)',
                        display_short: ''
                    },
                    suppository: {
                        display_long: 'suposto',
                        display_short: ''
                    },
                    tbsp: {
                        display_long: 'lugë gjelle',
                        display_short: ''
                    },
                    tsp: {
                        display_long: 'lugë çaji',
                        display_short: ''
                    },
                    unit: {
                        display_long: 'njësi',
                        display_short: ''
                    }
                }
            }
        }
    }
}
