import {
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    ALL_USERS_ERROR,
    ALL_USERS_FILTER_CHANGE,
    CLEAR_USERS,
    SELECT_USER,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    LOGOUT,
    CLEAR_SELECTED_USER,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    NO_MORE_ENTRIES,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    CHANGE_CHARTS_FILTERS,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    UPDATE_USER_PROFILE_ERROR,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    CLEAR_USER_PASSWORD,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR
} from "../actions/actions";
import { requestStatus } from './requests_reducers';

const initialState = { list: [], filter: null, request: requestStatus(undefined, {}) }

/**
 * Users reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the users
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function users(state = initialState, action) {
    switch (action.type) {
        case ALL_USERS_REQUEST_SENT: {
            const { error, isLastPage, ...rest } = state;
            return { ...rest, request: requestStatus(rest.request, { type: REQUEST_SENT }) }
        }
        case ALL_USERS_SUCCESS: {
            const { error, ...rest } = state;
            if (action.reset) {
                return { ...rest, list: action.result.content, isLastPage: action.result.last, request: requestStatus(rest.request, { type: REQUEST_SUCCESS }) }
            }
            return { ...rest, list: [...rest.list, ...action.result.content], isLastPage: action.result.last, request: requestStatus(rest.request, { type: REQUEST_SUCCESS }) }
        }
        case ALL_USERS_ERROR: {
            const { list, isLastPage, ...rest } = state;
            return { ...rest, error: action.result, request: requestStatus(rest.request, { type: REQUEST_ERROR, response: action.result }) };
        }
        case ALL_USERS_FILTER_CHANGE: {
            return { ...state, filter: action.filter };
        }
        case UPDATE_USER_PROFILE: {
            const usersList = state.list.map(u => {
                if (u.id === action.result.id) {
                    return action.result;
                }
                return u;
            });
            return { ...state, list: usersList };
        }
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...initialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * User profile info reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userInfoInitialState = { data: {}, request: requestStatus(undefined, {}) }
export function userInfo(state = userInfoInitialState, action) {
    switch (action.type) {
        case GET_USER_PROFILE:
        case UPDATE_USER_PROFILE: {
            return { ...state, data: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) };
        }
        case GET_USER_PROFILE_ERROR: {
            return { ...state, ...userInfoInitialState, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.response }) };
        }
        case UPDATE_USER_PROFILE_ERROR: {
            return { ...state, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.response }) };
        }
        case LOGOUT: {
            return { ...state, ...userInfoInitialState };
        }
        default: {
            return state
        }
    }
}

/**
 * User password reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userPasswordInitialState = { request: requestStatus(undefined, {}), }
export function userPassword(state = userPasswordInitialState, action) {
    switch (action.type) {
        case REQUEST_SENT: {
            return { ...state, request: requestStatus(state.request, { type: REQUEST_SENT }) };
        }
        case UPDATE_USER_PASSWORD: {
            return { ...state, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) };
        }
        case UPDATE_USER_PASSWORD_ERROR: {
            return { ...state, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) };
        }
        case CLEAR_USER_PASSWORD:
        case LOGOUT: {
            return { ...state, ...userPasswordInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * Selected user reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const selectedUserInitialState = { data: {} }
export function selectedUser(state = selectedUserInitialState, action) {
    switch (action.type) {
        case SELECT_USER: {
            const { data, ...rest } = state;
            return { ...rest, data: action.user }
        }
        case CLEAR_USERS:
        case CLEAR_SELECTED_USER: {
            return { ...state, ...selectedUserInitialState };
        }
        case LOGOUT: {
            return { ...selectedUserInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * Logbook entries reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const logBookEntriesInitialState = { entries: [], isLastPage: false, request: requestStatus(undefined, {}) }
export function logBook(state = logBookEntriesInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT: {
            const { error, isLastPage, ...rest } = state;
            return { ...rest, request: requestStatus(rest.request, { type: REQUEST_SENT }) }
        }
        case FETCH_SELECTED_USER_ENTRIES_SUCCESS: {
            return { ...state, entries: [...state.entries, ...action.result], request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_SELECTED_USER_ENTRIES_ERROR: {
            const { entries, isLastPage, ...rest } = state;
            return { ...rest, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case NO_MORE_ENTRIES: {
            return { ...state, isLastPage: true }
        }
        case SELECT_USER:
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...logBookEntriesInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 *Latest user logs reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const latestUserMeasurementsInitialState = { entries: [], request: requestStatus(undefined, {}) }
export function latestUserMeasurements(state = latestUserMeasurementsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS: {
            return { ...state, entries: [...state.entries, ...action.result], request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR: {
            const { entries, ...rest } = state;
            return { ...rest, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case SELECT_USER:
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...latestUserMeasurementsInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * Medical profile reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const medicalProfileInitialState = { data: {}, request: requestStatus(undefined, {}) }
export function medicalProfile(state = medicalProfileInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS: {
            return { ...state, data: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR: {
            return { ...state,  ...initialState, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case SELECT_USER:
        case CLEAR_USERS:
        case LOGOUT: {
            return { ...state, ...medicalProfileInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * Chart data reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const chartsInitialState = {
    userId: null,
    filters: {
        period: "WEEK",
        before_date_time: null,
        after_date_time: null
    },
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}
export function charts(state = chartsInitialState, action) {
    switch (action.type) {
        case FETCH_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return { ...state, userId: action.userId, chartsData: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_CHARTS_DATA_ERROR: {
            const rest = { ...state, chartsData: chartsInitialState.chartsData };
            return { ...rest, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case CHANGE_CHARTS_FILTERS: {
            return { ...state, filters: { period: action.period, before_date_time: action.beforeDateTime, after_date_time: action.afterDateTime } }
        }
        case CLEAR_USERS:
        case SELECT_USER: {
            return { ...state, chartsData: chartsInitialState.chartsData, request: chartsInitialState.request };
        }
        case LOGOUT: {
            return { ...state, ...chartsInitialState };
        }
        default: {
            return state;
        }
    }
}

/**
 * Dashboard chart data reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const dashboardChartsInitialState = {
    userId: null,
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}
export function dashboardCharts(state = dashboardChartsInitialState, action) {
    switch (action.type) {
        case FETCH_DASHBOARD_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return { ...state, userId: action.userId, chartsData: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) }
        }
        case FETCH_DASHBOARD_CHARTS_DATA_ERROR: {
            const rest = { ...state, chartsData: dashboardChartsInitialState.chartsData };
            return { ...rest, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) }
        }
        case CLEAR_USERS:
        case SELECT_USER: {
            return { ...state, chartsData: dashboardChartsInitialState.chartsData, request: dashboardChartsInitialState.request };
        }
        case LOGOUT: {
            return { ...state, ...dashboardChartsInitialState };
        }
        default: {
            return state;
        }
    }
}


/**
 * Add properties to chart datasets
 * 
 * @param {object} chartsData object containing all the charts data
 */
function addPropertiesToChartDatasets(chartsData) {
    chartsData.chartsDataAveragesPerDayInterval.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerDayOfWeek.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataHydrationAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataBPTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
        d.spanGaps = true
    });

    chartsData.chartsDataWeightTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataCholesterolTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataTemperatureTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataHydrationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataSaturationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataRespiratoryRateTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    function setLineProperties(dataset) {
        dataset.borderDash = [5, 5];
        dataset.borderWidth = 2;
        dataset.pointRadius = 3;
        dataset.pointHoverRadius = 3;
    }

    function setBarProperties(dataset) {
        dataset.barThickness = 15;
        dataset.maxBarThickness = 25;
    }
}