import React, { Component } from 'react'
import classnames from 'classnames'
import { $$ } from '../../helpers/localization'
import { validators } from '../../helpers/validators'
import PermissionList from './PermissionList';

export class RequestPermission extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        email: '',
        formclassName: '',
        errors: {}
    }

    /**
	 * Set the state to the latest change in the input value.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
	 * Form submit handler, validate data and set error in state if any. Call request permission action.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({ errors: formErrors });
        evt.preventDefault();
        if (this.state.formclassName !== "was-validated") {
            this.setState({ formclassName: "was-validated" });
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.props.requestPermission(this.state.email);
        }
    }

    /**
	 * Validate enetered form data..
	 *
	 * @returns {object} errors - Form errors after validate
	 */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email)) {
            errors.email = $$('register_form_email_not_correct_message');
        }
        return errors;
    }

    render() {
        const emailTxtClasses = classnames({
            "custom-error": !!this.state.errors.email,
            "form-control": true
        });
        const formClass = classnames({
            "form-inline": true,
            "was-validated": !!this.state.formclassName
        });

        let alert = '';
        if (this.props.error !== null && this.props.error !== undefined) {
            alert = (
                <div className="alert alert-danger">
                    {$$('requested_user_does_not_exist_message')}
                </div>
            );
        } else if (this.props.success) {
            alert = (
                <div className="alert alert-success">
                    {$$('premission_request_success_message')}
                </div>
            );
        }

        return (
            <div className="col-xs-8 col-md-8">
                <div className="card">
                    <div className="card-header" id="headingOne">
                        <h5 className="mb-0">
                            {$$('request_permission_label')}
                        </h5>
                    </div>
                    <div className="card-body">
                        {alert}
                        <div>
                            <form className="form-inline" onSubmit={this.onSubmit} className={formClass} noValidate={true} style={{ "width": "100%" }}>
                                <div className="form-group mb-2 col-xs-10 col-md-10">
                                    <input type="text"
                                        className={emailTxtClasses}
                                        value={this.state.email}
                                        placeholder={$$('email_label')}
                                        name="email"
                                        onChange={this.onInputChange}
                                        style={{ "width": "100%" }}
                                        required />
                                    <div className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.email ? this.state.errors.email : $$('email_required_message')}
                                    </div>
                                </div>
                                <div className="col-xs-2 col-md-2 request-permission-btn">
                                    <button type="submit" className="btn btn-primary mb-2">{$$('request_label')}</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <div style={{ "marginLeft": "15px", "marginTop": "10px" }}>{$$('pending_for_user_approval_label')}</div>
                            <PermissionList permissions={this.props.pending} onDeny={this.props.onDeny} cancelBtnLabel={$$('cancel_btn')} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RequestPermission
