import React, { Component } from 'react'
import TemperatureEntryV2 from '../loogbok/TemperatureEntryV2';
import RespiratoryRateEntryV2 from '../loogbok/RespiratoryRateEntryV2';
import SaturationEntryV2 from '../loogbok/SaturationEntryV2';
import WeightEntryV2 from '../loogbok/WeightEntryV2';
import CholesterolEntryV2 from '../loogbok/CholesterolEntryV2';
import BloodPressureEntryV2 from '../loogbok/BloodPressureEntryV2';
import HydrationEntryV2 from '../loogbok/HydrationEntryV2'
import { BLOOD_PRESSURE, WEIGHT, CHOLESTEROL, MEDICATION, HYDRATION, TEMPERATURE, RESPIRATORY_RATE, SATURATION, BLOOD_SUGAR, HBA1C, KETONES, URINE_PH, HEIGHT, MENSTRUATION } from '../../constants/entry_types';
import { $$ } from '../../helpers/localization'
import BloodSugarEntryV2 from '../loogbok/BloodSugarEntryV2';
import HBA1CEntryV2 from '../loogbok/HBA1CEntryV2';
import KetonesEntryV2 from '../loogbok/KetonesEntryV2';
import UrinePHEntryV2 from '../loogbok/UrinePHEntryV2';
import HeightEntryV2 from '../loogbok/HeightEntryV2';
import MenstruationEntityV2 from '../loogbok/MenstruationEntryV2';
import MedicationEntryV2 from '../loogbok/MedicationEntryV2';

export class LatestMeasurements extends Component {
    render() {
        const latestUserMeasurements = this.props.latestUserMeasurements.entries && this.props.latestUserMeasurements.entries.length > 0 ? this.props.latestUserMeasurements.entries.map((entry, idx) => {
            switch (entry.entry_type) {
                case WEIGHT:
                    return <WeightEntryV2 entry={entry} key={idx} unit={this.props.settings.data.unitsOfMeasurement} hideNotes={true} />
                case CHOLESTEROL:
                    return <CholesterolEntryV2 entry={entry} key={idx} unit={this.props.settings.data.cholesterolUnit} hideNotes={true} />
                case BLOOD_PRESSURE:
                    return <BloodPressureEntryV2 entry={entry} key={idx} hideNotes={true} />
                case MEDICATION:
                    return <MedicationEntryV2 entry={entry} key={idx} hideNotes={true} />
                case HYDRATION:
                    return <HydrationEntryV2 entry={entry} key={idx} hideNotes={true} />
                case TEMPERATURE:
                    return <TemperatureEntryV2 entry={entry} key={idx} hideNotes={true} />
                case RESPIRATORY_RATE:
                    return <RespiratoryRateEntryV2 entry={entry} key={idx} hideNotes={true} />
                case SATURATION:
                    return <SaturationEntryV2 entry={entry} key={idx} hideNotes={true} />
                case HEIGHT:
                    return <HeightEntryV2 entry={entry} key={idx} hideNotes={true} />
                case BLOOD_SUGAR:
                    return <BloodSugarEntryV2 entry={entry} key={idx} hideNotes={true} />
                case HBA1C:
                    return <HBA1CEntryV2 entry={entry} key={idx} hideNotes={true} />
                case KETONES:
                    return <KetonesEntryV2 entry={entry} key={idx} hideNotes={true} />
                case URINE_PH:
                    return <UrinePHEntryV2 entry={entry} key={idx} hideNotes={true} />
                case MENSTRUATION:
                    return <MenstruationEntityV2 entry={entry} key={idx} hideNotes={true} />
                default:
                    return '';
            }
        }) : (<div style={{ "paddingTop": "15px" }}>{$$("no_recent_measurements")}</div>);

        return (
            <div className="card">
                <div className="card-header">
                    <i className='kt-menu__link-icon flaticon2-medical-records-1' /> {$$('latest_measurements_label')}
                </div>
                <div className="card-body row" style={{ "paddingTop": "2px" }}>
                    <div className="latest-measurements-cards">
                        {latestUserMeasurements}
                    </div>
                </div>
            </div>
        )
    }
}

export default LatestMeasurements
