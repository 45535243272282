import React, { Component } from 'react'
import classnames from 'classnames';

export default class Tab extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * On click handler for the tab button
     * 
     * @param {object} e the event object
     */
    onClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.tabIndex);
    }

    render() {
        var tabClass = classnames({
            'btn': true,
            'btn-default': true,
            'btn-active': this.props.isActive
        });

        return (
            <button type="button" className={tabClass} onClick={(e) => this.onClick(e)}> {this.props.tabName} </button>
        )
    }
}
