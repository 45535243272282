import React, { Component } from 'react'
import { CONVERTER } from '../../utils/converter'
import moment from 'moment'
import { LabResultEntry } from './LabResultEntry'

export class GroupedLabResults extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const labResults = this.props.labResults && this.props.labResults.length > 0 ? this.props.labResults.map((entry, idx) => {
            return <LabResultEntry key={idx} index={idx} labs={this.props.labs} entry={entry} filters={this.props.filters} />
        }, this) : noData;
        return (
            <div className="card grouped-card">
                <div className="card-header">
                    {moment(this.props.resultDate).format(CONVERTER.getSelectedDateTimeFormat(true))}, {moment(this.props.resultDate).locale(this.props.i18n.lang).format('dddd')}
                </div>
                <div className="card-body" style={{ "padding": "0" }}>
                    {labResults}
                </div>
            </div>
        )
    }
}

export default GroupedLabResults
