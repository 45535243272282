import React, { Component } from 'react'
import Tabs from './Tabs';
import Tab from './Tab';
import Logbook from '../loogbok/Logbook';
import { connect } from 'react-redux'
import {
    fetchSelectedUserLogbookEntries,
    clearSelectedUserData,
    changeChartsFilters,
    fetchChartsData,
    fetchDashboardChartsData
} from '../../actions/users_actions';
import { fetchSelectedUserSymptomsLog } from '../../actions/symptoms_actions';
import { changeDocumentsFilters, fetchSelectedUserDocuments } from '../../actions/documents_actions';
import Charts from '../charts/Charts';
import { $$ } from '../../helpers/localization'
import { SubHeader } from './SubHeader';
import ChartFilters from './ChartFilters'
import SymptomsLog from '../symptoms/SymptomsLog';
import Dashboard from '../dashboard/Dashboard';
import Documents from '../documents/Documents';
import DocumentsFilters from '../documents/DocumentsFilters';
import { fetchLaboratories, fetchSelectedUserLabResults, changeLabResultsFilters } from '../../actions/lab-results_actions';
import LabResults from '../lab-results/LabResults';
import LabResultsFilters from '../lab-results/LabResultsFilters';

class TabsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: props.selectedUser ? props.selectedUser.id : null
        }
    }

    componentDidMount() {
        this.props.fetchLaboratories();
    }
    /**
     * Fetch the charts data if the filters or language has changed
     */
    componentDidUpdate(prevProps) {
        if (prevProps.selectedUser.id && this.props.selectedUser.id) {
            if ((prevProps.chartFilters.period !== this.props.chartFilters.period) ||
                (prevProps.chartFilters.before_date_time !== this.props.chartFilters.before_date_time) ||
                (prevProps.chartFilters.after_date_time !== this.props.chartFilters.after_date_time) ||
                (prevProps.i18n.selected.lang !== this.props.i18n.selected.lang)) {
                this.props.fetchChartsData(this.props.selectedUser.id);
                this.props.fetchDashboardChartsData(this.props.selectedUser.id);
            }
            if (prevProps.documents.filters.before_date_time !== this.props.documents.filters.before_date_time) {
                this.props.fetchSelectedUserDocuments(this.props.selectedUser.id, null, true);
            }
            if (prevProps.labResults.filters.before_date_time !== this.props.labResults.filters.before_date_time) {
                this.props.fetchSelectedUserLabResults(this.props.selectedUser.id, null, true);
            }
        }
    }

    /**
	   * Get the options for the documents category filter
     *
     * @returns {array} options for the documents category filter
	   */
    getDocumentsCategoryOptions = () => {
        const categories = [{ text: $$('select_category'), value: '' }];
        if (this.props.documents &&
            this.props.documents.selectedUser &&
            this.props.documents.selectedUser.entries &&
            this.props.documents.selectedUser.entries.length > 0) {
            for (let doc of this.props.documents.selectedUser.entries) {
                if (categories.length === 11) {
                    break;
                }
                if (doc.category && !categories.some(c => c.value === doc.category)) {
                    categories.push({ text: doc.category, value: doc.category });
                }
            }
        }
        return categories;
    }
    
    /**
	   * Get the options for the lab results laboratory filter
     *
     * @returns {array} options for the lab results laboratory filter
	   */
    getLabResultsLaboratoryOptions = () => {
        const laboratories = [{ text: $$('select_laboratory_label'), value: '' }];
        if (this.props.labs && this.props.labs.length > 0) {
            for (let lab of this.props.labs) {
                if (laboratories.length === 11) {
                    break;
                }
                if (lab.name && !laboratories.some(l => l.value === lab.name)) {
                    laboratories.push({ text: lab.name, value: lab.lab });
                }
            }
        }
        return laboratories;
    }

    render() {
        return (
            <Tabs defaultActiveTabIndex={0}>
                <Tab tabName={$$('Dashboard')}>
                    <Dashboard
                        selectedUser={this.props.selectedUser}
                        settings={this.props.settings}
                        isLoggedUserProfile={this.props.isLoggedUserProfile} />
                </Tab>
                <Tab tabName={$$('Logbook')}>
                    <Logbook userId={this.props.selectedUser.id}
                        logbook={this.props.logBook}
                        isLastPage={this.props.logBook.isLastPage}
                        isFinished={this.props.logBook.request.finished}
                        fetchEntries={this.props.fetchSelectedUserLogbookEntries}
                        settings={this.props.settings}
                        i18n={this.props.i18n.selected} />
                </Tab>
                <Tab tabName={$$('Symptoms')}>
                    <SymptomsLog userId={this.props.selectedUser.id}
                        symptoms={this.props.symptoms}
                        nomenclature={this.props.nomenclature}
                        fetchEntries={this.props.fetchSelectedUserSymptomsLog}
                        settings={this.props.settings}
                        i18n={this.props.i18n.selected} />
                </Tab>
                <Tab tabName={$$('Charts')}>
                    <SubHeader>
                        <ChartFilters
                            i18n={this.props.i18n}
                            selectedUser={this.props.selectedUser}
                            filters={this.props.chartFilters}
                            changeChartsFilters={this.props.changeChartsFilters}
                        />
                    </SubHeader>
                    <Charts />
                </Tab>
                <Tab tabName={$$('Documents')}>
                    <SubHeader>
                        <DocumentsFilters
                            i18n={this.props.i18n}
                            filters={this.props.documents.filters}
                            categoryOptions={this.getDocumentsCategoryOptions()}
                            changeDocumentsFilters={this.props.changeDocumentsFilters}
                        />
                    </SubHeader>
                    <Documents selectedUser={this.props.selectedUser}
                        filters={this.props.documents.filters}
                        documents={this.props.documents.selectedUser}
                        fetchEntries={this.props.fetchSelectedUserDocuments}
                        i18n={this.props.i18n.selected} />
                </Tab>
                <Tab tabName={$$('Lab_Results')}>
                    <SubHeader>
                        <LabResultsFilters
                            i18n={this.props.i18n}
                            filters={this.props.labResults.filters}
                            laboratoryOptions={this.getLabResultsLaboratoryOptions()}
                            changeLabResultsFilters={this.props.changeLabResultsFilters}
                        />
                    </SubHeader>
                    <LabResults selectedUser={this.props.selectedUser}
                        filters={this.props.labResults.filters}
                        labResults={this.props.labResults.selectedUser}
                        labs={this.props.labs}
                        fetchEntries={this.props.fetchSelectedUserLabResults}
                        i18n={this.props.i18n.selected} />
                </Tab>
            </Tabs>
        )
    }
}

function mapStateToProps(state) {
    return {
        selectedUser: state.selectedUser.data,
        logBook: state.logBook,
        symptoms: state.symptoms,
        nomenclature: state.nomenclature,
        settings: state.settings,
        chartFilters: state.charts.filters,
        documents: state.documents,
        labs: state.laboratories.labs,
        labResults: state.labResults,
        auth: state.authentication
    }
}

export default connect(mapStateToProps, {
    fetchSelectedUserLogbookEntries,
    clearSelectedUserData,
    changeChartsFilters,
    fetchChartsData,
    fetchDashboardChartsData,
    fetchSelectedUserSymptomsLog,
    fetchSelectedUserDocuments,
    changeDocumentsFilters,
    fetchLaboratories,
    fetchSelectedUserLabResults,
    changeLabResultsFilters
})(TabsContainer);
