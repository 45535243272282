import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import Brand from "../brand/Brand";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import UserList from '../../../components/main/UserList';
import { getAllUsers, clearUsers } from '../../../actions/users_actions'
import SearchDefault from "../../../components/theme/SearchDefault";

class AsideLeft extends React.Component {
    asideOffCanvasRef = React.createRef();

    componentWillUnmount() {
        this.props.clearUsers();
    }

    componentDidMount() {
        // eslint-disable-next-line
        this.props.getAllUsers({ page: 0, size: 500 }, this.props.selectedUser && this.props.selectedUser.id ? this.props.selectedUser.id : this.props.userInfo.id);

        const ktoffConvas = new KTOffcanvas(
            this.asideOffCanvasRef.current,
            this.props.menuCanvasOptions
        );
    }

    render() {
        //Filter users based on the input entered in the search field
        const filter = this.props.users_data.filter;
        const users = this.props.users_data && this.props.users_data.list ? this.props.users_data.list.filter(u => {
            if(u.id === this.props.userInfo.id) {
                return false;
            }
            if (!filter) {
                return true;
            }
            return u.fullname.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        }) : [];
        return (
            <>
                <button className="kt-aside-close" id="kt_aside_close_btn">
                    <i className="la la-close" />
                </button>
                <div
                    id="kt_aside"
                    ref={this.asideOffCanvasRef}
                    className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
                >
                    <Brand />
                    <div
                        id="kt_aside_menu_wrapper"
                        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
                    >
                        {this.props.disableScroll && (
                            <Menu htmlClassService={this.props.htmlClassService} />
                        )}
                        {!this.props.disableScroll && (
                            <PerfectScrollbar
                                options={{ wheelSpeed: 2, wheelPropagation: false }}
                            >
                                <Menu htmlClassService={this.props.htmlClassService} />
                            </PerfectScrollbar>
                        )}
                    </div>
                    <div className="kt-aside-menu user-search" style={{ "paddingLeft": "15px" }}>
                        <div className="kt-menu__nav" style={{ "padding": "0" }}>
                            <SearchDefault i18n={this.props.i18n} />
                        </div>
                    </div>
                    <div className="user-list-container kt-aside-menu">
                        <UserList users={users}
                            selectedUser={this.props.selectedUser}
                            i18n={this.props.i18n} />
                    </div>
                    <div className="text-center small">
                        Version 2.0.5 (e25127e3)
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    disableAsideSelfDisplay:
        builder.selectors.getConfig(store, "aside.self.display") === false,
    disableScroll:
        builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
        false,
    asideClassesFromConfig: builder.selectors.getClasses(store, {
        path: "aside",
        toString: true
    }),
    menuCanvasOptions: {
        baseClass: "kt-aside",
        overlay: true,
        closeBy: "kt_aside_close_btn",
        toggleBy: {
            target: "kt_aside_mobile_toggler",
            state: "kt-header-mobile__toolbar-toggler--active"
        }
    },
    userInfo: store.userInfo.data,
    users_data: store.users,
    selectedUser: store.selectedUser.data,
    i18n: store.language
});

export default connect(mapStateToProps, { getAllUsers, clearUsers })(AsideLeft);
