import React, { Component } from 'react'
import { TabContainer, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSlash, faMicrophone, faMicrophoneSlash, faVideoSlash, faVideo } from '@fortawesome/free-solid-svg-icons'
import _ from 'underscore'
import calling_mp3 from '../../../public/media/audio/calling.mp3'
import end_of_call_mp3 from '../../../public/media/audio/end_of_call.mp3'
import ringtone_mp3 from '../../../public/media/audio/ringtone.mp3'
import { IDLE, ONGOING, CALLING, STARTING_CALL, RINGING } from '../../constants/call_status'
import CenteredModal from '../shared/CenteredModal'
import classNames from 'classnames'
import { $$, _$$ } from '../../helpers/localization'
import { chatService } from '../../service/chat_service'
import { MISSED, REJECTED, IN_PROGRESS, COMPLETED } from '../../constants/video_message_status'

export class Video extends Component {
    constructor(props) {
        super(props);
        this.sounds = {
            'call': 'callingSignal',
            'end': 'endCallSignal',
            'rington': 'ringtoneSignal'
        };
        this.appId = process.env.REACT_APP_QUICK_BLOX_APP_ID;
        this.authKey = process.env.REACT_APP_QUICK_BLOX_AUTH_KEY;
        this.authSecret = process.env.REACT_APP_QUICK_BLOX_AUTH_SECRET;
        this._isMounted = false;
        this.callAudio = React.createRef();
        this.endAudio = React.createRef();
        this.ringtoneAudio = React.createRef();
    }

    state = {
        micMutted: false,
        videoOff: false,
        callStatus: this.props.startVideoCall ? STARTING_CALL : IDLE,
        modal: {
            show: false,
            title: '',
            confirmBtn: '',
            hideBtn: '',
            onConfirmModal: null,
            onHideModal: null
        }
    }

    /**
     * Start the duration timer for the call
     */
    startDurationTimer = () => {
        this.timerIntervalId = setInterval(() => this.duration = Date.now() - this.callStartTime, 1);
    }

    /**
     * Stop the duration timer for the call
     */
    stopDurationTimer = () => {
        this.callStartTime = null;
        clearInterval(this.timerIntervalId);
    }

    /**
     * Connect to chat, add listeners and if the user is the calling party initiate the call
     *
     * @param {object} prevProps - the previous properties object
     */
    componentDidUpdate(prevProps) {
        if (this.props.startVideoCall && !prevProps.recipientQBId && this.props.recipientQBId) {
            window.QB.webrtc && this.addQBListeners();
            this.connectToChat(this.onCallUser);
        }

        if (!prevProps.callerQBId && this.props.callerQBId || (prevProps.callerQBId !== this.props.callerQBId)) {
            window.QB.webrtc && this.addQBListeners();
            !window.QB.chat._isLogout && window.QB.chat.disconnect();
            this.connectToChat();
        }
    }

    /**
     * Connect to QB chat and start the call if the user is the calling part
     *
     * @param {function} callback - callback used to call the oposite user
     */
    connectToChat = (callback) => {
        if (window.QB.chat && this.props.currentUserQBId) {
            var params = { jid: window.QB.chat.helpers.getUserJid(this.props.currentUserQBId, this.appId), password: this.props.sessionToken };
            window.QB.chat.connect(params, function (err, res) {
                if (err) {
                    if (!_.isEmpty(this.currentSession)) {
                        this.currentSession.stop({});
                        this.currentSession = {};
                    }
                } else {
                    if (callback) {
                        callback(this.props.recipientQBId)
                    }
                }
            }.bind(this));
        }
    }

    /**
     * Disconnect from quickblox and destroy session
     */
    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.callStatus === ONGOING) {
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, this.duration, COMPLETED);
            this.stopDurationTimer();
        } else if (this.state.callStatus !== IDLE) {
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, 0, MISSED);
            this.stopDurationTimer();
        }
        if (!_.isEmpty(this.currentSession)) {
            this.currentSession.stop({});
            this.currentSession = {};
        }
        if (window.QB.chat && !window.QB.chat._isLogout) {
            window.QB.chat.disconnect();
        }
        if (window.QB.webrtc && window.QB.webrtc.sessions) {
            window.QB.webrtc.sessions = {};
        }
    }

    /**
     * Add quickblox listeners necessary to handle the videocall
     */
    addQBListeners = () => {
        //Listener called when the session is closed. Set the call status to IDLE
        window.QB.webrtc.onSessionCloseListener = function onSessionCloseListener(session) {
            console.log('onSessionCloseListener: ', session);

            this.onHideModal();
            this.callAudio.current.pause();
            this.endAudio.current.play();

            !_.isEmpty(this.currentSession) && this.currentSession.detachMediaStream('main_video');
            !_.isEmpty(this.currentSession) && this.currentSession.detachMediaStream('localVideo');

            this.ringtoneAudio.current.pause();
            this.setState({ callStatus: IDLE });
            this.currentSession = {};
            this.props.onHangupCall();
        }.bind(this);

        //Listener called when there is no answer in the given interval
        window.QB.webrtc.onUserNotAnswerListener = function onUserNotAnswerListener(session, userId) {
            console.group('onUserNotAnswerListener.');
            console.log('UserId: ', userId);
            console.log('Session: ', session);
            console.groupEnd();

            const modal = {
                show: true,
                title: $$('no_answer'),
                text: _$$('no_answer_message', this.props.userToChatFullName),
                confirmBtn: $$('ok'),
                hideBtn: $$('close_btn_label'),
                onConfirmModal: () => this.onHideModal()
            };
            this.setState({
                modal: modal
            });
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, 0, MISSED);
            this.hangupCall();
        }.bind(this);

        //Listener called when there is a call incoming. Set the call status to RINGING
        window.QB.webrtc.onCallListener = function onCallListener(session, extension) {
            console.group('onCallListener.');
            console.log('Session: ', session);
            console.log('Extension: ', extension);
            console.groupEnd();

            if (this.state.callStatus !== RINGING && this.state.callStatus !== ONGOING) {
                this.currentSession = session;
                this.setState({ callStatus: RINGING });
                this.onHideModal();
                // check the current session state
                if (session.state !== window.QB.webrtc.SessionConnectionState.CLOSED) {
                    this.setState({
                        modal: {
                            show: true,
                            title: $$('call'),
                            text: this.props.userToChatFullName ? _$$('incoming_call_message', this.props.userToChatFullName) : $$('incoming_call_another_user_message'),
                            confirmBtn: $$('accept'),
                            hideBtn: $$('close_btn_label'),
                            onHideModal: () => this.onDeclineCall(),
                            onConfirmModal: () => this.onAcceptCall()
                        }
                    });
                    this.ringtoneAudio.current.play();
                }
            }
        }.bind(this);

        //Listener called when the call is rejected
        window.QB.webrtc.onRejectCallListener = function onRejectCallListener(session, userId, extension) {
            if (this.currentSession.ID !== session.ID) {
                return;
            }
            console.group('onRejectCallListener.');
            console.log('UserId: ' + userId);
            console.log('Session: ' + session);
            console.log('Extension: ' + JSON.stringify(extension));
            console.groupEnd();
            this.hangupCall();
        }.bind(this);

        //Listener called when the call is accepted. Set the call statu to ONGOING.
        window.QB.webrtc.onAcceptCallListener = function onAcceptCallListener(session, userId, extension) {
            console.group('onAcceptCallListener.');
            console.log('UserId: ', userId);
            console.log('Session: ', session);
            console.log('Extension: ', extension);
            console.groupEnd();
            this.setState({ callStatus: ONGOING });
            this.callAudio.current.pause();
            this.callStartTime = new Date();
            this.startDurationTimer();
        }.bind(this);

        //Listener called when there is a remote stream incoming
        window.QB.webrtc.onRemoteStreamListener = function onRemoteStreamListener(session, userId, stream) {
            console.group('onRemoteStreamListener.');
            console.log('userId: ', userId);
            console.log('Session: ', session);
            console.log('Stream: ', stream);
            console.groupEnd();

            var state = this.currentSession.connectionStateForUser(userId),
                peerConnList = window.QB.webrtc.PeerConnectionState;

            if (state === peerConnList.DISCONNECTED || state === peerConnList.FAILED || state === peerConnList.CLOSED) {
                return false;
            }

            this.currentSession.peerConnections[userId].stream = stream;

            console.info('onRemoteStreamListener add video to the video element', stream);

            this.currentSession.attachMediaStream('main_video', this.currentSession.peerConnections[userId].stream);
        }.bind(this);

        //Listener called when the session state is changed
        window.QB.webrtc.onSessionConnectionStateChangedListener = function onSessionConnectionStateChangedListener(session, userId, connectionState) {
            console.group('onSessionConnectionStateChangedListener.');
            console.log('UserID:', userId);
            console.log('Session:', session);
            console.log('Сonnection state:', connectionState, statesPeerConn[connectionState]);
            console.groupEnd();

            if (connectionState === window.QB.webrtc.SessionConnectionState.CLOSED) {
                if (!_.isEmpty(this.currentSession)) {
                    if (Object.keys(this.currentSession.peerConnections).length === 1 || userId === this.currentSession.initiatorID) {
                        this.onHideModal();
                        this.ringtoneAudio.current.pause();
                    }
                }
            }

        }.bind(this);
    }

    /**
     * On accept call handler. Hide the modal, pause the ringtone and accept the call. Set the call status to ONGOING.
     */
    onAcceptCall = () => {
        const isAudio = this.currentSession.callType === window.QB.webrtc.CallType.AUDIO;

        var mediaParams;

        if (isAudio) {
            mediaParams = {
                audio: true,
                video: false
            };
        } else {
            mediaParams = {
                audio: true,
                video: true,
                elemId: 'localVideo',
                options: {
                    muted: true,
                    mirror: true
                }
            };
        }

        this.onHideModal();
        this.ringtoneAudio.current.pause();

        this.currentSession.getUserMedia(mediaParams, function (err, stream) {
            if (err || !stream.getAudioTracks().length || (isAudio ? false : !stream.getVideoTracks().length)) {
                var errorMsg = '';
                console.log(errorMsg);
                this.currentSession.stop({});
            } else {
                this.setState({ callStatus: ONGOING });
                this.currentSession.accept({});
                this.props.incomingVideoCallStart();
                this.callStartTime = new Date();
                this.startDurationTimer();
                chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, 0, IN_PROGRESS);
            }
        }.bind(this));
    }

    /**
     * Decline the call, hide the modal and pause the ringtone.
     */
    onDeclineCall = () => {
        if (!_.isEmpty(this.currentSession)) {
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, 0, REJECTED);
            this.onHideModal();
            this.hangupCall();
            this.ringtoneAudio.current.pause();
        }
    }

    /**
     * Hide the modal
     */
    onHideModal = () => {
        this.setState({
            modal: {
                show: false,
                title: '',
                text: '',
                confirmBtn: '',
                hideBtn: '',
                onHideModal: null,
                onConfirm: null
            }
        });
        this.callAudio.current.pause();
    }

    /**
     * On call user handler. Create the session, start the ringtone, send push notification. Set the call status to CALLING.
     *
     * @param {object} prevProps - the previous properties object
     */
    onCallUser = (user) => {
        //check for audio call
        const isAudio = false
        let mediaParams = {
            'audio': true,
            'video': true,
            'options': {
                'muted': true,
                'mirror': true
            },
            'elemId': 'localVideo'
        };

        /** Hangup */
        if (this.state.callStatus === ONGOING || this.state.callStatus === CALLING) {
            if (!_.isEmpty(this.currentSession)) {
                this.currentSession.stop({});
                this.currentSession = {};
                return false;
            }
        } else {
            /** Check internet connection */
            if (!window.navigator.onLine) {
                console.log("No internet connection!");
                return false;
            }

            if (!this.props.recipientQBId) {
                this.setState({
                    modal: {
                        show: true,
                        title: '',
                        text: $$('no_user_selected_message'),
                        confirmBtn: $$('ok'),
                        hideBtn: $$('close_btn_label'),
                        onConfirmModal: () => this.onHideModal()
                    }
                });
                return false;
            }
            const currentSession = window.QB.webrtc.createNewSession([this.props.recipientQBId], isAudio ? window.QB.webrtc.CallType.AUDIO : window.QB.webrtc.CallType.VIDEO, null, null);
            this.currentSession = currentSession;

            if (isAudio) {
                mediaParams = {
                    audio: true,
                    video: false
                };
            }

            currentSession.getUserMedia(mediaParams, function (err, stream) {
                if (err || !stream.getAudioTracks().length || (isAudio ? false : !stream.getVideoTracks().length)) {
                    var errorMsg = '';
                    currentSession.stop({});
                } else {
                    var callParameters = {};
                    if (isAudio) {
                        callParameters.callType = 2;
                    }
                    // Call to users
                    currentSession.call({}, function () {
                        if (!window.navigator.onLine) {
                            currentSession.stop({});
                        } else {
                            this.callAudio.current.play();
                            this.setState({ callStatus: CALLING });
                        }
                    }.bind(this));
                }
            }.bind(this));
        }
    }

    /**
     * Set the call status to IDLE and stop the session.
     */
    hangupCall = () => {
        if (this.state.callStatus !== IDLE) {
            if (!_.isEmpty(this.currentSession)) {
                this.currentSession.stop({});
                this.currentSession = {};
            }
            this.setState({ callStatus: IDLE });
        }
    }

    /**
     * On hangup call handler. Update the video message status.
     */
    onHangupCall = () => {
        if (this.state.callStatus === CALLING) {
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, 0, MISSED);
            this.stopDurationTimer();
        } else if (this.state.callStatus === ONGOING) {
            chatService.updateVideoCallDurationAndStatus(this.props.videoMessageId, this.duration, COMPLETED);
            this.stopDurationTimer();
        }
        this.hangupCall();
    }

    /**
     * On mic btn press. Mute or unmute
     */
    onMicBtnClick = () => {
        this.setState({ micMutted: !this.state.micMutted }, function () {
            if (this.state.micMutted) {
                this.currentSession.mute("audio");
            } else {
                this.currentSession.unmute("audio");
            }
        }.bind(this));
    }

    /**
     * On video btn press. Hold or continue video
     */
    onVideoBtnClick = () => {
        this.setState({ videoOff: !this.state.videoOff }, function () {
            if (this.state.videoOff) {
                this.currentSession.mute("video");
            } else {
                this.currentSession.unmute("video");
            }
        }.bind(this));
    }

    render() {
        const userList = this.props.users ? this.props.users.map((u, idx) => {
            const selectedCalleeId = this.props.recipientQBId ? this.props.recipientQBId : null;
            const activeUserClass = classNames("list-group-item", "list-group-item-action", {
                "active": (selectedCalleeId && u.id && selectedCalleeId.toString() === u.id.toString())
            })
            return <button type="button" key={idx} className={activeUserClass} value={u.id} onClick={e => this.onUserClick(e, "value")}>{u.full_name}</button>
        }) : '';

        const micBtnClass = classNames({
            "mic-off": this.state.micMutted === true,
            "mic-on": this.state.micMutted === false
        });
        const videoBtnClass = classNames({
            "video-off": this.state.videoOff === true,
            "video-on": this.state.videoOff === false
        });
        return (
            <div>
                {(this.state.callStatus === STARTING_CALL || this.state.callStatus === CALLING) &&
                    <div className="calling-overlay">
                        <div className="spiner-container"><Spinner animation="grow" /></div>
                    </div>
                }
                <div className="frames__main">

                    <div className="qb-video">
                        <video id="main_video" className="frames__main_v" autoPlay playsInline></video>
                        {(this.state.callStatus === ONGOING || this.state.callStatus === CALLING)
                            &&
                            <div className="buttons video-contols">
                                <button className="video-hangup-btn" data-target="video" onClick={this.onHangupCall}>
                                    <FontAwesomeIcon icon={faPhoneSlash} style={{ "color": "white" }} />
                                </button>
                                <button className="video-control-btn" style={{ "background": this.state.micMutted ? "grey" : "white" }} onClick={this.onMicBtnClick}>
                                    <FontAwesomeIcon className={micBtnClass} icon={this.state.micMutted ? faMicrophoneSlash : faMicrophone} />
                                </button>
                                <button className="video-control-btn" style={{ "background": this.state.videoOff ? "grey" : "white" }} onClick={this.onVideoBtnClick} >
                                    <FontAwesomeIcon className={videoBtnClass} icon={this.state.videoOff ? faVideoSlash : faVideo} />
                                </button>
                            </div>
                        }
                    </div>

                    <div className="local-video">
                        <video id="localVideo" className="qb-video_source" autoPlay playsInline></video>
                    </div>
                </div>

                <audio id="endCallSignal" preload="auto" ref={this.endAudio}>
                    <source src={end_of_call_mp3} type="audio/mp3" />
                </audio>

                <audio id="callingSignal" loop preload="auto" ref={this.callAudio}>
                    <source src={calling_mp3} type="audio/mp3" />
                </audio>

                <audio id="ringtoneSignal" loop preload="auto" ref={this.ringtoneAudio}>
                    <source src={ringtone_mp3} type="audio/mp3" />
                </audio>

                <CenteredModal title={this.state.modal.title}
                    show={this.state.modal.show}
                    onHide={this.state.modal.onHideModal ? this.state.modal.onHideModal : this.onHideModal}
                    onConfirm={this.state.modal.onConfirmModal}
                    confirmBtnLabel={this.state.modal.confirmBtn}
                    cancelBtnLabel={this.state.modal.hideBtn}
                >
                    {this.state.modal.text}
                </CenteredModal>
            </div>
        )
    }
}

export default Video
