import React, { Component } from 'react'
import { Routes } from '../../constants/routes'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth_actions'
import { changeLanguage } from '../../actions/language_actions'
import { $$ } from '../../helpers/localization'
import LanguageSelector from './LanguageSelector'
import NavLinks from './NavLinks'
import { authHelper } from '../../helpers/auth_helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'

class NavBar extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let logoutBtn = '';
        if (authHelper.isLoggedIn()) {
            logoutBtn =
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <FontAwesomeIcon icon={faUserCog} />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="nav-link" to={Routes.SETTINGS}>{$$('settings_label')}</Link>
                        <Link className="nav-link" to={Routes.LOGIN} onClick={() => this.props.logout()}>{$$('logout_label')}</Link>
                    </div>
                </li>
        }
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <Link className="navbar-brand" to={Routes.MAIN_PAGE}>{$$('title')}</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <NavLinks isAuthenticated={authHelper.isLoggedIn()} />
                    <div className="my-2 my-lg-0" style={{ width: '100%' }}>
                        <ul className="navbar-nav mr-auto" style={{ float: 'right' }}>
                            <li className="nav-item active">
                                <LanguageSelector className="show" changeLanguage={this.props.changeLanguage} />
                            </li>
                            {logoutBtn}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication.data,
        i18n: state.language
    }
}

export default connect(mapStateToProps, { logout, changeLanguage })(NavBar);
