import React from 'react'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as HeightIcon } from '../../../public/media/icons/height.svg'
import { HEIGHT } from '../../constants/entry_types'

export default function HeightEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon">
                    <HeightIcon className="svg-icon" fill="#646c9a"/>
                </span>
                <span className="logbook-entry-dashboard-text">
                    {CONVERTER.convertByUnit(HEIGHT, props.entry.height).toFixed(1)} {CONVERTER.getUnitByType(HEIGHT)}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
