import {
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_ERROR,
    CHANGE_USER_SETTINGS_SUCCESS,
    CHANGE_USER_SETTINGS_ERROR,
    LOGOUT,
    REQUEST_SUCCESS,
    REQUEST_ERROR
} from "../actions/actions";
import { requestStatus } from './requests_reducers';

const initialState = { data: {}, request: requestStatus(undefined, {}) }
/**
 * Settings reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of settings
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function settings(state = initialState, action) {
    switch (action.type) {
        case GET_USER_SETTINGS:
        case CHANGE_USER_SETTINGS_SUCCESS: {
            return { ...state, data: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) };
        }
        case GET_USER_SETTINGS_ERROR:
        case CHANGE_USER_SETTINGS_ERROR: {
            return { ...state, ...initialState, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.response }) };
        }
        case LOGOUT: {
            return { ...state, ...initialState };
        }
        default: {
            return state
        }
    }
}