import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { ReactComponent as HBA1CIcon } from '../../../public/media/icons/hba1c.svg'

export default function HBA1CEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className="card-header">
                <div className="card-title logbook-entry-title"><span className="logbook-entry-custom-icon"><HBA1CIcon fill="#646c9a"/></span> {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}, {CONVERTER.getDayPeriodFromTime(props.entry.date_time)}</div>
            </div>
            <div className="card-body logbook-entry-body">
                <div>{$$("HBA1C")}: {CONVERTER.hba1cPerUnit(props.entry.hba1c).toFixed(1)} {CONVERTER.getFormattedHBA1CUnit()}</div>
                {props.entry.notes && !props.entry.hideNotes ? <div>{$$("notes")}: {props.entry.notes}</div> : ""}
            </div>
        </div>
    )
}
