import { fetchHelper } from '../helpers/request_helper';
import { 
    GET_CHAT_WITH_USER,
    SEND_MESSAGE_TO_USER,
    MARK_MESSAGE_AS_READ_URL,
    GET_LATEST_MESSAGES_PER_CHAT,
    GET_UNREAD_MESSAGES,
    START_VIDEO_CALL_SESSION_UTR,
    STOP_VIDEO_CALL_SESSION_URL,
    UPDATE_VIDEO_DURATION_AND_STATUS_URL
} from '../constants/api_paths';

export const chatService = {
    getChatWithUser,
    sendMessage,
    updateVideoCallDurationAndStatus,
    markAsRead,
    getLatestMessages,
    getUnreadMessages,
    startVideoCallSession,
    getVideoCallSession,
    stopVideoCallSession
};

/**
 * Send chat message message
 * 
 * @param {object} message - the message to send
 * @returns {object} promise object
 */
export function sendMessage(message) {
    return fetchHelper.callPost(SEND_MESSAGE_TO_USER, message);
}

/**
 * Mark message as read
 * 
 * @param {object} message - the id of the message to mark as read
 * @returns {object} promise object
 */
export function markAsRead(messageId) {
    return fetchHelper.callPut(MARK_MESSAGE_AS_READ_URL.replace('{messageId}', messageId));
}

/**
 * Get the chat messages with the user with the given id
 * 
 * @param {string} userId id of the user to get the messages for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getChatWithUser(userId, params) {
    return fetchHelper.callGet(GET_CHAT_WITH_USER.replace('{userId}', userId), params);
}

/**
 * Fetch list with latest messages with each user
 *
 * @returns {object} promise object
 */
export function getLatestMessages() {
    return fetchHelper.callGet(GET_LATEST_MESSAGES_PER_CHAT);
}

/**
 * Fetch all undread messages
 *
 * @returns {object} promise object
 */
export function getUnreadMessages() {
    return fetchHelper.callGet(GET_UNREAD_MESSAGES);
}

/**
 * Update duration and status of the given video call message
 *
 * @param {number} messageId - Id of the video message to update
 * @param {number} duration - the duration of the video call
 * @param {string} status - the status of the video call
 * @returns {object} promise object
 */
export function updateVideoCallDurationAndStatus(messageId, duration, status) {
    const params = {
        video_duration: duration,
        video_status: status
    }
    return fetchHelper.callPut(UPDATE_VIDEO_DURATION_AND_STATUS_URL.replace('{messageId}', messageId), null, params);
}

/**
 * Start a videocall sesssion with the user with the given Id
 *
 * @param {number} userId - Id of the user to call
 * @returns {object} promise object
 */
export function startVideoCallSession(userId) {
    return fetchHelper.callPost(START_VIDEO_CALL_SESSION_UTR.replace('{userId}', userId));
}

/**
 * Get a videocall sesssion with the user with the given Id
 *
 * @param {number} userId - Id of the user which created the call session
 * @returns {object} promise object
 */
export function getVideoCallSession(userId) {
    return fetchHelper.callGet(START_VIDEO_CALL_SESSION_UTR.replace('{userId}', userId));
}

/**
 * Stop video call session
 *
 * @param {number} userId - Id of the user to call
 * @returns {object} promise object
 */
export function stopVideoCallSession(params) {
    return fetchHelper.callDelete(STOP_VIDEO_CALL_SESSION_URL, params);
}
