import React from 'react'
import { CONVERTER, getUnitValuefromKey } from '../../utils/converter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCapsules } from '@fortawesome/free-solid-svg-icons'

export default function MedicationEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-8 col-md-8">
                <span className="logbook-entry-dashboard-icon" style={{ "left": "10px" }}>
                    <FontAwesomeIcon icon={faCapsules} />
                </span>
                <div className="logbook-entry-dashboard-text">
                    <div>{props.entry.name}, {props.entry.dose} {getUnitValuefromKey(props.entry.unit)}</div>
                </div>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
