import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { $$ } from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';

export default class ImageCrop extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            croppedImageUrl: null,
            showMe: "none",
            showModal: false,
        };

        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.makeClientCrop = this.makeClientCrop.bind(this);
    }

    /**
     * On confirm modal, continue with image removal.
     */
    onConfirmModal = () => {
        this.removeProfilePicture();
        this.setState({ showModal: false });
    }

    /**
    * Set state to show the modal
    */
    showModal = () => {
        this.setState({ showModal: true });
    }

    /**
     * When the user has selected a file, the state properties: src and showMe are updated
     * 
     * @param {object} e - the event
     */
    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            const fileName = e.target.files[0].name;
            reader.addEventListener('load', (x) => {
                this.setState({ src: reader.result });
                this.setState({ showMe: "block" });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    /**
     * When the image is loaded, crop and crop for initial preview are being updated
     * 
     * @param {object} image - the loaded image 
     */
    onImageLoaded(image) {
        this.imageRef = image;
        let ratio = image.naturalWidth / image.naturalHeight;
        let heightInPct = 50 * ratio;
        let yInPct = (100 - (heightInPct)) / 2;
        let xInPct = 25;
        this.setState({crop: {
            unit: '%',
            width: 50,
            height: heightInPct,
            x: xInPct,
            y: yInPct,
            aspect: 1
        }});

        let cropForInitialPreview = {
            width: image.naturalWidth / 2,
            height: heightInPct / 100 * image.naturalHeight,
            x: xInPct / 100 * image.naturalWidth,
            y: yInPct / 100 * image.naturalHeight
        };
        this.makeClientCrop(cropForInitialPreview);
        return false;
    };

    /**
     * When the user has completed the cropping, a client crop takes place
     * 
     * @param {object} crop - crop along with its dimensions
     * @param {object} percentCrop - percentCrop along with its dimensions
     */
    onCropComplete (crop, percentCrop) {
        this.makeClientCrop(crop);
    };

    /**
     * When the user changes the crop this method is responsible for handling it
     * 
     * @param {object} crop - crop along with its dimensions
     * @param {object} percentCrop - percentCrop along with its dimensions
     */
    onCropChange (crop, percentCrop) {
        this.setState({ crop: percentCrop });
    };

    /**
     * Handles the client crop and updates the state
     * 
     * @callback resolveCallback
     * @param {object} crop - crop along with its dimensions 
     */
    makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg',
                (e) => {
                    this.setState({croppedImageUrl: e})
                }
            );
        }
    }

    /**
     *  Draws the image with the appropriate dimensions
     * 
     * @param {object} image - the image to be cropped
     * @param {object} crop - crop along with its dimensions
     * @param {string} fileName - the name of the file
     * @param {resolveCallback} resolve 
     */
    getCroppedImg(image, crop, fileName, resolve) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //fixed dest size - 512x512
        canvas.width = 512;
        canvas.height = 512;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;

            window.URL.revokeObjectURL(this.state.croppedImageUrl);
            let a = window.URL.createObjectURL(blob);
            resolve(a, blob);
        }, 'image/jpeg');
    }

    /**
     * Handles adding or changing profile picture event
     */
    addOrChangeProfilePicture = () => {
        var myInput = document.getElementById('picture');
        myInput.click();
    }

    /**
     * Handles profile picture removal
     */
    removeProfilePicture = () => {
        this.props.onRemove();
        this.setState({showMe: "none"}); 
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;
        return (
            <div className="col-xs-12 col-md-12 text-center">
                <button onClick={this.addOrChangeProfilePicture} id="takePicturesButton" style={{"backgroundColor": "Transparent", "outline": "none", "border": "none"}}>
                    <span className="flaticon-edit" style={{"fontSize": "170%"}}/>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button onClick={this.showModal} style={{"backgroundColor": "Transparent", "outline": "none", "border": "none"}} disabled={!this.props.hasProfilePicture}>
                    <span className="flaticon-delete" style={{"fontSize": "170%"}}/>
                </button>
                <div>
                    <input id="picture" type="file" onClick={(event)=> { event.target.value = null }} accept="image/jpeg,capture=camera" onChange={this.onSelectFile} style={{"display": "none"}} />
                </div>
                <div style={{"display": this.state.showMe}}>
                    {this.state.src && (
                        <div className="p-2 w-50 d-table-cell">
                        <div className="p-2 bg-light border-caption">{$$('select_image')}</div>
                        <ReactCrop
                            src={this.state.src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                        </div>
                    )}

                    <div className="p-2 w-50 d-table-cell">
                        <div className="p-2 bg-light border-caption">{$$('preview_image')}</div>
                        {croppedImageUrl && (
                            <img className="preview" alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                        )}
                    </div>

                    <div className="text-right pseudo-modal-footer">
                        <div>
                            <button className="btn btn-primary mr-2"  disabled={crop && crop.width < 10} onClick={() => { 
                                this.props.onComplete(this.state.croppedImageUrl);
                                this.setState({showMe: "none"});
                                }}>{$$('save_btn_label')}</button>
                            <button className="btn btn-danger"  onClick={() => this.setState({showMe: "none"})}>{$$('cancel_btn')}</button>
                        </div>
                    </div>
                </div>
                <CenteredModal title={$$('delete_image_modal_title')}
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false })}
                    onConfirm={this.onConfirmModal}
                    confirmBtnLabel={$$('OK')}
                    cancelBtnLabel={$$('cancel_btn')}
                >
                    {$$('delete_image_modal_message')}
                </CenteredModal>
            </div>
        );
    }
}
