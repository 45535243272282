import { fetchHelper } from '../helpers/request_helper';
import { GET_LABORATORIES_URL, GET_USER_LAB_RESULTS_URL } from '../constants/api_paths';

export const labResultsService = {
    fetchLaboratories,
    fetchSelectedUserLabResults
};

/**
 * Fetch all the available laboratories
 * 
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchLaboratories(params) {
    return fetchHelper.callGet(GET_LABORATORIES_URL, params);
}

/**
 * Fetch the selected user laboratories results
 * 
 * @param {string} userId id of the user to fetch lab results for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLabResults(userId, params) {
    return fetchHelper.callGet(GET_USER_LAB_RESULTS_URL.replace('{userId}', userId), params);
}
