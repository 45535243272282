import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'

export default function CholesterolEntryV2(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-4 col-md-4">
                {$$("Cholesterol")}
            </div>
            <div className="col-xs-4 col-md-4">
                <div>
                    HDL: {CONVERTER.cholesterolPerUnit(props.entry.hdl)} {CONVERTER.getFormattedCholesterolUnit()}
                </div>
                <div>
                    LDL: {CONVERTER.cholesterolPerUnit(props.entry.ldl)} {CONVERTER.getFormattedCholesterolUnit()}
                </div>
                <div>
                    {$$("triglycerides")}: {CONVERTER.cholesterolPerUnit(props.entry.triglycerides)} {CONVERTER.getFormattedCholesterolUnit()}
                </div>
                <div>
                    {$$("total_cholesterol")}: {CONVERTER.cholesterolPerUnit(props.entry.total_cholesterol)} {CONVERTER.getFormattedCholesterolUnit()}
                </div>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
