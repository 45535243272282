import store from '../store';

export const authHelper = {
    isLoggedIn
};

/**
 * Check if user is logged in
 * 
 * @returns {boolean} true if user is logged in
 */
export function isLoggedIn() {
    const authData = store.getState().authentication.data;
    return authData && authData.access_token !== null && store.getState().authentication.data.access_token !== undefined;
}