import React, { Component } from 'react'
import { validators } from '../../helpers/validators'
import { $$ } from '../../helpers/localization'
import Select from "../shared/Select"
import { countryOptions } from "../../constants/countries"
import moment from "moment";
import { getResolvedOptions, GENDER } from '../../constants/select_options'
import UserImage from "../shared/UserImage";
import ImageCrop from '../shared/ImageCrop';
import { uploadImage, deleteImage } from '../../service/users_service'

export class UserProfileForm extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        id: this.props.userInfo.id,
        fullname: this.props.userInfo.fullname,
        email: this.props.userInfo.email,
        city: this.props.userInfo.city || '',
        country: this.props.userInfo.country || '',
        birthday: this.props.userInfo.birthday || 0,
        gender: this.props.userInfo.gender || '',
        formclass: '',
        hasImage: false,
        hasProfilePicture: true,
        errors: {}
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.userInfo.response && this.props.userInfo.response.status === 403) {
            this.setState({
                errors: { email: 'register_form_email_exists_message' }
            });
        }
        if(!this.state.id) {
            this.setState({
                id: this.props.userInfo.id,
                fullname: this.props.userInfo.fullname,
                email: this.props.userInfo.email,
                city: this.props.userInfo.city || '',
                country: this.props.userInfo.country || '',
                birthday: this.props.userInfo.birthday || 0,
                gender: this.props.userInfo.gender || 'FEMALE',
            });
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({ name, value }) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = moment(evt.target.value).valueOf();
        this.setState(fields);
    };

    /**
     * Form submit handler, validate data and set error in state if any. Call updateUserInfo action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({ errors: formErrors });
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({ formclass: "was-validated" });
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            const { errors, formclass, ...data } = this.state;
            const userInfo = { ...this.props.userInfo, ...data };
            this.props.updateUserInfo(userInfo);
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email)) {
            errors.email = 'register_form_email_not_correct_message';
        }
        return errors;
    }

    /**
     * Get error message for the image uploading
     *
     * @returns {string} the error to display
     */
    getErrorMessage = () => {
        if (this.state.errors.unsupprotedImage) {
            return $$('unsupported_image_format');
        }
    }

    onImageSelectingComplete = (data) => {
        uploadImage(data).then(() => {
            this.setState({
                hasImage: true
            });
        });
    }

    onRemoveImage = () => {
        deleteImage().then(() => {
            this.setState({
                hasImage: false
            });
        });
    }

    setStateForProfilePicture = (hasPicture) => {
        if(this.state.hasProfilePicture !== hasPicture) {
            this.setState({ hasProfilePicture: hasPicture });
        }
    }

    render() {
        let alert = '';
        if (this.props.showSuccessfulAlert) {
            alert = (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {$$('user_info_updated_message')}
                </div>
            );
        }

        if (this.state.errors.unsupprotedImage) {
            alert = (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {this.getErrorMessage()}
                </div>
            );
        }

        return (
            <div className="card centered-form" style={{ "width": "80%" }}>
                <div className="card-header">
                    <h2 className="text-center card-title">{$$("edit_profile_label")}</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xs-4 col-md-4">
                            <div className="row">
                                <div className="text-center" style={{ "margin": "auto" }} >
                                    <UserImage userID={this.props.userInfo.id} setProfilePictureState={this.setStateForProfilePicture} hasProfilePicture={this.state} />
                                </div>
                            </div>
                            <div className="row">
                                <ImageCrop src={undefined} onComplete={(blobUri, fileName) => { 
                                        fetch(blobUri).then(r => { 
                                            return r.blob();
                                        })
                                        .then(blob => {
                                            this.onImageSelectingComplete(blob);
                                        });
                                    }
                                }
                                onRemove={() => {
                                    this.onRemoveImage();      
                                }}
                                hasProfilePicture={this.state.hasProfilePicture}
                                />
                            </div>
                        </div>

                        <div className="col-xs-8 col-md-8 personal-info">
                            <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                                {alert}
                                <div className="form-group register-control">
                                    <label>{$$('fullname_label')}</label>
                                    <input type="text" className="form-control" value={this.state.fullname} placeholder={$$('fullname_label')} name="fullname" onChange={this.onInputChange} required />
                                    <div className="invalid-feedback">
                                        {$$('register_form_fullname_required')}
                                    </div>
                                </div>
                                <div className="form-group register-control">
                                    <label>{$$('email_label')}</label>
                                    <input type="text" className={this.state.errors.email ? "custom-error form-control" : "form-control"} value={this.state.email} placeholder={$$('email_label')} name="email" onChange={this.onInputChange} required />
                                    <div className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                        {this.state.errors.email ? $$(this.state.errors.email) : $$('email_required_message')}
                                    </div>
                                </div>
                                <div className="row register-control">
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('gender_label')}
                                            name="gender"
                                            value={this.state.gender}
                                            options={getResolvedOptions(GENDER.OPTIONS)}
                                            onChange={this.onSelectChange} />
                                    </div>
                                    <div className="col-sm-6">
                                        <label>{$$('birthday_label')}</label>
                                        <input type="date" className="form-control  my-1 mr-sm-2" value={moment(this.state.birthday).format('YYYY-MM-DD')} placeholder={$$('birthday_label')} name="birthday" onChange={this.onDateChange} />
                                    </div>
                                </div>
                                <div className="row" style={{ "marginTop": "5px" }}>
                                    <div className="col-sm-6">
                                        <Select
                                            label={$$('country_label')}
                                            name="country"
                                            options={countryOptions()}
                                            value={this.state.country}
                                            onChange={this.onSelectChange} />
                                    </div>
                                    <div className="col-sm-6" style={{ "marginTop": "3px" }}>
                                        <label>{$$('city_label')}</label>
                                        <input type="text" className="form-control" value={this.state.city} placeholder={$$('city_label')} name="city" onChange={this.onInputChange} />
                                    </div>
                                </div>
                                <div className="form-group register-control" style={{ "marginTop": "10px" }}>
                                    <div className="col-xs-7 col-md-7 mx-auto">
                                        <button type="submit" className="btn btn-primary btn-block">{$$('update_btn')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfileForm
