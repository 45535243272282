import React from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons'

export default function BloodPressureEntry(props) {
    return (
        <div className="row dashboard-entry" style={{ "marginTop": "15px" }}>
            <div className="col-xs-4 col-md-4 ">
                <span className="logbook-entry-dashboard-icon" style={{ "left": "10px" }}>
                    <FontAwesomeIcon icon={faHeartbeat} />
                </span>
                <span className="logbook-entry-dashboard-text">
                    {props.entry.systolic} / {props.entry.diastolic} {$$('millimetre_of_mercury')}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                <span className="logbook-entry-dashboard-icon" style={{ "left": "10px" }}>
                    <i className='kt-menu__link-icon flaticon2-heart-rate-monitor' />
                </span>
                <span className="logbook-entry-dashboard-text">
                    {props.entry.pulse}
                </span>
            </div>
            <div className="col-xs-4 col-md-4">
                {CONVERTER.formatDate(new Date(props.entry.date_time))}
            </div>
        </div>
    )
}
