import {
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_ERROR,
    FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_SELECTED_USER_DOCUMENTS_SUCCESS,
    FETCH_SELECTED_USER_DOCUMENTS_ERROR,
    NO_MORE_SELECTED_USER_DOCUMENTS,
    CHANGE_DOCUMENTS_FILTERS,
    RESET_SELECTED_USER_DOCUMENTS_SUCCESS,
    LOGOUT,
    CLEAR_USERS,
    SELECT_USER,
    FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_SUCCESS,
    FETCH_LOGGED_USER_DOCUMENTS_ERROR,
    NO_MORE_LOGGED_USER_DOCUMENTS,
    RESET_LOGGED_USER_DOCUMENTS_SUCCESS
} from "../actions/actions";
import { requestStatus } from './requests_reducers';

/**
 * Documents entries reducer. Reduce state based on action type.
 * 
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const documentsInitialState = {
    filters: {
        type: '',
        category: '',
        before_date_time: null,
        after_date_time: null,
    },
    selectedUser: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {}),
    },
    loggedUser: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {}),
    }
}
export function documents(state = documentsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT: {
            return { ...state, selectedUser: { ...state.selectedUser, isLastPage: false, request: requestStatus(state.request, { type: REQUEST_SENT }) } }
        }
        case FETCH_SELECTED_USER_DOCUMENTS_SUCCESS: {
            return { ...state, selectedUser: { entries: [...state.selectedUser.entries, ...action.result], request: requestStatus(state.request, { type: REQUEST_SUCCESS }) } }
        }
        case FETCH_SELECTED_USER_DOCUMENTS_ERROR: {
            return { ...state, selectedUser: { entries: documentsInitialState.selectedUser.entries, isLastPage: documentsInitialState.selectedUser.isLastPage, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) } }
        }
        case RESET_SELECTED_USER_DOCUMENTS_SUCCESS: {
            return { ...state, selectedUser: { entries: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) } }
        }
        case CHANGE_DOCUMENTS_FILTERS: {
            return { ...state, filters: { type: action.documentType, category: action.category, before_date_time: action.beforeDateTime, after_date_time: action.afterDateTime } }
        }
        case NO_MORE_SELECTED_USER_DOCUMENTS: {
            return { ...state, selectedUser: { ...state.selectedUser, isLastPage: true } }
        }
        case FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT: {
            return { ...state, loggedUser: { ...state.loggedUser, isLastPage: false, request: requestStatus(state.request, { type: REQUEST_SENT }) } }
        }
        case FETCH_LOGGED_USER_DOCUMENTS_SUCCESS: {
            return { ...state, loggedUser: { entries: [...state.loggedUser.entries, ...action.result], request: requestStatus(state.request, { type: REQUEST_SUCCESS }) } }
        }
        case FETCH_LOGGED_USER_DOCUMENTS_ERROR: {
            return { ...state, loggedUser: { entries: documentsInitialState.loggedUser.entries, isLastPage: documentsInitialState.loggedUser.isLastPage, request: requestStatus(state.request, { type: REQUEST_ERROR, response: action.result }) } }
        }
        case NO_MORE_LOGGED_USER_DOCUMENTS: {
            return { ...state, loggedUser: { ...state.loggedUser, isLastPage: true } }
        }
        case RESET_LOGGED_USER_DOCUMENTS_SUCCESS: {
            return { ...state, loggedUser: { entries: action.result, request: requestStatus(state.request, { type: REQUEST_SUCCESS }) } }
        }
        case SELECT_USER:
        case CLEAR_USERS: {
            return { ...state, selectedUser: documentsInitialState.selectedUser, loggedUser: documentsInitialState.loggedUser };
        }
        case LOGOUT: {
            return { ...state, ...documentsInitialState };
        }
        default: {
            return state;
        }
    }
}