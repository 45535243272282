import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { $$ } from '../../helpers/localization'
import Select from '../shared/Select';
import { SETTING_OPTIONS, getResolvedOptions } from '../../constants/select_options';

export class SettingsForm extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
        cholesterolUnits: this.props.settings.cholesterolUnits,
        classificationMethod: this.props.settings.classificationMethod,
        dateFormat: this.props.settings.dateFormat,
        time24hour: this.props.settings.time24hour,
    };

    /**
	 * Set the state to the latest selected option.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onSelectChange = ({ name, value }) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
	 * On checkbox checked handler, set the correct value to state
	 *
	 * @param {object} evt - The event handler argument
	 */
    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    /**
    * On label clicked handler, set the correct value to state
    *
    * @param {object} evt - The event handler argument
    */
    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    /**
	 * Form submit handler, update the user settings.
	 *
	 * @param {object} evt - The event handler argument
	 */
    onSubmit = (evt) => {
        evt.preventDefault();
        this.props.updateUserSettings(this.state);
    }

    render() {
        let alert = '';
        if (this.props.showSuccessfulAlert) {
            alert = (
                <div className="alert alert-success">
                    {$$('settings_updated_message')}
                </div>
            );
        }

        return (
            <div className="card centered-form">
                <div className="card-header">
                    <h2 className="text-center card-title">{$$("settings_label")}</h2>
                </div>
                <div className="card-body">
                    {alert}
                    <form onSubmit={(evt) => this.onSubmit(evt)}>
                        <div className="form-group">
                            <Select
                                label={$$('unit_of_measurement_label')}
                                name="unitsOfMeasurement"
                                options={getResolvedOptions(SETTING_OPTIONS.UNITS_OF_MEASUREMENTS)}
                                value={this.state.unitsOfMeasurement}
                                onChange={this.onSelectChange} />
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('cholesterol_units_label')}
                                name="cholesterolUnits"
                                options={getResolvedOptions(SETTING_OPTIONS.CHOLESTEROL_UNITS)}
                                value={this.state.cholesterolUnits}
                                onChange={this.onSelectChange} />
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('classification_method_label')}
                                name="classificationMethod"
                                options={SETTING_OPTIONS.CLASSIFICATION_METHODS}
                                value={this.state.classificationMethod}
                                onChange={this.onSelectChange} />
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('date_format_label')}
                                name="dateFormat"
                                options={SETTING_OPTIONS.DATE_FORMATS}
                                value={this.state.dateFormat}
                                onChange={this.onSelectChange} />
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="time24hour"
                                checked={this.state.time24hour}
                                onChange={this.onCheckboxChanged}
                            />
                            <label className="form-check-label" htmlFor="time24hour" onClick={this.onLabelClick}>
                                {$$('time_24hour_label')}
                            </label>
                        </div>
                        <div className="form-group" style={{ "marginTop": "20px" }}>
                            <div className="col-xs-7 col-md-7 mx-auto">
                                <button type="submit" className="btn btn-primary btn-block">{$$("save_btn_label")}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default SettingsForm
