import React, { Component } from 'react'
import { $$ } from '../../helpers/localization'
import { CONVERTER } from '../../utils/converter'
import { GENDER } from '../../constants/select_options'
import { getcountry_labelName } from '../../constants/countries'
import '../../_metronic/'
import UserImage from "../shared/UserImage";

export class UserInfo extends Component {

    render() {
        if (!this.props.selectedUser.id) {
            return '';
        }
        const gender = GENDER.OPTIONS.find(g => g.value === this.props.selectedUser.gender);
        return (

            <div className="card" style={{ "height": "auto" }}>
                <div className="card-header">
                    <i className='kt-menu__link-icon flaticon2-user' /> {$$('user_info_label')}
                </div>
                <div className="card-body row">
                    <div className="col-xs-6 col-md-6  d-flex justify-content-center">
                       <UserImage userID={this.props.selectedUser.id} />
                    </div>
                    <div className="col-xs-6 col-md-6 user-info-container">
                        <div>{$$('fullname_label')}: {this.props.selectedUser.fullname}</div>
                        {this.props.selectedUser.email && this.props.selectedUser.email.includes('@') &&
                            <div>{$$('email_label')}: {this.props.selectedUser.email}</div>
                        }
                        {this.props.selectedUser.gender && <div>{$$('gender_label')}: {$$(gender.text)}</div>}
                        {<div>{$$('birthday_label')}: {CONVERTER.formatDate(this.props.selectedUser.birthday, true)}</div>}
                        {this.props.selectedUser.country && <div>{$$('country_label')}: {this.props.selectedUser.country ? getcountry_labelName(this.props.selectedUser.country) : ''}</div>}
                        {this.props.selectedUser.city && <div>{$$('city_label')}: {this.props.selectedUser.city}</div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default UserInfo
